import { Flex, Text, Image } from '@chakra-ui/react';
import { BROWSER_TAB_BASE64, ICON_BASE64 } from '../../../utils/base64';
import PassDownloadTemplateContext from '../../../store/client/PassDownloadTemplateContext';
import { useContext } from 'react';

const PreviewBrowserTab = () => {
  const passDownloadCtx = useContext(PassDownloadTemplateContext);

  return (
    <Flex direction="column" mt={8} w="100%" align="center">
      <Text
        textStyle="bodyBold"
        fontSize="16px"
        color="secondaryDark"
        textAlign={{ base: 'center', md: 'left' }}
      >
        Browser tab
      </Text>

      <Flex
        mx="auto"
        data-testid="browser-tab-preview"
        mt={4}
        bgColor="#DDDDDD"
        border="1px solid #ccc"
        boxShadow="0px 6px 13px rgb(0 0 0 / 15%)"
        w="100%"
        maxW={{ base: '95%', sm: '400px' }}
        minH="100px"
        borderRadius="10px"
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        <Image
          w="100%"
          maxW="360px"
          h="50px"
          objectFit="contain"
          src={BROWSER_TAB_BASE64}
          alt="browser-tab-image"
        />
        <Flex
          position="absolute"
          top={{ base: '45%', sm: '40%' }}
          left={{ base: '25%', sm: '100px' }}
          align="center"
          zIndex="1"
        >
          <Image
            boxSize={{ base: '20px', sm: '25px' }}
            src={passDownloadCtx.faviconImage || ICON_BASE64}
            alt="favicon-image"
            mr={2}
          />
          <Text
            textStyle="headingFamilyMediumApplePay"
            fontSize={{ base: '14px', sm: '16px' }}
            color="secondaryDark"
          >
            {passDownloadCtx.pageTitle?.length < 19
              ? passDownloadCtx.pageTitle
              : `${passDownloadCtx.pageTitle?.substring(0, 19)}..`}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PreviewBrowserTab;
