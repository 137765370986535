import { Icon } from '@chakra-ui/react';

export const CustomPassDownloadMultiIcon = (props) => (
  <Icon viewBox="0 0 15 18" {...props}>
    <g clip-path="url(#clip0_9964_2538)">
      <path
        className="multicolorElToDark darkBgElToWhite"
        d="M7.14844 17.6683C7.38281 17.7865 7.67578 17.7274 7.85156 17.5501L11.543 14.0638C11.8359 13.8274 11.8359 13.4138 11.543 13.1183C11.4258 12.941 11.25 12.8819 11.0742 12.8819C10.8984 12.8819 10.7227 12.941 10.6055 13.0592L8.02734 15.4819V6.32285C8.02734 5.96831 7.73438 5.67285 7.38281 5.67285C7.03125 5.67285 6.73828 5.96831 6.73828 6.32285L6.73828 15.4819L4.16016 13.0592C3.86719 12.7638 3.45703 12.8229 3.22266 13.0592C2.92969 13.3547 2.98828 13.7683 3.22266 14.0047L6.85547 17.4319C6.97266 17.5501 7.03125 17.6092 7.14844 17.6683Z"
        fill="#FF8477"
      />
      <path
        className="multicolorElToWhite darkBgElToBrand"
        d="M0.703127 0L14.3555 0C14.707 0 15 0.295455 15 0.65L15 11.2273C15 11.5818 14.707 11.8773 14.3555 11.8773H10.7813C10.4297 11.8773 10.1367 11.5818 10.1367 11.2273C10.1367 10.8727 10.4297 10.5773 10.7813 10.5773H13.7109V1.35909L1.40625 1.35909V10.5773L4.33594 10.5773C4.6875 10.5773 4.98047 10.8727 4.98047 11.2273C4.98047 11.5818 4.6875 11.8773 4.33594 11.8773L0.761721 11.8773C0.410158 11.8773 0.117189 11.5818 0.117189 11.2273L0.117189 0.65C1.90735e-06 0.295455 0.292971 0 0.703127 0Z"
        fill="#29323A"
      />
    </g>
  </Icon>
);
