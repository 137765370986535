import appsignal from '../appsignal';
import { useContext, useEffect } from 'react';
import { ClientDashboardStatsContext } from '../store/client/ClientDashboardStatsContext';
import { Flex, useMediaQuery, useToast } from '@chakra-ui/react';
import CustomToast from '../common/CustomToast';
import { MeContext } from '../context/MeContext';
import { getErrorResponsePayload } from '../utils/ajax';
import moment from 'moment';
import { HttpContext } from '../context/HttpContext';
import InfoBoxList from '../components/client/dashboard/InfoBoxList';
import { useIsMount } from './useIsMount';
import DashboardPassTaps from '../components/client/dashboard/DashboardPassTaps';
import DashboardNoPassTemplates from '../components/client/dashboard/DashboardNoPassTemplates';
import useOrganizationStatus from './useOrganisationStatus';

const UseClientDashboardStats = ({
  children,
  loadingDashBody,
  setLoadingDashBody,
  setGetDataCount,
}) => {
  const { authAxios } = useContext(HttpContext);
  const { updatePassesInfoData, filterTime, filterPassTemplate } = useContext(
    ClientDashboardStatsContext
  );
  const meCtx = useContext(MeContext);
  const toast = useToast();
  const { shouldRender } = useOrganizationStatus();
  const templates = meCtx.state?.passTemplates;
  const [isSmallerThan1360px] = useMediaQuery('(max-width: 1360px)');
  const { filterEntity } = meCtx.state;

  const isMount = useIsMount();

  const prepareTimeQuery = (value) => {
    let fromDate;
    let toDate = moment().startOf('day').format();

    if (value.name === 'All time') {
      // fromDate = '';
      // toDate = '';
      return '';
    }

    if (value.name === 'Last 7 days') {
      fromDate = moment().startOf('day').subtract(7, 'days').format();
      return `&createdAfter=${encodeURIComponent(fromDate)}`;
    }

    if (value.name === 'Last 30 days') {
      fromDate = moment().startOf('day').subtract(30, 'days').format();
      return `&createdAfter=${encodeURIComponent(fromDate)}`;
    }

    if (value.name === 'Last quarter') {
      fromDate = moment().startOf('day').subtract(3, 'months').format();
      return `&createdAfter=${encodeURIComponent(fromDate)}`;
    }

    if (value.name === 'Last year') {
      fromDate = moment().startOf('day').subtract(1, 'years').format();
      return `&createdAfter=${encodeURIComponent(fromDate)}`;
    }

    if (value.name === 'Custom') {
      fromDate = value.data.totalFromDate;
      toDate = value.data.totalToDate;
      return `&createdAfter=${encodeURIComponent(
        fromDate
      )}&createdBefore=${encodeURIComponent(toDate)}`;
    }
  };

  const preparePassTemplateForQuery = (passTemplateValue) => {
    if (
      !!passTemplateValue &&
      passTemplateValue.label !== 'All pass templates'
    ) {
      const passTemplateName = `${passTemplateValue.name}`;
      return `&passTemplateName=${passTemplateName}`;
    }
    return '';
  };

  useEffect(() => {
    const getData = async () => {
      if (isMount) {
        return;
      }

      if (!shouldRender) {
        console.log(
          'Setup not confirmed or organisation is not active, skipping data fetch'
        );
        return;
      }

      setGetDataCount((prev) => prev + 1);
      setLoadingDashBody(true);
      try {
        const queryTime = prepareTimeQuery(filterTime);
        const queryPassTemplate =
          preparePassTemplateForQuery(filterPassTemplate);
        let response;
        if (filterEntity) {
          response = await Promise.all([
            authAxios.get(
              `api/v1/entities/${filterEntity.uuid}/passes?fields=id${queryPassTemplate}${queryTime}`
            ),
          ]);
        } else {
          response = await Promise.all([
            authAxios.get(
              `api/v1/passes?fields=id${queryPassTemplate}${queryTime}`
            ),
          ]);
        }
        const passesData = response[0].data;

        const activePassesResponse = filterEntity
          ? await Promise.all([
              authAxios.get(
                `api/v1/entities/${filterEntity.uuid}/passes?fields=status&status=active${queryPassTemplate}${queryTime}`
              ),
            ])
          : await Promise.all([
              authAxios.get(
                `api/v1/passes?fields=status&status=active${queryPassTemplate}${queryTime}`
              ),
            ]);

        const activePasses =
          activePassesResponse[0].data?.meta?.totalCount || 0;

        const passTapsData = filterEntity
          ? await authAxios.get(
              `api/v1/scans?entity=${filterEntity.uuid}&last_scan=true${queryPassTemplate}${queryTime}`
            )
          : await authAxios.get(
              `api/v1/scans?last_scan=true${queryPassTemplate}${queryTime}`
            );

        const readers = passTapsData?.data?.meta?.readersCount || 0;
        const totalPassTaps = passTapsData?.data?.meta?.totalScans || 0;
        const successfulPassTaps =
          passTapsData?.data?.meta?.successfulScans || 0;

        const lastPassTap = totalPassTaps
          ? passTapsData?.data?.meta?.lastScan
          : null;

        const dashboardData = {
          ...passesData,
          activePasses: activePasses,
          readers,
          totalPassTaps,
          successfulPassTaps,
          lastPassTap,
        };

        // const readersData = readersResponse?.data?.data;

        updatePassesInfoData(dashboardData);

        // updateMostUsedReadersData(readersData);
        setLoadingDashBody(false);
      } catch (error) {
        setLoadingDashBody(false);
        console.log(error);
        const { message, code } = getErrorResponsePayload(error);
        code !== 401 &&
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={message ? message : `Something went wrong`}
                description={!message && 'Please try again later'}
                onClose={props.onClose}
              />
            ),
          });
      }
    };

    if (
      (filterTime.name !== 'Custom' &&
        filterTime.name !== filterTime.prevSelection) ||
      (filterTime.name === 'Custom' && filterTime.data?.totalFromDate)
    ) {
      getData();
    }
  }, [authAxios, filterTime, filterPassTemplate, shouldRender]);

  if (!loadingDashBody || !shouldRender) {
    return <>{children}</>;
  }

  return (
    <>
      <Flex
        direction={isSmallerThan1360px ? 'column' : 'row'}
        gap={8}
        width="100%"
      >
        <Flex direction="column" gap={5} width="full">
          <InfoBoxList isLoading={loadingDashBody} />
          {templates && !templates?.length && (
            <DashboardNoPassTemplates isLoading={loadingDashBody} />
          )}
        </Flex>
        <Flex direction="column" gap={5} width="full">
          {/* {events?.length > 0 && <DashboardEvents isLoading={loadingDashBody} />} */}
          <DashboardPassTaps isLoading={loadingDashBody} />
        </Flex>
      </Flex>
    </>
  );
};

export default UseClientDashboardStats;
