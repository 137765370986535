import { extendTheme, withDefaultVariant } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const stylesControls = {
  // height: '50px',
  // fontSize: '14px',
  // lineHeight: '50px',
  borderRadius: '25px',
  border: 'solid 2px transparent',
  // backgroundColor: 'rgba(221, 221, 221, 0.5)',
  color: 'primaryDark',
  padding: '0 20px',
  _placeholder: {
    color: 'rgba(59, 72, 81, 0.6)',
  },
  // _hover: {
  //   border: 'solid 2px #3B4851',
  //   backgroundColor: 'rgba(221, 221, 221, 0.5)',
  //   color: 'secondaryDark',
  // }
  _focus: {
    border: 'solid 2px #3B4851',
    backgroundColor: 'white',
    color: 'secondaryDark',
  },
  _invalid: {
    borderColor: 'error',
    backgroundColor: 'white',
  },
  _disabled: {
    opacity: 1,
    color: 'rgba(59, 72, 81, 0.4)',
    backgroundColor: '#E0E0E0',
    border: 'none',
    _hover: {
      border: 'none',
      backgroundColor: '#E0E0E0',
    },
  },
};

const stylesControlsForDarkMode = {
  color: 'white',
  backgroundColor: 'primaryDark',
  _placeholder: {
    // #DDDDDD, 0.5
    color: 'rgba(221, 221, 221, 0.5)',
  },
  _hover: {
    border: 'solid 2px #3B4851',
    color: 'white',
    backgroundColor: 'primaryDark',
  },
  _focus: {
    border: 'solid 2px #3B4851',
    color: 'white',
    backgroundColor: 'primaryDark',
  },
  _invalid: {
    borderColor: 'error',
    color: 'white',
    backgroundColor: 'primaryDark',
  },
  _disabled: {
    opacity: 1,
    color: 'rgba(221, 221, 221, 0.4)',
    border: 'none',
    _hover: {
      border: 'none',
      color: 'rgba(221, 221, 221, 0.4)',
    },
  },
};

const stylesControlsForLightBg = {
  backgroundColor: 'rgba(221, 221, 221, 0.5)',
  _hover: {
    border: 'solid 2px #3B4851',
    backgroundColor: 'rgba(221, 221, 221, 0.5)',
    color: 'secondaryDark',
  },
};

const stylesControlsForDarkBg = {
  backgroundColor: 'white',
  _hover: { backgroundColor: '#FFFFFF', borderColor: '#3B4851' },
};

const sizesControls = {
  md: {
    field: {
      height: '50px',
      fontSize: '14px',
      lineHeight: '50px',
    },
  },
  sm: {
    field: {
      height: '36px',
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
};

export const customTheme = extendTheme(
  {
    styles: {
      global: (props) => ({
        'html, body': {
          backgroundColor: mode('primaryBackground', 'primaryDark')(props),
          // color: 'primaryDark',
          color: mode('primaryDark', 'white')(props),
          height: '100%',
          scrollbarColor: '#a5a5a5 transparent' /* thumb and track color */,
          scrollbarWidth: 'thin',
        },
        'input:-webkit-autofill': {
          boxShadow: 'inset 0 0 0px 1000px #ECECEC',
          WebkitBoxShadow: 'inset 0 0 0px 1000px #ECECEC',
          borderColor: '#ECECEC',
        },
        'input:autofill': {
          boxShadow: 'inset 0 0 0px 1000px #ECECEC',
          WebkitBoxShadow: 'inset 0 0 0px 1000px #ECECEC',
          borderColor: '#ECECEC',
        },
        '.autofillForDarkBg input:-webkit-autofill': {
          boxShadow: 'inset 0 0 0px 1000px #FFFFFF',
          WebkitBoxShadow: 'inset 0 0 0px 1000px #FFFFFF',
          borderColor: '#FFFFFF',
          _hover: {
            borderColor: '#3B4851',
          },
        },
        '.autofillForDarkBg input:autofill': {
          boxShadow: 'inset 0 0 0px 1000px #FFFFFF',
          WebkitBoxShadow: 'inset 0 0 0px 1000px #FFFFFF',
          borderColor: '#FFFFFF',
          _hover: {
            borderColor: '#3B4851',
          },
        },
        '.chakra-ui-dark input:-webkit-autofill': {
          boxShadow: 'inset 0 0 0px 1000px #29323A',
          WebkitBoxShadow: 'inset 0 0 0px 1000px #29323A',
          borderColor: '#29323A',
          WebkitTextFillColor: 'white !important',
          // Below helps with visible bluish border-radius, "content-box" works for FF and Chrome, while "text" just for Chrome
          WebkitBackgroundClip: 'content-box',
          // Below helps with flashing after change/select new prefilled value in FF, but causes again bluish issue for Chrome on mode change
          // transition: 'background-color 6000s',
        },
        '.chakra-ui-dark input:autofill': {
          boxShadow: 'inset 0 0 0px 1000px #29323A',
          WebkitBoxShadow: 'inset 0 0 0px 1000px #29323A',
          borderColor: '#29323A',
          WebkitTextFillColor: 'white !important',
          // Below helps with visible bluish border-radius, "content-box" works for FF and Chrome, while "text" just for Chrome
          WebkitBackgroundClip: 'content-box',
          // Below helps with flashing after change/select new prefilled value in FF, but causes again bluish issue for Chrome on mode change
          // transition: 'background-color 6000s',
        },
        // '.chakra-ui-dark input[data-autocompleted]': {
        //   backgroundColor: 'red !important',
        // },
        '.countryCodePrefixRightBorder': {
          '::after': {
            content: "''",
            position: 'absolute',
            height: '40%',
            width: '1.5px',
            top: '30%',
            right: '5px',
            backgroundColor: '#DDDDDD',
          },
        },
        '.androidGPayDetailsBorderHelper': {
          '::after': {
            content: "''",
            position: 'absolute',
            height: '15px',
            width: '1px',
            top: '-8px',
            right: '-1px',
            backgroundColor: 'primaryBackground',
          },
          '::before': {
            content: "''",
            position: 'absolute',
            height: '15px',
            width: '1px',
            top: '-8px',
            left: '-1px',
            backgroundColor: 'primaryBackground',
          },
        },
        '::-webkit-scrollbar': {
          width: '8px',
          background: 'lightgray',
        },
        '::-webkit-scrollbar-track': {
          // '-webkit-box-shadow': 'inset 0 0 6px rgb(195, 195, 195)',
          WebkitBoxShadow: 'inset 0 0 6px rgb(195, 195, 195)',
          borderRadius: '15px',
        },
        '::-webkit-scrollbar-thumb': {
          borderRadius: '15px',
          // '-webkit-box-shadow': 'inset 0 0 6px rgba(219, 219, 219, 0.5)',
          WebkitBoxShadow: 'inset 0 0 6px rgba(219, 219, 219, 0.5)',
          background: '#a5a5a5',
        },
        '.primaryBoxShadow': {
          boxShadow: '0px 7px 23px rgba(0, 0, 0, 0.05)',
        },
        '.opacityEffectParent': {
          _hover: {
            '.opacityEffectEl': {
              opacity: 0,
            },
            '.opacityEffectElHover': {
              opacity: 1,
            },
          },
        },
        '.cutoutBorderGrey': {
          '::after': {
            content: "''",
            position: 'absolute',
            height: '7.2px',
            width: '90px',
            backgroundColor: mode('#e7e7e7', 'primaryDark')(props),
            top: '0px',
            left: '50%',
            transform: 'translateX(-50%)',
          },
        },
        '.cutoutBorderWhite': {
          '::after': {
            content: "''",
            position: 'absolute',
            height: '7px',
            width: '90px',
            backgroundColor: mode('#ffff', 'secondaryDark')(props),
            top: '0px',
            left: '50%',
            transform: 'translateX(-50%)',
          },
        },
        // .zigZagTop::before {
        //   background: linear-gradient(140deg, transparent 50%, #EFEEEF 50%),
        //   linear-gradient(220deg, transparent 50%, #EFEEEF 50%);
        //   background-position: left-bottom;
        //   background-repeat: repeat-x;
        //   background-size: 10px 45px;
        //   content: ' ';
        //   display: block;
        //   position: absolute;
        //   left: 0;
        //   width: 100%;
        //   height: 42px;
        //   bottom: 329px;
        // }
        // .zigZagBottom::after {
        //   background: linear-gradient(-140deg, #EFEEEF 50%, transparent 50%),
        //   linear-gradient(-220deg, #EFEEEF 50%, transparent 50%);
        //   background-position: left-bottom;
        //   background-repeat: repeat-x;
        //   background-size: 10px 45px;
        //   content: ' ';
        //   display: block;
        //   position: absolute;
        //   left: 0;
        //   width: 100%;
        //   height: 33px;
        //   bottom: -11px;
        // }
        '.orDivider': {
          '::before': {
            content: "''",
            position: 'absolute',
            height: '1px',
            width: '450px',
            backgroundColor: 'primaryBackground',
            top: '10px',
            bottom: '0px',
            left: '50%',
            transform: 'translateX(-50%)',
          },
        },
        '.sideNav': {
          a: {
            fontFamily: 'Inter Bold, sans-serif',
            fontSize: '13px',
            //color: 'secondaryDark08',
            color: mode('secondaryDark08', '#DDDDDD')(props),
            padding: '11px 14px',
            borderRadius: '15px',
            width: '100%',
            transition: '0.3s',
            _hover: {
              color: 'primaryDark',
            },
            '&.active': {
              color: 'primaryDark',
              bg: 'white',
              boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)',
              // cursor: 'default',
              '.sideNavSquare': { bg: 'gradientBrand' },
              // '.sideNavIcon': { color: 'white' },
              '.multicolorElToWhite': {
                fill: 'white',
              },
              '.multicolorElToDark': {
                fill: 'secondaryDark',
              },
            },
            '.sideNavSquare': { bg: 'white', borderRadius: '12px' },
            '.sideNavIcon': { transition: '0.3s' },
          },
          '.logoutSideBar': {
            fontFamily: 'Inter Bold, sans-serif',
            fontSize: '13px',
            //color: 'secondaryDark08',
            color: mode('secondaryDark08', '#DDDDDD')(props),
            padding: '11px 14px',
            borderRadius: '15px',
            width: '100%',
            transition: '0.3s',
            _hover: {
              color: 'primaryDark',
            },
            '.sideNavSquare': { bg: 'white', borderRadius: '12px' },
            '.sideNavIcon': { transition: '0.3s' },
          },
        },
        '.chakra-ui-dark .sideNav': {
          a: {
            _hover: {
              color: 'white',
            },
            '&.active': { bg: 'primaryDark', color: 'white' },
            '&.inactive': {
              '.sideNavSquare': { bg: 'primaryDark' },
              '.multicolorElToWhite': {
                fill: 'white',
              },
            },
          },
        },
        '.toolTipTriangleMyAcc': {
          position: 'absolute',
          display: 'block',
          top: '-2%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderBottom: '5px solid',
          borderBottomColor: mode('#fff', '#2D3748')(props),
        },
        '.toolTipTriangleClientDashboard': {
          position: 'absolute',
          display: 'block',
          top: '100%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderLeft: '15px solid transparent',
          borderRight: '15px solid transparent',
          borderTop: '15px solid',
          borderTopColor: mode('#3B4851', '#ddd')(props),
        },
        '.footerNav': {
          a: {
            _hover: {
              textDecoration: 'underline',
            },
            '&.active': {
              textDecoration: 'underline',
            },
          },
        },
        '.multicolorDarkBg': {
          '.darkBgElToWhite': {
            fill: 'white',
          },
          '.darkBgElToBrand': {
            fill: 'brand',
          },
        },
        '.reactColorfulParent': {
          _before: {
            content: '"#"',
            position: 'absolute',
            top: '5px',
            left: '60px',
            color: 'rgba(59, 72, 81, 0.8)',
            pointerEvents: 'none',
          },
        },
        '.chakra-ui-dark .reactColorfulParent': {
          _before: {
            color: '#DDDDDD',
          },
        },
        '.reactColorfulInput': {
          display: 'block',
          boxSizing: 'border-box',
          border: '2px solid transparent',
          borderRadius: '25px',
          background: '#fff',
          outline: 'none',
          font: 'inherit',
          textTransform: 'uppercase',
          textAlign: 'left',
          paddingLeft: '24px',
          fontSize: '14px',
          height: '32px',
          lineHeight: '32px',
          width: '106px',
          transition: '0.3s',
          _hover: {
            borderColor: '#3B4851',
          },
          _focus: {
            borderColor: '#3B4851',
          },
        },
        '.chakra-ui-dark .reactColorfulInput': {
          background: 'primaryDark',
        },
        '.publicHeading': {
          fontSize: '3.125rem !important',
          lineHeight: '4.06rem !important',
        },
        '.publicLightParagraph': {
          fontSize: '1.25rem',
          fontWeight: '350',
          lineHeight: '1.75rem',
          color: 'rgba(59, 72, 81, 0.8)',
        },
        '.publicParagraph': {
          fontSize: '1.625rem',
          fontWeight: '600',
          lineHeight: '2.25rem',
          color: 'rgba(59, 72, 81, 0.8)',
        },
        '.navbar-active': {
          borderBottom: '2px solid #FF8477',
        },
        '.animation-1': {
          opacity: '1 !important',
          scale: '1 !important',
        },
        '.animation-2': {
          opacity: '1 !important',
          left: '-10% !important',
        },
        '.animation-3': {
          opacity: '1 !important',
          left: '30% !important',
        },
        '.animation-4': {
          opacity: '1 !important',
          left: '-30% !important',
        },
        '.chakra-collapse': {
          overflow: 'initial !important',
        },
      }),
    },
    semanticTokens: {
      colors: {
        //brand: '#FF8477',
        brand: {
          default: '#FF8477',
          _dark: '#FF8477',
        },
        brandDark: {
          default: '#EC6759',
          _dark: '#EC6759',
        },

        // 29323a
        apiCodeRed: '#e94040',
        apiCodeBlue: '#008fff',
        androidGPayLabel: '#212121',
        androidGPayContent: '#616161',
        applePayLabel: '#8C8C8E',
        applePayUrl: '#1B76E0',
        primaryDark: '#29323A',
        primaryDark06: 'rgba(41, 50, 58, 0.6)',
        primaryDark03: 'rgba(41, 50, 58, 0.3)',
        secondaryDark: { default: '#3B4851', _dark: '#3B4851' },
        secondaryDark08: 'rgba(59, 72, 81, 0.8)',
        secondaryDark06: 'rgba(59, 72, 81, 0.6)',
        error: { default: '#E53E3E', _dark: '#FF8477' },
        success: '#1DBE68',
        warning: '#FBC207',
        removed: '#6C747E',
        blueLight: '#0DB2F9',
        yellowLight: '#FFC759',
        primaryBackground: {
          default: '#DDDDDD',
          _dark: 'rgba(59, 72, 81, 0.8)',
        },
        secondaryBackground: '#EAEAEA',
        tertiaryBackground: '#FBFBFB',
        quaternaryBackground: '#F5F3F1',
        quinaryBackground: {
          default: '#EFEFEF',
          _dark: '#3B4851',
        },
        gradient:
          'linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)',
        gradientBrand:
          'linear-gradient(81.62deg, #FF8477 2.25%, #EC6759 79.87%)',
        gradientDark: 'linear-gradient(180deg, #29323A 0%, #3B4851 100%);',
      },
    },
    fonts: {
      heading: 'Inter Bold, sans-serif',
      body: 'Inter Regular, sans-serif',
      // body: 'Body Font Name, sans-serif',
    },
    textStyles: {
      headingFamilyMedium: {
        fontFamily: 'Inter Medium, sans-serif',
        lineHeight: '130%',
      },
      bodyFamilyRegular: {
        fontFamily: 'Inter Regular, sans-serif',
      },
      bodyFamilyMedium: {
        fontFamily: 'Inter Medium, sans-serif',
      },
      bodyRoman: {
        // you can also use responsive styles
        fontSize: '14px',
        lineHeight: '140%',
      },
      bodyMedium: {
        fontFamily: 'Inter Medium, sans-serif',
        fontSize: '15px',
        lineHeight: '140%',
      },
      bodyBold: {
        fontFamily: 'Inter Bold, sans-serif',
        fontSize: '16px',
        lineHeight: '140%',
      },
      headingFamilyRegularGooglePay: {
        fontFamily: 'Open Sans Regular, sans-serif',
        lineHeight: '130%',
      },
      headingFamilySemiBoldGooglePay: {
        fontFamily: 'Open Sans SemiBold, sans-serif',
        lineHeight: '130%',
      },
      headingFamilyRegularApplePay: {
        fontFamily: 'Sf Pro Display Regular, sans-serif',
        lineHeight: '130%',
      },
      headingFamilyMediumApplePay: {
        fontFamily: 'Sf Pro Display Medium, sans-serif',
        lineHeight: '130%',
      },
      passDownloadHeaderText: {
        fontFamily: 'Cera Pro Medium, sans-serif',
        fontSize: '24px',
        lineHeight: '32px',
      },
    },
    components: {
      Input: {
        variants: {
          filled: (props) => ({
            field:
              props.colorMode === 'dark'
                ? {
                    ...stylesControls,
                    ...stylesControlsForDarkMode,
                  }
                : {
                    ...stylesControls,
                    ...stylesControlsForLightBg,
                  },
          }),

          filledForDarkBg: (props) => ({
            field:
              props.colorMode === 'dark'
                ? {
                    ...stylesControls,
                    ...stylesControlsForDarkMode,
                  }
                : {
                    ...stylesControls,
                    ...stylesControlsForDarkBg,
                  },
          }),
          filledNoLeftBorder: (props) => ({
            field:
              props.colorMode === 'dark'
                ? {
                    ...stylesControls,
                    ...stylesControlsForDarkMode,
                  }
                : {
                    ...stylesControls,
                    ...stylesControlsForDarkBg,
                    border: '0px',
                    ':hover': {
                      border: '0px',
                    },
                    ':focus': {
                      border: '0px',
                    },
                  },
          }),
        },
        sizes: {
          ...sizesControls,
        },
      },
      Select: {
        variants: {
          filled: (props) => ({
            field:
              props.colorMode === 'dark'
                ? {
                    ...stylesControls,
                    ...stylesControlsForDarkMode,
                  }
                : {
                    ...stylesControls,
                    ...stylesControlsForLightBg,
                  },
          }),
          filledForDarkBg: (props) => ({
            field:
              props.colorMode === 'dark'
                ? {
                    ...stylesControls,
                    ...stylesControlsForDarkMode,
                  }
                : {
                    ...stylesControls,
                    ...stylesControlsForDarkBg,
                  },
          }),
          filledForDarkBg2: (props) => ({
            field:
              props.colorMode === 'dark'
                ? {
                    ...stylesControls,
                    ...stylesControlsForDarkMode,
                    backgroundColor: 'secondaryDark',
                  }
                : {
                    ...stylesControls,
                    ...stylesControlsForDarkBg,
                  },
          }),
          countryCode: {
            field: {
              borderRadius: '0px',
              backgroundColor: 'transparent',
            },
            icon: {
              color: '#dddd',
            },
          },
        },
        sizes: {
          ...sizesControls,
        },
      },
      Accordion: {
        variants: {
          docs: {
            container: {
              borderTopWidth: '0px',
              borderColor: 'transparent',
              _last: {
                borderBottomWidth: '0px',
              },
            },
            panel: {
              pt: 0,
              px: 0,
              pb: 0,
            },
            button: {
              width: 'fit-content',
              borderBottom: '2px solid transparent',
              px: 0,
              py: 1,
              _focus: {
                boxShadow: 'none',
              },
              _hover: {
                bg: 'none',
                borderColor: 'brand',
              },
            },
          },
          faq: {
            container: {
              mt: 4,
              borderTop: 'none',
              _last: {
                borderBottomWidth: '0px',
              },
            },
            panel: {
              p: 4,
              color: 'secondaryDark08',
            },
            button: {
              borderBottom: '1px solid #ddd',
              pl: '0',
              _focus: {
                boxShadow: 'none',
              },
              _hover: {
                bg: 'none',
              },
            },
          },
        },
      },
      Skeleton: {
        baseStyle: {
          borderRadius: '6px',
          opacity: 0.6,
        },
      },
      Popover: {
        baseStyle: {
          content: {
            border: 'none',
            borderRadius: '15px',
            boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
            _focus: {
              // boxShadow: 'none',
              boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
            },
          },
        },
      },
      Textarea: {
        variants: {
          filled: (props) => ({
            backgroundColor: 'white',
            borderRadius: '20px',
            _placeholder: {
              color: 'rgba(59, 72, 81, 0.6)',
            },
            _hover: {
              backgroundColor: '#fff',
              border: 'solid 2px #3B4851',
            },
            _focus: {
              backgroundColor: '#fff',
              border: 'solid 2px #3B4851',
            },
            paddingTop: '10px !important',
            lineHeight: '140%',
            fontSize: '14px',
          }),
          outlined: (props) => ({
            backgroundColor: '#EEEEEE',
            borderRadius: '15px',
            _placeholder: {
              color: 'rgba(59, 72, 81, 0.6)',
            },
            _hover: {
              border: 'solid 2px #3B4851',
              paddingTop: '8px !important',
            },
            _focus: {
              backgroundColor: '#fff',
              border: 'solid 2px #3B4851',
              paddingTop: '8px !important',
            },
            paddingTop: '10px !important',
            lineHeight: '140%',
            fontSize: '14px',
          }),
        },
      },
      NumberInput: {
        baseStyle: (props) => ({
          // root: {
          //   color: 'error',
          // },
          // field: {
          //   color: 'success',
          // },
          stepper: {
            _first: {
              border: 'none',
              mr: '10px',
              _active: {
                bg: 'quinaryBackground',
              },
            },
            _last: {
              mt: '0',
              borderLeft: 'none',
              borderTop:
                props.colorMode === 'dark'
                  ? 'solid 1px rgba(255, 255, 255, 0.4)'
                  : 'solid 1px rgba(59, 72, 81, 0.4)',
              mr: '10px',
              _active: {
                bg: 'quinaryBackground',
              },
            },
          },
        }),
        variants: {
          filled: (props) => ({
            field:
              props.colorMode === 'dark'
                ? {
                    ...stylesControls,
                    ...stylesControlsForDarkMode,
                    pl: '16px',
                    _selection: {
                      bg: 'quinaryBackground',
                    },
                  }
                : {
                    ...stylesControls,
                    ...stylesControlsForLightBg,
                    pl: '16px',
                    _selection: {
                      bg: 'quinaryBackground',
                    },
                  },
          }),
          filledForDarkBg: (props) => ({
            field:
              props.colorMode === 'dark'
                ? {
                    ...stylesControls,
                    ...stylesControlsForDarkMode,
                    pl: '16px',
                    _selection: {
                      bg: 'transparent',
                    },
                  }
                : {
                    ...stylesControls,
                    ...stylesControlsForDarkBg,
                    pl: '16px',
                    _selection: {
                      bg: 'transparent',
                    },
                  },
          }),
        },
        sizes: {
          ...sizesControls,
        },
      },
      Form: {
        baseStyle: {
          requiredIndicator: {
            color: 'error',
            marginStart: 0,
          },
          helperText: {
            fontSize: '12px',
            mt: 0,
            lineHeight: '140%',
          },
        },
      },
      FormLabel: {
        baseStyle: {
          fontFamily: 'Inter Medium, sans-serif',
          fontSize: '14px',
          mb: '6px',
          lineHeight: '140%',
        },
        variants: {
          adminLabel: {
            fontFamily: 'Inter Medium, sans-serif',
            fontSize: '17px',
          },
        },
      },
      FormError: {
        baseStyle: {
          text: {
            fontSize: '12px',
            mt: 0,
            lineHeight: '140%',
          },
        },
      },
      Radio: {
        baseStyle: {
          label: {
            ml: '15px',
            transition: 'all 0.2s',
          },
        },
        sizes: {
          md: (props) => ({
            label: {
              fontSize: '14px',
              fontWeight: 400,
              color: props.colorMode === 'dark' ? '#DDDDDD' : 'secondaryDark08',
              _checked: {
                color: props.colorMode === 'dark' ? 'white' : 'primaryDark',
              },
              _hover: {
                color: props.colorMode === 'dark' ? 'white' : 'primaryDark',
              },
            },

            control: {
              w: '20px',
              h: '20px',
              borderColor:
                props.colorMode === 'dark' ? '#DDDDDD' : 'secondaryDark08',
              transition: 'all 0.2s',
              _hover: {
                borderColor: 'brand',
              },
              _checked: {
                borderColor: 'brand',
                bg: 'transparent',
                _before: {
                  bg: 'brand',
                },
                _hover: {
                  borderColor: 'brand',
                  bg: 'transparent',
                },
              },
              _focus: {
                ring: 0,
              },
            },
          }),
        },
      },
      Heading: {
        sizes: {
          md: {
            fontSize: '13px',
            lineHeight: '150%',
          },
          lg: {
            fontSize: '18px',
            lineHeight: '130%',
          },
          xl: {
            fontSize: '24px',
            lineHeight: '130%',
          },
          '2xl': {
            fontSize: '32px',
            lineHeight: '130%',
          },
        },
      },
      Container: {
        baseStyle: {
          // "maxW" should be used, instead of "maxWidth", so we can override it with props inside the code
          maxW: '100%',
        },
        variants: {
          bodyContent: {
            flex: '1 0 auto',
            padding: 0,
            display: 'flex',
          },
          apiContent: {
            flex: '1 0 auto',
            paddingTop: '0rem',
            paddingBottom: '0rem',
            display: 'flex',
          },
        },
      },
      Link: {
        baseStyle: {
          // cursor: 'crosshair',
          _hover: {
            // textDecoration: 'none',
            // color: 'secondaryDark'
          },
          _focus: {
            boxShadow: 'none',
          },
        },
        variants: {
          navbar: {
            color: 'white',
            fontSize: '1rem',
            fontFamily: 'Inter Bold, sans-serif',
            fontWeight: 500,
            whiteSpace: 'nowrap',
            textTransform: 'capitalize',
            _hover: {
              textDecoration: 'none',
              color: 'brand',
            },
          },
        },
      },
      Switch: {
        baseStyle: (props) => ({
          track: {
            width: '32px',
            height: '14px',
            bg:
              props.colorMode === 'dark'
                ? 'rgba(221, 221, 221, 0.5)'
                : 'primaryBackground',
            _focus: {
              boxShadow: 'none',
            },
            _checked: {
              bg: 'brand',
            },
          },
          thumb: {
            width: '14px',
            height: '14px',
            _checked: {
              transform: 'translateX(18px)',
            },
          },
        }),
      },
      Button: {
        sizes: {
          xs: {
            height: '28px', // To override we need here "height"
            lineHeight: '28px',
            fontSize: '14px',
          },
          sm: {
            height: '36px', // To override we need here "height"
            lineHeight: '36px',
            fontSize: '14px',
          },

          md: {
            height: '45px', // To override we need here "height"
            lineHeight: '24px',
            fontSize: '16px',
          },
        },
        variants: {
          gradient: {
            ring: 0,
            // ringColor: 'gray.200',
            bgGradient: 'linear(to-r, brand 2.25%, brandDark 80%)',
            color: 'white',
            fontFamily: 'Space Grotesk Bold, sans-serif',
            fontWeight: '700',
            textTransform: 'uppercase',
            px: '40px',
            h: '45px', // To override we need here "h"
            borderRadius: '25px',
            _focus: {
              ring: 0,
            },
            _hover: {
              // bgGradient: 'linear(to-r, brandDark, brandDark)',
              bgGradient: 'linear(to-r, brand 2.25%, brandDark 80%)',
              _disabled: {
                bgGradient: 'linear(to-r, brand 2.25%, brandDark 80%)',
              },
              _before: {
                opacity: 1,
              },
            },
            _active: { bgGradient: 'linear(to-r, brand 2.25%, brandDark 80%)' },
            _disabled: {
              opacity: 0.6,
              color: 'rgba(255, 255, 255, 0.6)',
              _before: {
                display: 'none',
              },
              // bgGradient:
              //   'linear(to-r, rgba(255, 132, 119, 0.6) 2.25%, rgba(236, 103, 89, 0.6) 80%))',
            },
            _before: {
              // content: '" "',
              // content: 'attr(alt)',
              // 'pointer-events': 'none',
              pointerEvents: 'none',
              bgGradient: 'linear(to-l, brand 2.25%, brandDark 80%)',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              borderRadius: '25px',
              opacity: 0,
              transition: '0.3s',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          },
          secondary: (props) => ({
            ring: 0,
            //backgroundColor: 'primaryBackground',
            backgroundColor:
              props.colorMode === 'dark' ? 'secondaryDark' : '#DDDDDD',
            color: props.colorMode === 'dark' ? '#DDDDDD' : 'secondaryDark08',
            fontFamily: 'Space Grotesk Bold, sans-serif',
            fontWeight: '700',
            textTransform: 'uppercase',
            px: '40px',
            h: '45px', // To override we need here "h"
            borderRadius: '25px',
            _focus: {
              ring: 0,
            },
            _hover: {
              // backgroundColor: '#CCCCCC',
              backgroundColor:
                props.colorMode === 'dark' ? 'secondaryDark08' : '#CCCCCC',
              _disabled: {
                backgroundColor:
                  props.colorMode === 'dark' ? 'secondaryDark08' : '#DDDDDD',
              },
            },
            _active: {
              backgroundColor:
                props.colorMode === 'dark' ? 'secondaryDark' : '#DDDDDD',
            },
            _disabled: {
              opacity: 0.6,
            },
          }),
          white: (props) => ({
            ring: 0,
            backgroundColor:
              props.colorMode === 'dark' ? 'primaryDark' : 'white',
            color: props.colorMode === 'dark' ? 'white' : 'primaryDark',
            fontFamily: 'Space Grotesk Bold, sans-serif',
            fontWeight: '700',
            textTransform: 'uppercase',
            px: '40px',
            h: '45px', // To override we need here "h"
            borderRadius: '25px',
            _focus: {
              ring: 0,
            },
            _hover: {
              backgroundColor:
                props.colorMode === 'dark'
                  ? 'primaryDark06'
                  : 'primaryBackground',
              _disabled: {
                backgroundColor:
                  props.colorMode === 'dark' ? 'primaryDark' : 'white',
              },
            },
            _active: {
              backgroundColor:
                props.colorMode === 'dark' ? 'primaryDark' : 'white',
            },
            _disabled: {
              opacity: 0.6,
            },
          }),
          white02: (props) => ({
            ring: 0,
            backgroundColor:
              props.colorMode === 'dark' ? 'secondaryDark' : 'white',
            color: props.colorMode === 'dark' ? 'white' : 'secondaryDark',
            fontFamily: 'Space Grotesk Bold, sans-serif',
            fontWeight: '700',
            textTransform: 'uppercase',
            px: '40px',
            h: '45px', // To override we need here "h"
            borderRadius: '25px',
            _focus: {
              ring: 0,
            },
            _hover: {
              backgroundColor:
                props.colorMode === 'dark'
                  ? 'secondaryDark06'
                  : 'primaryBackground',
              _disabled: {
                backgroundColor:
                  props.colorMode === 'dark' ? 'secondaryDark' : 'white',
              },
            },
            _active: {
              backgroundColor:
                props.colorMode === 'dark' ? 'secondaryDark' : 'white',
            },
            _disabled: {
              opacity: 0.6,
            },
          }),
          link: {
            color: 'brand',
            _focus: { ring: 0, color: 'brand' },
            _active: {
              color: 'brand',
            },
            _hover: {
              textDecoration: 'underline',
              textDecorationColor: 'brand',
            },
          },
          mobileStepper: (props) => ({
            ring: 0,
            backgroundColor:
              props.colorMode === 'dark' ? 'secondaryDark' : 'white',
            color: props.colorMode === 'dark' ? 'white' : 'secondaryDark',
            h: '45px', // To override we need here "h"
            borderRadius: '10px',
            _focus: {
              ring: 0,
            },
            _hover: {
              backgroundColor:
                props.colorMode === 'dark'
                  ? 'secondaryDark06'
                  : 'primaryBackground',
              _disabled: {
                backgroundColor:
                  props.colorMode === 'dark' ? 'secondaryDark' : 'white',
              },
            },
            _active: {
              backgroundColor:
                props.colorMode === 'dark' ? 'secondaryDark' : 'white',
            },
            _disabled: {
              opacity: 0.6,
            },
          }),
          gPay: {
            ring: 0,
            backgroundColor: '#000',
            color: '#fff',
            h: '60px', // To override we need here "h"
            width: '280px',
            borderRadius: '30px',
            _focus: {
              ring: 0,
            },
            _hover: {
              opacity: '0.7',
            },
          },
          applePay: {
            ring: 0,
            backgroundColor: '#000',
            color: '#fff',
            borderRadius: '15px',
            _focus: {
              ring: 0,
            },
            _hover: {
              opacity: '0.7',
            },
          },
          myAccount: (props) => ({
            ring: 0,
            backgroundColor: 'transparent',
            color: props.colorMode === 'dark' ? 'white' : 'primaryDark',
            fontFamily: 'Space Grotesk Bold, sans-serif',
            fontSize: '14px',
            h: '30px',
            border: 'none',
            _focus: {
              ring: 0,
            },
          }),
          dashTime: (props) => ({
            ring: 0,
            backgroundColor: props.colorMode === 'dark' ? '#3B4851' : 'white',
            color: props.colorMode === 'dark' ? 'white' : 'primaryDark',
            fontWeight: '400',
            px: '16px',
            h: '18px',
            borderRadius: '25px',
            _focus: {
              ring: 0,
            },
            _hover: {
              border:
                props.colorMode === 'dark'
                  ? '2px solid #EFEFEF'
                  : '2px solid #29323A',
            },
          }),
          previewStepper: {
            ring: 0,
            backgroundColor: 'brand',
            color: 'white',
            px: '40px',
            h: '45px', // To override we need here "h"
            borderRadius: '25px',
            _focus: {
              ring: 0,
            },
            _hover: {
              backgroundColor: 'brand',
              opacity: 0.7,
              _disabled: {
                backgroundColor: 'brand',
              },
            },
            _active: {
              backgroundColor: 'brand',
            },
            _disabled: {
              opacity: 0.6,
            },
          },
        },
      },
      Drawer: {
        sizes: {
          sm: {
            dialog: { maxW: '250px' },
          },
        },
      },
      CloseButton: {
        baseStyle: {
          _focus: {
            boxShadow: 'none',
          },
        },
      },
      Divider: {
        baseStyle: {
          opacity: 1,
        },
      },
      Modal: {
        sizes: {
          xl: (props) => ({
            dialog: {
              maxW: '560px',
              borderRadius: '15px',
              bg: props.colorMode === 'dark' ? 'primaryDark' : 'white',
            },
            header: {
              padding: '24px 48px 0 24px',
            },
            body: {
              padding: '24px',
            },
            footer: {
              padding: '0 24px 24px',
            },
          }),

          l: (props) => ({
            dialog: {
              maxW: '560px',
              borderRadius: '15px',
              bg: props.colorMode === 'dark' ? 'primaryDark' : 'white',
            },
            header: {
              padding: '24px 48px 0 24px',
            },
            body: {
              padding: '24px 24px 10px',
            },
            footer: {
              padding: '24px',
            },
          }),
        },
      },
      Menu: {
        baseStyle: {
          list: {},
          item: {},
          divider: {},
        },
        variants: {
          myAccount: {
            list: {
              p: '0',
              borderRadius: '6px',
              border: 'none',
              minW: 'auto',
              boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
            },
            item: {
              fontSize: '14px',
              textStyle: 'bodyFamilyMedium',
              _hover: { bg: 'quinaryBackground' },
              p: 3,
              _focus: {
                bg: 'quinaryBackground',
              },
            },
          },
        },
      },
      Breadcrumb: {
        baseStyle: {
          link: {
            // color: 'red',
          },
        },
      },
      Table: {
        // The rest of the styling for Table component is within react-table columns
        baseStyle: (props) => ({
          // tr: {},
          th: {
            fontSize: '12px',
            fontFamily: 'Inter Medium, sans-serif',
            textTransform: 'uppercase',
            // textAlign: 'start',
            letterSpacing: 'wide',
          },
          td: {
            fontSize: '14px',
            textAlign: 'start',
            '&.modeTdColor': {
              color: props.colorMode === 'dark' ? 'white' : 'primaryDark',
            },
          },
        }),
        variants: {
          simple: (props) => ({
            tbody: {
              tr: {
                bg: props.colorMode === 'dark' ? 'transparent' : 'white',
                transition: '0.3s',
                h: '63px',
                cursor: 'pointer',
                _hover: {
                  bg:
                    props.colorMode === 'dark'
                      ? 'primaryDark'
                      : 'rgba(221, 221, 221, 0.5)',
                },
              },
            },
            th: {
              color: props.colorMode === 'dark' ? '#DDDDDD' : 'secondaryDark08',
              borderColor:
                props.colorMode === 'dark'
                  ? 'primaryDark'
                  : 'primaryBackground',
            },
            td: {
              color: props.colorMode === 'dark' ? '#DDDDDD' : 'secondaryDark08',
              borderColor:
                props.colorMode === 'dark'
                  ? 'primaryDark'
                  : 'primaryBackground',
            },
          }),
        },
        sizes: {
          md: {
            th: {
              // paddingLeft: 0,
              // paddingRight: '12px',
              px: '10px',
              py: '9px',
            },
            td: {
              px: '10px',
              py: '6px',
            },
          },
        },
      },
      Badge: {
        baseStyle: {
          px: '10px',
          py: '6px',
          textTransform: 'none',
          borderRadius: '12px',
          lineHeight: '100%',
          fontWeight: '400',
          color: 'white',
          bg: 'brand',
        },
        variants: {
          issued: {
            bg: '#0DB2F9',
            color: 'white',
          },
          active: {
            bg: 'success',
            color: 'white',
          },
          pending: {
            bg: 'warning',
            color: 'white',
          },
          removed: {
            bg: 'removed',
            color: 'white',
          },
          error: {
            bg: 'error',
            color: 'white',
          },
          error02: {
            bg: '#E53E3E',
            color: '#fff',
          },
          disabled: {
            bg: '#9AA0A4',
            color: 'primaryDark',
            border: 'none',
          },
        },
      },
      Checkbox: {
        baseStyle: (props) => ({
          control: {
            borderRadius: '2px',
            borderColor:
              props.colorMode === 'dark' ? '#DDDDDD' : 'secondaryDark08',
            _checked: {
              bg: 'brand',
              borderColor: 'brand',
              color: 'white',
              _hover: {
                bg: 'brand',
                borderColor: 'brand',
              },
              _disabled: {
                bg:
                  props.colorMode === 'dark' ? '#DDDDDD' : 'primaryBackground',
                borderColor:
                  props.colorMode === 'dark' ? '#DDDDDD' : 'primaryBackground',
                color:
                  props.colorMode === 'dark'
                    ? 'secondaryDark06'
                    : 'secondaryDark06',
              },
            },
            _indeterminate: {
              bg: 'brand',
              borderColor: 'brand',
              color: 'white',
            },
            _focus: {
              boxShadow: 'none',
              ring: 0,
            },
            _disabled: {
              bg: props.colorMode === 'dark' ? '#DDDDDD' : 'primaryBackground',
              borderColor:
                props.colorMode === 'dark' ? '#DDDDDD' : 'primaryBackground',
              color:
                props.colorMode === 'dark'
                  ? 'secondaryDark06'
                  : 'secondaryDark06',
            },
          },
          label: {
            fontFamily: 'Inter Medium, sans-serif',
            color: props.colorMode === 'dark' ? 'white' : 'secondaryDark',
            ml: 3,
            position: 'relative',
            top: '1px',
          },
        }),
        sizes: {
          md: {
            control: {
              w: '14px',
              h: '14px',
            },
            label: { fontSize: '16px' },
            icon: {
              fontSize: '8px',
            },
          },
        },
      },
      Tabs: {
        variants: {
          line: (props) => ({
            tablist: {
              borderColor:
                props.colorMode === 'dark'
                  ? 'primaryDark'
                  : 'primaryBackground',
            },
            tab: {
              color: props.colorMode === 'dark' ? '#DDDDDD' : 'secondaryDark08',
              _selected: {
                color: props.colorMode === 'dark' ? 'white' : 'secondaryDark',
                borderColor: 'brandDark',
              },
              _focus: {
                boxShadow: 'none',
              },
              _active: {
                background: 'none',
              },
              _hover: {
                color: props.colorMode === 'dark' ? 'white' : 'secondaryDark',
              },
            },
          }),
        },
      },
      Slider: {
        baseStyle: {
          filledTrack: { bg: 'brand' },
        },
        sizes: {
          md: {
            thumb: {
              w: '14px',
              h: '14px',
              bg: 'gradientBrand',
              _focus: {
                boxShadow: 'none',
              },
            },
            track: {
              bg: 'primaryBackground',
              h: '2px',
            },
          },
        },
      },
      Spinner: {
        baseStyle: {
          width: '52px',
          height: '52px',
          thickness: '4px',
        },
        sizes: {
          md: {
            color: 'brand',
            thickness: '4px',
          },
        },
      },
      Stepper: {
        baseStyle: (props) => ({
          colorScheme: 'primaryBackground',
          indicator: {
            borderColor: props.colorMode === 'dark' ? '#DDDDDD' : '#9F9F9F',
          },
        }),
      },
      Box: {},
    },
    breakpoints: {
      xl2: '83.5em', // 1336px
      xl3: '90em', // 1440px
    },
  },
  withDefaultVariant({
    variant: 'filled',
    components: ['Input', 'Select', 'Textarea', 'NumberInput'], // colide with components array from other withDef...
  }),
  withDefaultVariant({
    variant: 'gradient',
    components: ['Button'], // colide with components array from other withDef...
  })
);
