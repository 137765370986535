import {
  Box,
  Heading,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { CustomAppleIcon } from '../../../theme/icons/CustomAppleIcon';
import { CustomAndroidIcon } from '../../../theme/icons/CustomAndroidIcon';
import PreviewDesktop from './PreviewDesktop';
import PassDownloadTemplateContext from '../../../store/client/PassDownloadTemplateContext';
import PreviewMobileScreens from './PreviewMobileScreens';
import { useState, useEffect, useContext } from 'react';

const PreviewDownloadScreens = () => {
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const [isLargeForParallelDisplay] = useMediaQuery('(min-width: 1530px)');
  const [activeStep, setActiveStep] = useState(0);
  const [currentTab, setCurrentTab] = useState('');
  const passDownloadCtx = useContext(PassDownloadTemplateContext);
  const { skipPreview } = passDownloadCtx;
  const [isSkipPreview, setIsSkipPreview] = useState(true);

  useEffect(() => {
    if (currentTab === 'desktop') {
      setActiveStep(0);
    }
  }, [currentTab]);

  useEffect(() => {
    setIsSkipPreview(skipPreview);
  }, [skipPreview]);

  useEffect(() => {
    setActiveStep(0);
  }, [skipPreview]);

  return (
    <Box>
      <Box
        bg={modeBoxBg}
        borderRadius="15px"
        pb={8}
        zIndex="1"
        minW={{ base: 'none', md: 'fit-content' }}
        className="primaryBoxShadow"
        position="sticky"
        top="20px"
        width="100%"
        transition="top 0.3s"
      >
        <Heading data-testid="preview-header" size="lg" p="24px 0 0">
          <Text
            pl={
              isLargeForParallelDisplay ? '20px' : { base: '14px', md: '32px' }
            }
          >
            Preview
          </Text>
        </Heading>
        <Tabs px={{ base: '14px', md: '32px' }} pt={2}>
          <TabList>
            <Tab onClick={() => setCurrentTab('desktop')}>
              <HStack alignItems="baseline">
                <Text textStyle="bodyMedium">Desktop</Text>
              </HStack>
            </Tab>
            <Tab onClick={() => setCurrentTab('apple')}>
              <HStack alignItems="baseline">
                <CustomAppleIcon fontSize="18px" />
                <Text textStyle="bodyMedium">iOS</Text>
              </HStack>
            </Tab>
            <Tab onClick={() => setCurrentTab('android')}>
              <HStack alignItems="baseline">
                <CustomAndroidIcon fontSize="18px" />
                <Text textStyle="bodyMedium">Android</Text>
              </HStack>
            </Tab>
          </TabList>
          <TabPanels mt={8}>
            <TabPanel px="0">
              <PreviewDesktop />
            </TabPanel>
            <TabPanel px="0">
              <PreviewMobileScreens
                type="Apple"
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                isSkipPreview={isSkipPreview}
              />
            </TabPanel>
            <TabPanel px="0">
              <PreviewMobileScreens
                type="Google"
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                isSkipPreview={isSkipPreview}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default PreviewDownloadScreens;
