import {
  Box,
  Text,
  Divider,
  useColorModeValue,
  Heading,
  VStack,
  Center,
  Button,
  Link,
} from '@chakra-ui/react';

const ViewApiDocs = () => {
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', 'primaryDark08');
  const modeApiSectionBg = useColorModeValue('#efefef', '#35414a');

  return (
    <Box className="autofillForDarkBg" w="full">
      <Box className="primaryBoxShadow" bg={modeBoxBg} borderRadius="15px">
        <VStack
          bg={modeFormControlBg}
          borderRadius="15px 15px 0 0"
          p="35px 23px"
          spacing={7}
        >
          <Heading>PassEntry API documentation</Heading>
          <Box>
            <Text>
              Documentation for our API can be found here:{' '}
              <Link
                href="https://docs.passentry.com"
                isExternal
                textDecoration="underline"
                color="brand"
              >
                {/* {process.env.REACT_APP_URL_BASE}/docs/api */}
                https://docs.passentry.com
              </Link>
            </Text>
          </Box>
          <Box>
            <Text>
              If you don't have a technical team or simply need help
              understanding our API, please feel free to contact us:{' '}
              <Link
                href="mailto:info@passentry.com"
                textDecoration="underline"
                color="brand"
              >
                info@passentry.com
              </Link>
            </Text>
          </Box>
        </VStack>
        <Divider borderColor={modeBorderColor} />
        <Center
          pt={9}
          pb={9}
          backgroundColor={modeApiSectionBg}
          borderBottomLeftRadius="15px"
          borderBottomRadius="15px"
        >
          <VStack spacing={5} p={2}>
            <Heading fontSize="16px">View API documentation</Heading>
            <Button
              mt={{ base: '12px', sm: '0' }}
              alt="View documentation"
              size="sm"
            >
              <Link
                href="https://docs.passentry.com"
                isExternal
                textDecoration="none"
              >
                View documentation
              </Link>
            </Button>
          </VStack>
        </Center>
      </Box>
    </Box>
  );
};
export default ViewApiDocs;
