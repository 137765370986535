import { PassDownloadTemplateContextProvider } from '../../store/client/PassDownloadTemplateContext';
import PassDownloadTemplateEditPage from './PassDownloadTemplateEditPage';
import UsePassDownloadTemplate from '../../hooks/usePassDownloadTemplate';

const PassDownloadTemplatePage = () => {
  return (
    <PassDownloadTemplateContextProvider>
      <UsePassDownloadTemplate>
        <PassDownloadTemplateEditPage />
      </UsePassDownloadTemplate>
    </PassDownloadTemplateContextProvider>
  );
};

export default PassDownloadTemplatePage;
