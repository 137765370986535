import React from 'react';
import {
  Flex,
  IconButton,
  Text,
  useColorModeValue,
  Box,
} from '@chakra-ui/react';
import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';
import PreviewMobileScreen from './PreviewMobileScreen';

const PreviewMobileScreens = ({
  type,
  activeStep,
  setActiveStep,
  isSkipPreview,
}) => {
  const modePrevNext = useColorModeValue('primary', 'white');

  //Render only the active screen
  const renderScreen = () => {
    if (!isSkipPreview) {
      switch (activeStep) {
        case 0:
          return <PreviewMobileScreen type="addToWallet" device={type} />;
        case 1:
          return <PreviewMobileScreen type="building" device={type} />;
        case 2:
          return <PreviewMobileScreen type="success" device={type} />;
        case 3:
          return <PreviewMobileScreen type="failure" device={type} />;
        default:
          return null;
      }
    } else {
      // Skipped preview
      switch (activeStep) {
        case 0:
          return <PreviewMobileScreen type="building" device={type} />;
        case 1:
          return <PreviewMobileScreen type="success" device={type} />;
        case 2:
          return <PreviewMobileScreen type="failure" device={type} />;
        default:
          return null;
      }
    }
  };

  //Render the title text
  const renderTitle = () => {
    if (!isSkipPreview) {
      if (activeStep === 0) return '"Add to Wallet" screen';
      if (activeStep === 1) return 'Building screen';
      if (activeStep === 2) return 'Success screen';
      if (activeStep === 3) return 'Failure screen';
    } else {
      // Skipped preview
      if (activeStep === 0) return 'Building screen';
      if (activeStep === 1) return 'Success screen';
      if (activeStep === 2) return 'Failure screen';
    }
    return '';
  };

  return (
    <div data-testid={`preview-pass-download-${type}`}>
      <Flex
        direction="column"
        alignItems="center"
        gap="10px"
        display={{ base: 'none', sm: 'flex' }}
      >
        <Text
          textAlign="center"
          textStyle="bodyBold"
          fontSize="16px"
          color="secondaryDark"
        >
          {renderTitle()}
        </Text>
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap="60px"
        >
          <IconButton
            aria-label="previous-button-desktop"
            onClick={() => setActiveStep(activeStep - 1)}
            isDisabled={activeStep === 0}
            icon={
              <CustomChevronLeftIcon w="24px" h="24px" color={modePrevNext} />
            }
            variant={useColorModeValue('previewStepper', 'white')}
            w="36px"
            h="38px"
            borderRadius="full"
          />
          {renderScreen()}
          <IconButton
            aria-label="next-button-desktop"
            onClick={() => setActiveStep(activeStep + 1)}
            isDisabled={!isSkipPreview ? activeStep === 3 : activeStep === 2}
            icon={
              <CustomChevronLeftIcon
                transform="rotate(180deg)"
                w="24px"
                h="24px"
                color={modePrevNext}
              />
            }
            variant={useColorModeValue('previewStepper', 'white')}
            w="36px"
            h="38px"
            borderRadius="full"
          />
        </Flex>
      </Flex>
      <Flex
        direction="column"
        alignItems="center"
        gap="10px"
        display={{ base: 'flex', sm: 'none' }}
        w="100%"
      >
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          w="100%"
          maxW="400px"
          px={6}
        >
          <IconButton
            aria-label="previous-button-mobile"
            onClick={() => setActiveStep(activeStep - 1)}
            isDisabled={activeStep === 0}
            icon={
              <CustomChevronLeftIcon w="24px" h="24px" color={modePrevNext} />
            }
            _hover={activeStep !== 0 ? { opacity: 1 } : {}}
            variant={useColorModeValue('previewStepper', 'white')}
            size="sm"
            w="36px"
          />
          <Text
            textAlign="center"
            textStyle="bodyBold"
            fontSize="16px"
            color="secondaryDark"
          >
            {renderTitle()}
          </Text>
          <IconButton
            aria-label="next-button-mobile"
            onClick={() => setActiveStep(activeStep + 1)}
            isDisabled={!isSkipPreview ? activeStep === 3 : activeStep === 2}
            icon={
              <CustomChevronLeftIcon
                transform="rotate(180deg)"
                w="24px"
                h="24px"
                color={modePrevNext}
              />
            }
            _hover={
              !isSkipPreview
                ? activeStep === 2
                : activeStep === 3
                  ? { opacity: 1 }
                  : {}
            }
            variant={useColorModeValue('previewStepper', 'white')}
            size="sm"
            w="36px"
          />
        </Flex>
        <Box>{renderScreen()}</Box>
      </Flex>
    </div>
  );
};

export default PreviewMobileScreens;
