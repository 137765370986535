import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { styled } from '@stitches/react';

import { CustomClockIcon } from '../../../theme/icons/CustomClockIcon';
import EventsContext from '../../../store/client/EventsContext';
import PassContext from '../../../store/client/PassContext';

const Wrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '15px',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
  zIndex: '15',
  fontFamily: 'Inter Medium, sans-serif',
});

const TopBar = styled('div', {
  width: '100%',
  height: '50px',
  borderTopLeftRadius: '15px',
  borderTopRightRadius: '15px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  variants: {
    topBarBackgroundColor: {
      lightMode: { backgroundColor: '#fff' },
      darkMode: { backgroundColor: '#29323A' },
    },
  },
});

const TimeDisplay = styled('span', {
  marginLeft: '10px',
  padding: '5px',
  variants: {
    timeDisplayColor: {
      lightMode: { color: '#29323a' },
      darkMode: { color: '#fff' },
    },
  },
});

const MeridiemBtn = styled('span', {
  marginRight: '10px',
  padding: '5px',
  cursor: 'pointer',
  variants: {
    meridiemBtnColor: {
      lightMode: { color: '#29323a' },
      darkMode: { color: '#fff' },
    },
  },
  '&:hover': {
    color: '#ff8477',
  },
});

const CircleWrapper = styled('div', {
  height: '100%',
  width: '100%',
  paddingTop: '10px',
  paddingBottom: '10px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottomLeftRadius: '15px',
  borderBottomRightRadius: '15px',
  variants: {
    clockBackgroundColor: {
      lightMode: { backgroundColor: '#EFEFEF' },
      darkMode: { backgroundColor: '#3B4851' },
    },
  },
});

const Circle = styled('div', {
  width: '200px',
  height: '200px',
  borderRadius: '50%',
  position: 'relative',
  variants: {
    clockBackgroundColor: {
      lightMode: { backgroundColor: '#fff' },
      darkMode: { backgroundColor: '#29323a' },
    },
  },
});

const ResetBtn = styled('button', {
  fontSize: 14,
  paddingLeft: '10px',
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  '&:hover': {
    color: '#FF8477',
  },
  variants: {
    resetColor: {
      lightMode: { color: '#29323A' },
      darkMode: { color: '#fff' },
    },
  },
});

const OkBtn = styled('button', {
  fontSize: 14,
  backgroundColor: '#FF8477',
  border: 'none',
  cursor: 'pointer',
  marginRight: '10px',
  padding: '2px 5px',
  borderRadius: '20%',
  '&:hover': {
    opacity: 0.8,
    color: '#fff',
  },
  variants: {
    resetColor: {
      lightMode: { color: '#29323A' },
      darkMode: {
        color: '#fff',
        '&:hover': {
          color: '#29323A',
        },
      },
    },
  },
});

const Hours = styled('span', {
  width: '30px',
  height: '30px',
  position: 'absolute',
  marginLeft: '-15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#ff8477',
    color: '#fff',
  },
  variants: {
    handPosition: {
      one: { top: '13%', left: '69%' },
      two: { top: '26%', left: '82%' },
      three: { top: '44%', left: '86%' },
      four: { top: '61%', left: '81%' },
      five: { top: '74%', left: '68%' },
      six: { top: '78%', left: '49%' },
      seven: { top: '72%', left: '32%' },
      eight: { top: '59%', left: '19%' },
      nine: { top: '41%', left: '15%' },
      ten: { top: '24%', left: '21%' },
      eleven: { top: '12%', left: '34%' },
      twelve: { top: '7%', left: '52%' },
    },
    numberColor: {
      lightMode: { color: '#29323a' },
      darkMode: { color: '#fff' },
    },
  },
});

const CenterDot = styled('div', {
  width: '6px',
  height: '6px',
  position: 'absolute',
  top: '49%',
  left: '49%',
  backgroundColor: '#ff8477',
  borderRadius: '100px',
});

const LongHand = styled('div', {
  height: '78px',
  width: '2px',
  background: '#ff8477',
  position: 'absolute',
  left: '50%',
  top: '0%',
  marginTop: '22px',
  transformOrigin: '50% 100%',
  transition: 'transform 300ms ease-out',
});

const LongHandAttachment = styled('div', {
  width: '35px',
  height: '35px',
  borderRadius: '100%',
  background: '#ff8477',
  position: 'absolute',
  top: '-10px',
  left: '50%',
  marginLeft: '-15px',
});

const CustomTimePicker = ({
  timeValue,
  setTimeValue,
  expiryTime,
  placementTop,
  darkMode,
  isEdit,
  isDisabled,
}) => {
  const [showHours, setShowHours] = useState(true);
  const [hour, setHour] = useState('');
  const [minutes, setMinutes] = useState('');
  const [meridiem, setMeridiem] = useState('PM');
  const [selected, setSelected] = useState('');
  const [type, setType] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const eventCtx = useContext(EventsContext);
  const passCtx = useContext(PassContext);

  // if edit mode is true pre-populate time value
  useEffect(() => {
    if (isEdit) {
      if (!expiryTime) {
        // "Gate time" scenario
        setTimeValue(eventCtx.eventState.gateTime);
      } else {
        // "Expiry time" scenario
        setTimeValue(passCtx.passState.expiryTime);
      }
    }
  }, [
    eventCtx.eventState.gateTime,
    expiryTime,
    isEdit,
    passCtx.passState.expiryTime,
    setTimeValue,
  ]);

  // main time handling
  useEffect(() => {
    if (hour === '') {
      setTimeValue('');
      return;
    }
    setTimeValue(`${hour}:${minutes} ${meridiem}`);
  }, [hour, minutes, meridiem, setTimeValue]);

  const resetTimeHandler = () => {
    setHour('');
    setMinutes('');
    setMeridiem('PM');
    setTimeValue('');
  };

  const generalHourClickHandler = (e) => {
    const val = e.target.innerText;
    setSelected(val);

    if (showHours) {
      setType('hours');
      setShowHours(false);

      setHour(val < 10 ? '0' + val : val);
      setMinutes('00');
    } else {
      setType('minutes');
      setShowHours(true);
      setMinutes(val);
    }
  };

  const meridiemHandler = () => {
    setMeridiem((prev) => (prev === 'AM' ? 'PM' : 'AM'));
  };

  // long hand rotation logic
  let deg = (selected / (type === 'hours' ? 12 : 60)) * 360;

  return (
    <Popover
      placement={placementTop ? 'top' : 'bottom'}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      closeOnBlur
    >
      <PopoverTrigger>
        <InputGroup
          onClick={isDisabled ? undefined : onOpen}
          style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
        >
          <InputLeftElement pointerEvents="none" pl="5px" zIndex="1">
            <CustomClockIcon
              boxSize="20px"
              stopColor="#FF8477"
              stopcolor2="#EC6759"
            />
          </InputLeftElement>
          <Input
            placeholder="Select time"
            type="text"
            value={timeValue}
            isDisabled={isDisabled}
            readOnly
            pl="55px"
            pt="5px"
            variant="filledForDarkBg"
            cursor="pointer"
          />
        </InputGroup>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          border="none"
          bg="transparent"
          width="250px"
          height="257px"
        >
          <Box position="relative">
            <Wrapper placement={placementTop ? 'top' : 'bottom'}>
              <TopBar
                topBarBackgroundColor={darkMode ? 'darkMode' : 'lightMode'}
              >
                <TimeDisplay
                  timeDisplayColor={darkMode ? 'darkMode' : 'lightMode'}
                >
                  {timeValue}
                </TimeDisplay>
                <MeridiemBtn
                  meridiemBtnColor={darkMode ? 'darkMode' : 'lightMode'}
                  onClick={meridiemHandler}
                >
                  {meridiem}
                </MeridiemBtn>
              </TopBar>

              <CircleWrapper
                clockBackgroundColor={darkMode ? 'darkMode' : 'lightMode'}
              >
                <Circle
                  clockBackgroundColor={darkMode ? 'darkMode' : 'lightMode'}
                >
                  <CenterDot />
                  <LongHand
                    style={{
                      transform: `rotate(${deg}deg)`,
                    }}
                  >
                    <LongHandAttachment />
                  </LongHand>
                  <Hours
                    handPosition="one"
                    onClick={generalHourClickHandler}
                    numberColor={darkMode ? 'darkMode' : 'lightMode'}
                  >
                    {showHours ? '1' : '05'}
                  </Hours>
                  <Hours
                    handPosition="two"
                    onClick={generalHourClickHandler}
                    numberColor={darkMode ? 'darkMode' : 'lightMode'}
                  >
                    {showHours ? '2' : '10'}
                  </Hours>
                  <Hours
                    handPosition="three"
                    onClick={generalHourClickHandler}
                    numberColor={darkMode ? 'darkMode' : 'lightMode'}
                  >
                    {showHours ? '3' : '15'}
                  </Hours>
                  <Hours
                    handPosition="four"
                    onClick={generalHourClickHandler}
                    numberColor={darkMode ? 'darkMode' : 'lightMode'}
                  >
                    {showHours ? '4' : '20'}
                  </Hours>
                  <Hours
                    handPosition="five"
                    onClick={generalHourClickHandler}
                    numberColor={darkMode ? 'darkMode' : 'lightMode'}
                  >
                    {showHours ? '5' : '25'}
                  </Hours>
                  <Hours
                    handPosition="six"
                    onClick={generalHourClickHandler}
                    numberColor={darkMode ? 'darkMode' : 'lightMode'}
                  >
                    {showHours ? '6' : '30'}
                  </Hours>
                  <Hours
                    handPosition="seven"
                    onClick={generalHourClickHandler}
                    numberColor={darkMode ? 'darkMode' : 'lightMode'}
                  >
                    {showHours ? '7' : '35'}
                  </Hours>
                  <Hours
                    handPosition="eight"
                    onClick={generalHourClickHandler}
                    numberColor={darkMode ? 'darkMode' : 'lightMode'}
                  >
                    {showHours ? '8' : '40'}
                  </Hours>
                  <Hours
                    handPosition="nine"
                    onClick={generalHourClickHandler}
                    numberColor={darkMode ? 'darkMode' : 'lightMode'}
                  >
                    {showHours ? '9' : '45'}
                  </Hours>
                  <Hours
                    handPosition="ten"
                    onClick={generalHourClickHandler}
                    numberColor={darkMode ? 'darkMode' : 'lightMode'}
                  >
                    {showHours ? '10' : '50'}
                  </Hours>
                  <Hours
                    handPosition="eleven"
                    onClick={generalHourClickHandler}
                    numberColor={darkMode ? 'darkMode' : 'lightMode'}
                  >
                    {showHours ? '11' : '55'}
                  </Hours>
                  <Hours
                    handPosition="twelve"
                    onClick={generalHourClickHandler}
                    numberColor={darkMode ? 'darkMode' : 'lightMode'}
                  >
                    {showHours ? '12' : '00'}
                  </Hours>
                </Circle>
                <Flex
                  w="full"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <ResetBtn
                    type="button"
                    onClick={resetTimeHandler}
                    resetColor={darkMode ? 'darkMode' : 'lightMode'}
                  >
                    Reset time
                  </ResetBtn>
                  <OkBtn
                    type="button"
                    onClick={() => {
                      onClose();
                    }}
                    resetColor={darkMode ? 'darkMode' : 'lightMode'}
                  >
                    OK
                  </OkBtn>
                </Flex>
              </CircleWrapper>
            </Wrapper>
          </Box>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default CustomTimePicker;
