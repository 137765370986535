import {
  Badge,
  Box,
  Circle,
  Flex,
  HStack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';

const LiveEventDataRenderStatus = ({ value, errorMsg, inDrawer }) => {
  const [isSmallerThan540px] = useMediaQuery('(max-width: 540px)');

  return value ? (
    isSmallerThan540px && !inDrawer ? (
      <Circle bg="success" size="15px" />
    ) : (
      <Badge variant="active">Accepted</Badge>
    )
  ) : !value ? (
    isSmallerThan540px && !inDrawer ? (
      <Circle bg="error" size="15px" />
    ) : (
      <Flex direction="column" alignItems="flex-start">
        <Flex
          bg="#ddd"
          borderRadius="20px"
          justifyContent="center"
          alignItems="center"
          maxWidth="100px"
        >
          <HStack pr="12px" pl="12px">
            <Circle bg="error" size="12px" />
            <Box
              fontSize="12px"
              top="1px"
              pos="relative"
              pb="1px"
              color="secondaryDark"
            >
              Rejected
            </Box>
          </HStack>
        </Flex>
        <Text fontSize="11px" lineHeight="16px" mt="4px">
          {errorMsg}
        </Text>
      </Flex>
    )
  ) : (
    <Badge color="error">n/a</Badge>
  );
};

export default LiveEventDataRenderStatus;
