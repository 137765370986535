import { createContext, useState } from 'react';
import { ICON_BASE64, LOGO_BASE64 } from '../../utils/base64';
import placeholderLogo from '../../assets/images/logo.png';
import placeholderIcon from '../../assets/images/icon.png';

const initialLogo = {
  src: null,
  crop: { x: 0, y: 0 },
  zoom: 1,
  aspect: 1,
  mimeType: null,
};

const initialFaviconLogo = {
  src: null,
  crop: { x: 0, y: 0 },
  zoom: 1,
  aspect: 1,
  mimeType: null,
};

const PassDownloadTemplateContext = createContext({
  headerText: '',
  updateHeaderText: () => {},
  passDownloadText: '',
  updatePassDownloadText: () => {},
  clientWebsite: '',
  updateClientWebsite: () => {},
  pageTitle: '',
  updatePageTitle: () => {},
  skipPreview: true,
  updateSkipPreview: () => {},
  skipFooter: false,
  updateSkipFooter: () => {},
  logo: initialLogo,
  updateLogo: () => {},
  resetLogo: () => {},
  faviconLogo: initialFaviconLogo,
  updateFaviconLogo: () => {},
  resetFaviconLogo: () => {},
  logoImage: '',
  updateLogoImage: () => {},
  faviconImage: '',
  updateFaviconImage: () => {},
  componentFillColor: '',
  updateComponentFillColor: () => {},
  componentFontColor: '',
  updateComponentFontColor: () => {},
  backgroundColor: '',
  updateBackgroundColor: () => {},
  headerColor: '',
  updateHeaderColor: () => {},
  passDownloadTextColor: '',
  updatePassDownloadTextColor: () => {},
  footerColor: '',
  updateFooterColor: () => {},
  updateAllData: () => {},
});

export const PassDownloadTemplateContextProvider = ({ children }) => {
  const [backgroundColor, setBackgroundColor] = useState('#DDDDDD');
  const [headerText, setHeaderText] = useState('');
  const [headerColor, setHeaderColor] = useState('#3C4852');
  const [passDownloadText, setPassDownloadText] = useState('');
  const [passDownloadTextColor, setPassDownloadTextColor] = useState('#45525B');
  const [clientWebsite, setClientWebsite] = useState('');
  const [pageTitle, setPageTitle] = useState('Add pass to Wallet');
  const [skipPreview, setSkipPreview] = useState(true);
  const [skipFooter, setSkipFooter] = useState(false);
  const [logo, setLogo] = useState(initialLogo);
  const [faviconLogo, setFaviconLogo] = useState(initialFaviconLogo);
  const [logoImage, setLogoImage] = useState(LOGO_BASE64);
  const [faviconImage, setFaviconImage] = useState(ICON_BASE64);
  const [componentFillColor, setComponentFillColor] = useState('#FF8577');
  const [componentFontColor, setComponentFontColor] = useState('#FFFFFF');
  const [footerColor, setFooterColor] = useState('dark');

  function updateBackgroundColorHandler(color) {
    setBackgroundColor(color);
  }

  function updateHeaderTextHandler(text) {
    setHeaderText(text);
  }

  function updateHeaderColorHandler(color) {
    setHeaderColor(color);
  }

  function updatePassDownloadTextHandler(text) {
    setPassDownloadText(text);
  }

  function updatePassDownloadTextColorHandler(color) {
    setPassDownloadTextColor(color);
  }

  function updateClientWebsiteHandler(website) {
    setClientWebsite(website);
  }

  function updatePageTitleHandler(title) {
    setPageTitle(title);
  }

  function updateSkipPreviewHandler(skip) {
    setSkipPreview(skip);
  }

  function updateSkipFooterHandler(skip) {
    setSkipFooter(skip);
  }

  function updateLogoHandler(logo) {
    setLogo(logo);
  }

  function resetLogoHandler() {
    setLogo(initialLogo);
  }

  function updateFaviconLogoHandler(logo) {
    setFaviconLogo(logo);
  }

  function resetFaviconLogoHandler() {
    setFaviconLogo(initialFaviconLogo);
  }

  function updateLogoImageHandler(image) {
    setLogoImage(image);
  }

  function updateFaviconImageHandler(image) {
    setFaviconImage(image);
  }

  function updateComponentFillColorHandler(color) {
    setComponentFillColor(color);
  }

  function updateComponentFontColorHandler(color) {
    setComponentFontColor(color);
  }

  function updateFooterColorHandler(color) {
    setFooterColor(color);
  }

  function updateAllDataHandler(data) {
    const {
      backgroundColor,
      headerText,
      headerColor,
      passDownloadText,
      passDownloadTextColor,
      clientWebsite,
      pageTitle,
      skipPreview,
      skipFooter,
      logoImageUrl,
      faviconUrl,
      componentFillColor,
      componentFontColor,
    } = data;

    setBackgroundColor(backgroundColor || '#DDDDDD');
    setHeaderText(headerText || '');
    setHeaderColor(headerColor || '#3C4852');
    setPassDownloadText(passDownloadText || '');
    setPassDownloadTextColor(passDownloadTextColor || '#45525B');
    setClientWebsite(clientWebsite || '');
    setPageTitle(pageTitle || 'Add pass to Wallet');
    setSkipPreview(skipPreview);
    setSkipFooter(skipFooter);
    setComponentFillColor(componentFillColor || '#FF8577');
    setComponentFontColor(componentFontColor || '#FFFFFF');
    setLogoImage(logoImageUrl);
    setFaviconImage(faviconUrl);

    const logoUrl = logoImageUrl ?? placeholderLogo;
    const faviconImageUrl = faviconUrl ?? placeholderIcon;

    setLogo({
      ...initialLogo,
      src: logoUrl,
    });

    setFaviconLogo({
      ...initialFaviconLogo,
      src: faviconImageUrl,
    });
  }

  const context = {
    backgroundColor,
    updateBackgroundColor: updateBackgroundColorHandler,
    headerText,
    updateHeaderText: updateHeaderTextHandler,
    headerColor,
    updateHeaderColor: updateHeaderColorHandler,
    passDownloadText,
    updatePassDownloadText: updatePassDownloadTextHandler,
    passDownloadTextColor,
    updatePassDownloadTextColor: updatePassDownloadTextColorHandler,
    clientWebsite,
    updateClientWebsite: updateClientWebsiteHandler,
    pageTitle,
    updatePageTitle: updatePageTitleHandler,
    skipPreview,
    updateSkipPreview: updateSkipPreviewHandler,
    skipFooter,
    updateSkipFooter: updateSkipFooterHandler,
    logo,
    updateLogo: updateLogoHandler,
    resetLogo: resetLogoHandler,
    faviconLogo,
    updateFaviconLogo: updateFaviconLogoHandler,
    resetFaviconLogo: resetFaviconLogoHandler,
    logoImage,
    updateLogoImage: updateLogoImageHandler,
    faviconImage,
    updateFaviconImage: updateFaviconImageHandler,
    componentFillColor,
    updateComponentFillColor: updateComponentFillColorHandler,
    componentFontColor,
    updateComponentFontColor: updateComponentFontColorHandler,
    footerColor,
    updateFooterColor: updateFooterColorHandler,
    updateAllData: updateAllDataHandler,
  };

  return (
    <PassDownloadTemplateContext.Provider value={context}>
      {children}
    </PassDownloadTemplateContext.Provider>
  );
};

export default PassDownloadTemplateContext;
