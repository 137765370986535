import appsignal from '../../../appsignal';
import { useContext, useState } from 'react';
import {
  Button,
  Box,
  Text,
  Stack,
  Spinner,
  Center,
  useToast,
  HStack,
} from '@chakra-ui/react';
import CustomToast from '../../../common/CustomToast';
import { CustomCsvDownloadIcon } from '../../../theme/icons/CustomCsvDownloadIcon';
import { getErrorResponsePayload } from '../../../utils/ajax';
import { HttpContext } from '../../../context/HttpContext';
import { MeContext } from '../../../context/MeContext';

const MessageBatchResultDownload = (props) => {
  const batchId = props.batchId;
  const { authAxios } = useContext(HttpContext);
  const [isDownloading, setIsDownloading] = useState(false);
  const meCtx = useContext(MeContext);
  const toast = useToast();

  const generateMessageBatchResultData = async () => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    await authAxios.get(
      `api/v1/batches/${batchId}?export=true&timezone=${encodeURIComponent(
        userTimezone
      )}&email=${meCtx.state.account.email}`
    );

    try {
      toast({
        render: (props) => (
          <CustomToast
            status="info"
            title="Please check your email"
            description={`A download link has been sent to ${meCtx.state.account.email}. It may take a few minutes to arrive.`}
            onClose={props.onClose}
          />
        ),
      });
    } catch (error) {
      appsignal.sendError(error);
      console.log(error);
      setIsDownloading(false);
      const { message, code } = getErrorResponsePayload(error);
      code !== 401 &&
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title={message ? message : `Something went wrong`}
              description={!message && 'Please try again later'}
              onClose={props.onClose}
            />
          ),
        });
    }
  };

  return (
    <Stack
      spacing="18px"
      direction={{ base: 'column', sm: 'row' }}
      shouldWrapChildren="true"
      position="relative"
    >
      <Button
        size="sm"
        width={{ base: 'full', sm: '198px' }}
        onClick={() => {
          generateMessageBatchResultData();
        }}
        isLoading={isDownloading}
      >
        {isDownloading ? (
          <Center as="span" zIndex={1}>
            <Spinner
              width="15px"
              height="15px"
              thickness="2px"
              speed="0.65s"
              emptyColor="quinaryBackground"
              mx="auto"
            />
          </Center>
        ) : (
          <Box as="span" zIndex={1}>
            <HStack alignItems="center" justifyContent="center">
              <CustomCsvDownloadIcon boxSize="20px" />
              <Text>Download CSV</Text>
            </HStack>
          </Box>
        )}
      </Button>
    </Stack>
  );
};

export default MessageBatchResultDownload;
