import {
  Flex,
  Heading,
  Spacer,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import useOrganizationStatus from '../../../hooks/useOrganisationStatus';
import { useState } from 'react';
import { useContext } from 'react';
import { MeContext } from '../../../context/MeContext';
import LiveEventDataListDownload from './LiveEventDataListDownload';
import LiveEventDataListFilter from './LiveEventDataListFilter';
import LiveEventDataListContext from '../../../store/client/LiveEventDataListContext';

function getFiltersString(filters) {
  const { reader, pass, scanType, status, validationType, groupTag } = filters;
  if (
    !reader &&
    !pass &&
    !scanType &&
    !status &&
    !validationType &&
    !groupTag
  ) {
    return '';
  }

  let values = [];

  if (reader !== '') {
    values.push(reader.label);
  }
  if (pass !== '') {
    values.push(pass);
  }
  if (scanType !== '') {
    values.push(scanType.label);
  }
  if (status !== '') {
    values.push(status.label);
  }
  if (validationType !== '') {
    values.push(validationType.label);
  }
  if (groupTag !== '') {
    values.push(groupTag.label);
  }

  const count = values.length;
  let outString = '';
  values.forEach((value, index) => {
    if (index === count - 1) {
      outString = outString + value;
    } else {
      outString = outString + value + ', ';
    }
  });

  return outString;
}

const LiveEventDataHeader = () => {
  const { shouldRender } = useOrganizationStatus();
  const [loadingDashHeader, setLoadingDashHeader] = useState(true);
  const [loadingDashBody, setLoadingDashBody] = useState(true);
  const liveEventDataListCtx = useContext(LiveEventDataListContext);
  const filters = getFiltersString(liveEventDataListCtx.filterValues);
  const filterModeColor = useColorModeValue('secondaryDark08', 'white');

  return (
    <Flex
      flexDirection={{ base: 'column', sm: 'row' }}
      flexWrap="wrap"
      mt="15px"
      py="9px"
      minH="54px"
    >
      <Heading alignSelf={{ base: 'flex-start', sm: 'center' }}>
        {filters === '' ? 'Scans' : `Filter results: `}
        <Text
          as="span"
          display="inline-block"
          textStyle="bodyFamilyMedium"
          fontSize="16px"
          color={filterModeColor}
          wordBreak="break-word"
        >
          {filters}
        </Text>
      </Heading>
      <Spacer />
      <Flex
        gap={3}
        direction={{ base: 'column', sm: 'row' }}
        alignItems={{ base: 'flex-start' }}
        mt={{ base: 3, md: 0 }}
      >
        {shouldRender && (
          <>
            {/* <LiveEventDataListDownload /> */}
            <LiveEventDataListFilter
              loadingDashHeader={loadingDashHeader}
              loadingDashBody={loadingDashBody}
              setLoadingDashBody={setLoadingDashBody}
              setLoadingDashHeader={setLoadingDashHeader}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};
export default LiveEventDataHeader;
