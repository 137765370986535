import { Box, Text, Image, Flex, Heading } from '@chakra-ui/react';
import { LOGO_BASE64, QR_BASE64 } from '../../../utils/base64';
import PassDownloadTemplateContext from '../../../store/client/PassDownloadTemplateContext';
import { useContext } from 'react';
import footerLight from '../../../assets/vectors/footer-light.svg';
import footerDark from '../../../assets/vectors/footer-dark.svg';

const PreviewWebsitePage = () => {
  const passDownloadCtx = useContext(PassDownloadTemplateContext);

  return (
    <Flex direction="column" w="100%" align="center">
      <Text
        textStyle="bodyBold"
        fontSize="16px"
        color="secondaryDark"
        textAlign={{ base: 'center', md: 'left' }}
        mt={4}
      >
        Website page
      </Text>
      <Box
        data-testid="preview-website-page"
        mt="15px"
        w="100%"
        maxW={{ base: '320px', sm: '500px', md: '600px' }}
        // border="2px solid lightgray"
        border="1px solid #ccc"
        boxShadow="0px 6px 13px rgb(0 0 0 / 15%)"
        bg={passDownloadCtx.backgroundColor}
        minH="600px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={{ base: 4, md: 8 }}
        position="relative"
      >
        {/* Header text */}
        {passDownloadCtx.headerText && (
          <Text
            fontFamily="avenir"
            data-testid="header-text"
            textStyle="headingFamilyMedium"
            fontSize={{ base: '20px', sm: '24px' }}
            fontWeight="600"
            color={passDownloadCtx.headerColor}
            textAlign="center"
            mb={{ base: 4, md: 6 }}
          >
            {passDownloadCtx.headerText}
          </Text>
        )}
        <Flex
          data-testid="download-info-card"
          direction="column"
          justifyContent="center"
          alignItems="center"
          bg="white"
          borderRadius="15px"
          width="100%"
          maxW={{ base: '260px', sm: '390px' }}
          mx="auto"
          py={{ base: '20px', sm: '30px' }}
          px={{ base: '15px', sm: '30px' }}
        >
          <Image
            data-testid="logo-image"
            width="200px"
            src={passDownloadCtx.logoImage || LOGO_BASE64}
            alt="pass-download-web-page"
          />
          {/* Pass Download Text */}
          {passDownloadCtx.passDownloadText && (
            <Text
              fontFamily="avenir"
              textAlign="center"
              mt={4}
              data-testid="body-text"
              textStyle="bodyRegular"
              fontSize={{ base: '14px', sm: '16px' }}
              lineHeight="120%"
              color={passDownloadCtx.passDownloadTextColor}
            >
              {passDownloadCtx.passDownloadText}
            </Text>
          )}
          {/* QR Code */}
          <Image
            mt={3}
            data-testid="qr-code-image"
            boxSize={{ base: '70px', sm: '100px' }}
            src={QR_BASE64}
            alt="pass-download-web-page"
          />

          <Heading
            fontFamily="avenir"
            size="lg"
            fontWeight="black"
            fontSize={{ base: '15px', sm: '20px' }}
            color={passDownloadCtx.passDownloadTextColor || '#3C4852'}
            mt={2}
            textAlign="center"
          >
            Scan QR code
          </Heading>
          <Text
            fontFamily="avenir"
            fontSize={{ base: '14px', sm: '16px' }}
            color={passDownloadCtx.passDownloadTextColor || '#3C4852'}
          >
            to add pass to wallet
          </Text>
        </Flex>
        {/* Footer */}
        {!passDownloadCtx.skipFooter && (
          <Box
            data-testid="passentry-footer"
            position="absolute"
            bottom="20px"
            left="50%"
            transform="translateX(-50%)"
          >
            <Image
              width="150px"
              src={
                passDownloadCtx.footerColor === 'dark'
                  ? footerDark
                  : footerLight
              }
              alt="pass-download-web-page"
            />
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default PreviewWebsitePage;
