import PreviewWebsitePage from './PreviewWebsitePage';
import PreviewBrowserTab from './PreviewBrowserTab';
import { Divider, Flex } from '@chakra-ui/react';

const PreviewDesktop = () => {
  return (
    <Flex direction="column" align="center">
      <PreviewWebsitePage />
      <Divider py={4} />
      <PreviewBrowserTab />
    </Flex>
  );
};

export default PreviewDesktop;
