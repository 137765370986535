import {
  Flex,
  Box,
  Square,
  useColorModeValue,
  List,
  ListItem,
  VStack,
  Text,
  FormErrorMessage,
  FormControl,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Portal,
  useOutsideClick,
} from '@chakra-ui/react';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import React, { useRef, useState } from 'react';
import { CustomEyeDropperIcon } from '../../../theme/icons/CustomEyeDropperIcon';
import CanvasEyeDropper from './CanvasEyeDropper';

function CustomColorPicker({ color, onChange, hexError, parent }) {
  const [dropperImage, setDropperImage] = useState();
  const modePickerPopupBg = useColorModeValue('white', 'primaryDark');
  const modeEyeDropperIconColor = useColorModeValue('primaryDark', 'white');
  const ref = useRef();
  const [isHexPickerOpen, setIsHexPickerOpen] = useState(false);
  const [isEyeDropperMenuOpen, setIsEyeDropperMenuOpen] = useState(false);
  const [isEyeDropperOpen, setIsEyeDropperOpen] = useState(false);

  useOutsideClick({
    ref: ref,
    handler: () => {
      setIsHexPickerOpen(false);
      setIsEyeDropperMenuOpen(false);
      setIsEyeDropperOpen(false);
    },
  });

  const handleEyeDropperMenuItemClick = (image) => {
    setDropperImage(image);
    handleEyeDropperOpen();
  };

  const handleHexPickerClick = () => {
    setIsHexPickerOpen(!isHexPickerOpen);
    setIsEyeDropperOpen(false);
    setIsEyeDropperMenuOpen(false);
  };

  const handleEyeDropperMenuClick = () => {
    setIsEyeDropperMenuOpen(!isEyeDropperMenuOpen);
    setIsHexPickerOpen(false);
  };

  const handleEyeDropperOpen = () => {
    setIsEyeDropperOpen(!isEyeDropperOpen);
    setIsHexPickerOpen(false);
    setIsEyeDropperMenuOpen(false);
  };

  return (
    <Flex
      display="inline-flex"
      verticalAlign="top"
      pos="relative"
      ref={ref}
      className="reactColorfulParent"
    >
      <Flex direction="column">
        <Flex gap="15px">
          <Popover placement="top-start" isOpen={isHexPickerOpen}>
            <PopoverTrigger>
              <Square
                size={8}
                borderRadius="4px"
                bg={color}
                border="solid 1px #333"
                cursor="pointer"
                onClick={handleHexPickerClick}
              />
            </PopoverTrigger>
            <Portal>
              <PopoverContent
                bg={modePickerPopupBg}
                boxShadow="0 6px 12px rgba(0, 0, 0, 0.15)"
                overflow="hidden"
                w="auto"
              >
                <PopoverArrow />
                <PopoverBody p={4}>
                  <HexColorPicker color={color} onChange={onChange} />
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>

          <HexColorInput
            color={color}
            onChange={onChange}
            className="reactColorfulInput"
          />

          <Popover placement="top-start" isOpen={isEyeDropperMenuOpen}>
            <PopoverTrigger>
              <Box alignSelf="center">
                <CustomEyeDropperIcon
                  boxSize="20px"
                  fill={modeEyeDropperIconColor}
                  cursor="pointer"
                  onClick={handleEyeDropperMenuClick}
                />
              </Box>
            </PopoverTrigger>
            <Portal>
              <PopoverContent
                bg={modePickerPopupBg}
                boxShadow="0 6px 12px rgba(0, 0, 0, 0.15)"
                overflow="hidden"
                maxWidth="200px"
              >
                <PopoverArrow />
                <PopoverBody>
                  <Popover isOpen={isEyeDropperOpen}>
                    <PopoverTrigger>
                      <VStack align="flex-start">
                        <Text
                          fontSize="14px"
                          fontFamily="Inter Bold"
                          px={2}
                          pt={2}
                        >
                          Choose an image to pick a colour from:
                        </Text>
                        {parent === 'download_page' ? (
                          <List width="full" mt={1}>
                            <ListItem
                              fontSize="14px"
                              textStyle="bodyFamilyMedium"
                              p={2}
                              _hover={{ bg: 'quinaryBackground' }}
                              cursor="pointer"
                              onClick={() =>
                                handleEyeDropperMenuItemClick('download-logo')
                              }
                            >
                              Logo image
                            </ListItem>
                            <ListItem
                              fontSize="14px"
                              textStyle="bodyFamilyMedium"
                              p={2}
                              _hover={{ bg: 'quinaryBackground' }}
                              cursor="pointer"
                              onClick={() =>
                                handleEyeDropperMenuItemClick(
                                  'download-favicon'
                                )
                              }
                            >
                              Favicon image
                            </ListItem>
                          </List>
                        ) : (
                          <List width="full" mt={1}>
                            <ListItem
                              fontSize="14px"
                              textStyle="bodyFamilyMedium"
                              p={2}
                              _hover={{ bg: 'quinaryBackground' }}
                              cursor="pointer"
                              onClick={() =>
                                handleEyeDropperMenuItemClick('apple')
                              }
                            >
                              Logo for iOS version
                            </ListItem>
                            <ListItem
                              fontSize="14px"
                              textStyle="bodyFamilyMedium"
                              p={2}
                              _hover={{ bg: 'quinaryBackground' }}
                              cursor="pointer"
                              onClick={() =>
                                handleEyeDropperMenuItemClick('android')
                              }
                            >
                              Logo for Android version
                            </ListItem>
                            <ListItem
                              fontSize="14px"
                              textStyle="bodyFamilyMedium"
                              p={2}
                              borderBottomLeftRadius="9px"
                              borderBottomRightRadius="9px"
                              _hover={{ bg: 'quinaryBackground' }}
                              cursor="pointer"
                              onClick={() =>
                                handleEyeDropperMenuItemClick('banner')
                              }
                            >
                              Banner image
                            </ListItem>
                          </List>
                        )}
                      </VStack>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent
                        bg={modePickerPopupBg}
                        boxShadow="0 6px 12px rgba(0, 0, 0, 0.15)"
                        overflow="hidden"
                        w="auto"
                      >
                        <PopoverArrow />
                        <PopoverBody p={4}>
                          <CanvasEyeDropper
                            color={color}
                            onChange={onChange}
                            dropperImage={dropperImage}
                          />
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </Flex>

        <FormControl isInvalid={hexError} mt="5px">
          <FormErrorMessage>{hexError}</FormErrorMessage>
        </FormControl>
      </Flex>
    </Flex>
  );
}

export default CustomColorPicker;
