import { useContext, useEffect } from 'react';
import { useTable } from 'react-table';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  useMediaQuery,
  HStack,
  Hide,
  useColorMode,
  useColorModeValue,
  Spinner,
  Flex,
  Button,
  Show,
} from '@chakra-ui/react';
import TableBox from '../../common/TableBox';
import EmptyState from '../../../common/EmptyState';
import EmptyArtistAnimated from '../../../theme/illustrations-animated/EmptyArtistAnimated';
import EmptyArtistDarkAnimated from '../../../theme/illustrations-animated/EmptyArtistDarkAnimated';
import LiveEventDataListMoreInfo from './LiveEventDataListMoreInfo';
import LiveEventDataRenderStatus from './LiveEventDataRenderStatus';
import { CustomUserAcceptedMultiIcon } from '../../../theme/multicolor-icons/CustomUserAcceptedMultiIcon';
import { CustomUserRejectedMultiIcon } from '../../../theme/multicolor-icons/CustomUserRejectedMultiIcon';
import LiveEventDataListContext from '../../../store/client/LiveEventDataListContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment/moment';
import { useState } from 'react';
import { CustomVisibilityIcon } from '../../../theme/multicolor-icons/CustomVisibilityIcon';
import { useNavigate } from 'react-router';
import { MeContext } from '../../../context/MeContext';
import { keyframes } from '@emotion/react';

// Create a default prop getter (per documentation https://react-table.tanstack.com/docs/examples/data-driven-classes-and-styles)
const defaultPropGetter = () => ({});

export default function LiveEventDataListTable({
  columns,
  data,
  update,
  query,
  newScanCounter,
  isLoading,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
  } = useTable({
    columns,
    data,
  });
  const navigate = useNavigate();
  const meCtx = useContext(MeContext);
  const accountEmail = meCtx.state.account.email;

  const [isLargerThan2XL] = useMediaQuery('(min-width: 96em)');
  const [isLargerThanXL] = useMediaQuery('(min-width: 80em)');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanSM] = useMediaQuery('(min-width: 30em)');
  const [isLargerThanS] = useMediaQuery('(min-width: 18em)');
  const [isLargerThanXS] = useMediaQuery('(min-width: 15em)');
  const [isLargerThanXXS] = useMediaQuery('(min-width: 10em)');
  const { colorMode } = useColorMode();
  const modeSvgIcon = useColorModeValue(
    <EmptyArtistAnimated />,
    <EmptyArtistDarkAnimated />
  );

  useEffect(() => {
    if (isLargerThan2XL) {
      setHiddenColumns(['']);
    } else if (isLargerThanXL) {
      setHiddenColumns(['']);
    } else if (isLargerThanLG) {
      setHiddenColumns(['validation', 'type']);
    } else if (isLargerThanMD) {
      setHiddenColumns(['validation']);
    } else if (isLargerThanSM) {
      setHiddenColumns(['externalId', 'viewPass', 'type', 'validation']);
    } else if (isLargerThanS) {
      setHiddenColumns([
        'externalId',
        'time',
        'type',
        'validation',
        'viewPass',
      ]);
    } else if (isLargerThanXS) {
      setHiddenColumns([
        'externalId',
        'time',
        'type',
        'validation',
        'viewPass',
      ]);
    } else if (isLargerThanXXS) {
      setHiddenColumns([
        'externalId',
        'time',
        'type',
        'validation',
        'viewPass',
      ]);
    } else {
      setHiddenColumns([
        'externalId',
        'validation',
        'time',
        'type',
        'viewPass',
      ]);
    }
  }, [
    isLargerThan2XL,
    isLargerThanLG,
    isLargerThanMD,
    isLargerThanS,
    isLargerThanSM,
    isLargerThanXL,
    isLargerThanXS,
    isLargerThanXXS,
    setHiddenColumns,
  ]);

  const {
    initialLiveEventData: { hasMore, items, total },
  } = useContext(LiveEventDataListContext);

  // scan tap animation setup
  const successBg = '#90eebc';
  const rejectedBg = '#f08f8f';

  const keyframeSuccess1 = keyframes`
    0% {
      background-color: 'transparent';
    }
    25% {
      background-color: ${successBg};
    }
    50% {
      background-color: ${successBg};
    }
    75% {
      background-color: ${successBg};
    }
    100% {
      background-color: 'transparent';
    }
  `;

  const keyframeRejected1 = keyframes`
    0% {
      background-color: 'transparent';
    }
    25% {
      background-color: ${rejectedBg};
    }
    50% {
      background-color: ${rejectedBg};
    }
    75% {
      background-color: ${rejectedBg};
    }
    100% {
      background-color: 'transparent';
    }
  `;

  if (isLoading) {
    return (
      <Spinner
        width="52px"
        height="52px"
        thickness="4px"
        speed="0.65s"
        emptyColor="quinaryBackground"
        mt={4}
        mx="auto"
      />
    );
  }

  // Handle no results yet and bad filter search
  if (items.length === 0 && !isLoading && query === '') {
    return (
      <EmptyState
        iconSvgAnimated={modeSvgIcon}
        heading="No data yet."
        text="When we receive data from the readers it will be presented here."
      />
    );
  }

  if (items.length === 0 && !isLoading && query !== '') {
    return (
      <EmptyState
        iconSvgAnimated={modeSvgIcon}
        heading="No results found."
        text="Try adjusting your filters to find what you're looking for."
      />
    );
  }

  return (
    <>
      <TableBox liveEventData>
        <Heading size="lg" mb="16px">
          Live reader scans {total ? `(${total})` : ''}
        </Heading>
        <InfiniteScroll
          dataLength={items.length}
          next={update}
          hasMore={hasMore}
          scrollableTarget="scrollableTable"
        >
          <Table {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()} pointerEvents="none">
                  {headerGroup.headers.map((column) => {
                    return (
                      <Th
                        {...column.getHeaderProps()}
                        {...column.getToggleHiddenProps()}
                        isNumeric={column.isNumeric}
                      >
                        {column.render('Header')}
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>

            {items.length > 0 && (
              <Tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <Tr
                      {...row.getRowProps()}
                      style={{ cursor: 'unset' }}
                      animation={
                        row.id === '0' && newScanCounter > 0
                          ? `${row.original.status === 'successful' ? keyframeSuccess1 : keyframeRejected1} 1s linear`
                          : undefined
                      }
                    >
                      {row.cells.map((cell) => {
                        return (
                          <Td
                            {...cell.getCellProps([
                              {
                                className: cell.column.className,
                                style: cell.column.style,
                              },
                              getColumnProps(cell.column),
                              getCellProps(cell),
                            ])}
                            isNumeric={cell.column.isNumeric}
                            pointerEvents={cell.column.id !== 'moreInfo'}
                          >
                            {cell.row.original.status === 'successful' &&
                            cell.column.id === 'externalId' ? (
                              <Box>
                                <HStack spacing={3}>
                                  <Hide below="xl">
                                    <Box>
                                      <CustomUserAcceptedMultiIcon
                                        boxSize="40px"
                                        mode={
                                          colorMode === 'dark' ? 'dark' : null
                                        }
                                      />
                                    </Box>
                                  </Hide>
                                  <Text>
                                    {cell.row.original.externalId || 'N/A'}
                                  </Text>
                                </HStack>
                              </Box>
                            ) : cell.row.original.status === 'failed' &&
                              cell.column.id === 'externalId' ? (
                              <Box>
                                <HStack spacing={3}>
                                  <Hide below="xl">
                                    <Box>
                                      <CustomUserRejectedMultiIcon
                                        boxSize="40px"
                                        mode={
                                          colorMode === 'dark' ? 'dark' : null
                                        }
                                      />
                                    </Box>
                                  </Hide>
                                  <Text>
                                    {cell.row.original.externalId || 'N/A'}
                                  </Text>
                                </HStack>
                              </Box>
                            ) : cell.column.id === 'viewPass' &&
                              cell.row.original.passUuid ? (
                              <Button
                                variant="link"
                                pt="5px"
                                onClick={() =>
                                  navigate(cell.row.original.viewPass)
                                }
                              >
                                <Flex
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Show below="sm">
                                    <CustomVisibilityIcon
                                      className={
                                        colorMode === 'dark' &&
                                        'multicolorDarkBg'
                                      }
                                    />
                                  </Show>
                                  <Show above="xl">
                                    <CustomVisibilityIcon
                                      className={
                                        colorMode === 'dark' &&
                                        'multicolorDarkBg'
                                      }
                                    />
                                  </Show>
                                  <Text
                                    textStyle="headingFamilyMedium"
                                    fontWeight="500"
                                    pl="7px"
                                    pt="1px"
                                  >
                                    View
                                  </Text>
                                </Flex>
                              </Button>
                            ) : cell.column.id === 'status' ? (
                              <LiveEventDataRenderStatus
                                value={
                                  cell.row.original.status === 'successful'
                                }
                                errorMsg={cell.row.original.statusDetail}
                              />
                            ) : cell.column.id === 'moreInfo' ? (
                              <LiveEventDataListMoreInfo cell={cell}>
                                <LiveEventDataRenderStatus
                                  value={cell.row.original.success}
                                  errorMsg={cell.row.original.reasonFailed}
                                  inDrawer
                                />
                              </LiveEventDataListMoreInfo>
                            ) : !!cell.value && cell.column.id === 'time' ? (
                              <Flex
                                gap={1}
                                direction={{ base: 'column', '2xl': 'row' }}
                              >
                                <Text>{cell.value.split(' ')[0]}</Text>
                                <Text>
                                  {cell.value.split(' ')[1]}{' '}
                                  {cell.value.split(' ')[2]}
                                </Text>
                              </Flex>
                            ) : cell.value !== null ? (
                              <Text>{cell.value}</Text>
                            ) : null}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            )}
          </Table>
        </InfiniteScroll>
      </TableBox>
    </>
  );
}
