import appsignal from '../appsignal';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import PassDownloadTemplateContext from '../store/client/PassDownloadTemplateContext';
import { useToast } from '@chakra-ui/react';
import CustomToast from '../common/CustomToast';
import { getErrorResponsePayload } from '../utils/ajax';
import { HttpContext } from '../context/HttpContext';
import { MeContext } from '../context/MeContext';

function UsePassDownloadTemplate({ children }) {
  const { authAxios } = useContext(HttpContext);
  const meCtx = useContext(MeContext);
  const { passDownloadTemplateUuid } = meCtx.state;
  const navigate = useNavigate();
  const passDownloadCtx = useContext(PassDownloadTemplateContext);
  const toast = useToast();

  useEffect(() => {
    const getData = async () => {
      try {
        const uuid = passDownloadTemplateUuid;
        const response = await authAxios.get(
          `api/v1/pass_download_templates/${uuid}`
        );
        const data = response.data?.data?.attributes;
        passDownloadCtx.updateAllData(data);
      } catch (onError) {
        appsignal.sendError(onError);
        console.log(onError);
        const { message, code } = getErrorResponsePayload(onError);
        code !== 401 &&
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={message ? message : `Something went wrong`}
                description={!message && 'Please try again later'}
                onClose={props.onClose}
              />
            ),
          });
        navigate('/pass-download', { replace: true });
      }
    };

    getData();
  }, [authAxios]);

  return <>{children}</>;
}

export default UsePassDownloadTemplate;
