import React, { useState, useEffect, useContext } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Flex,
  HStack,
  Portal,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';

import { START_DATE, useDatepicker, useMonth } from '@datepicker-react/hooks';
import { Month } from './Month';

import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';
import { CustomCalendarIcon } from '../../../theme/icons/CustomCalendarIcon';

import DatepickerContext from './DatepickerContext';
import EventsContext from '../../../store/client/EventsContext';
import RedirectionContext from '../../../context/RedirectionContext';
import PassContext from '../../../store/client/PassContext';

export function Datepicker({
  setDate,
  placementTop,
  darkMode,
  isEdit,
  isDisabled,
  startDate,
  endDate,
  expiryDate,
  gateDate,
  minBookingDate,
  maxBookingDate,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [state, setState] = useState({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE,
  });

  const [isSmallerThan768px] = useMediaQuery('(max-width: 768px)');
  const [isLargerThan768px] = useMediaQuery('(min-width: 768px)');
  const redirectCtx = useContext(RedirectionContext);
  const eventCtx = useContext(EventsContext);
  const passCtx = useContext(PassContext);

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
    goToDate,
    onResetDates,
  } = useDatepicker({
    startDate: state.startDate,
    minBookingDate,
    maxBookingDate,
    endDate: state.endDate,
    focusedInput: state.focusedInput,
    firstDayOfWeek: 1,
    numberOfMonths: 1,
    exactMinBookingDays: true,
    onDatesChange: (data) => {
      if (!data.focusedInput) {
        setState({ ...data, focusedInput: START_DATE });
      } else {
        setState(data);
      }
    },
    isDateBlocked: (date) => {
      // Block dates beyond maxBookingDate and before minBookingDate
      if (
        (maxBookingDate &&
          date > new Date(maxBookingDate.setHours(0, 0, 0, 0))) ||
        (minBookingDate && date < new Date(minBookingDate.setHours(0, 0, 0, 0)))
      ) {
        return true;
      }
      return false;
    },
  });
  const month = activeMonths[0];
  const { monthLabel } = useMonth({
    year: month.year,
    month: month.month,
    firstDayOfWeek,
  });

  const formatDate = (date) => {
    if (!date) return '';
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0');
    let yyyy = date.getFullYear();
    return `${dd}/${mm}/${yyyy}`;
  };

  useEffect(() => {
    setDate(state.startDate);
  }, [state.startDate, setDate]);

  useEffect(() => {
    if (
      redirectCtx.addReaderFromEventState ||
      redirectCtx.addTemplateFromEventState
    ) {
      if (startDate) {
        setState({
          startDate: redirectCtx.tempEventState.startDate,
          focusedInput: START_DATE,
        });
      } else if (endDate) {
        setState({
          startDate: redirectCtx.tempEventState.endDate,
          focusedInput: START_DATE,
        });
      } else if (gateDate) {
        setState({
          startDate: redirectCtx.tempEventState.gateDate,
          focusedInput: START_DATE,
        });
      }
    }
  }, [
    endDate,
    gateDate,
    redirectCtx.addReaderFromEventState,
    redirectCtx.addTemplateFromEventState,
    redirectCtx.tempEventState.endDate,
    redirectCtx.tempEventState.gateDate,
    redirectCtx.tempEventState.startDate,
    startDate,
  ]);

  useEffect(() => {
    if (isEdit) {
      if (
        redirectCtx.addTemplateFromEditEventState ||
        redirectCtx.addReaderFromEventState ||
        redirectCtx.addTemplateFromEventState ||
        redirectCtx.addReaderFromEditEventState
      ) {
        if (startDate) {
          setState({
            startDate: redirectCtx.tempEventState.startDate,
            focusedInput: START_DATE,
          });
        } else if (endDate) {
          setState({
            startDate: redirectCtx.tempEventState.endDate,
            focusedInput: START_DATE,
          });
        } else if (gateDate) {
          setState({
            startDate: redirectCtx.tempEventState.gateDate,
            focusedInput: START_DATE,
          });
        }
      } else {
        if (expiryDate) {
          if (passCtx.passState.expiryDate) {
            setState({
              startDate: passCtx.passState.expiryDate,
              focusedInput: START_DATE,
            });
          }
        } else {
          if (startDate) {
            setState({
              startDate: eventCtx.eventState.startDate,
              focusedInput: START_DATE,
            });
          } else if (endDate) {
            setState({
              startDate: eventCtx.eventState.endDate,
              focusedInput: START_DATE,
            });
          } else if (gateDate) {
            setState({
              startDate: eventCtx.eventState.gateDate,
              focusedInput: START_DATE,
            });
          }
        }
      }
    }
  }, [
    endDate,
    eventCtx.eventState.endDate,
    eventCtx.eventState.gateDate,
    eventCtx.eventState.startDate,
    gateDate,
    isEdit,
    redirectCtx.addReaderFromEditEventState,
    redirectCtx.addReaderFromEventState,
    redirectCtx.addTemplateFromEditEventState,
    redirectCtx.addTemplateFromEventState,
    redirectCtx.tempEventState.endDate,
    redirectCtx.tempEventState.gateDate,
    redirectCtx.tempEventState.startDate,
    setDate,
    startDate,
    expiryDate,
    passCtx.passState.expiryDate,
  ]);

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
      }}
    >
      <Popover
        placement={placementTop ? 'top' : 'bottom'}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        closeOnBlur
      >
        <PopoverTrigger>
          <InputGroup
            onClick={isDisabled ? undefined : onOpen}
            style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
          >
            <InputLeftElement pointerEvents="none" pl="5px">
              <CustomCalendarIcon boxSize="20px" />
            </InputLeftElement>
            <Input
              isReadOnly
              pl="55px"
              pt="5px"
              variant="filledForDarkBg"
              value={formatDate(state.startDate)}
              placeholder="Select date"
              isDisabled={isDisabled}
              cursor="pointer"
            />
          </InputGroup>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            borderRadius="15px"
            boxShadow="0px 8px 16px rgba(0, 0, 0, 0.1)"
            overflow="hidden"
            bg={darkMode ? '#3B4851' : '#fff'}
            width="300px"
          >
            <PopoverArrow />
            <PopoverBody padding="16px 24px">
              {/* Close button */}
              <Flex justifyContent="flex-end">
                <button
                  type="button"
                  style={{
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '14px',
                    color: darkMode ? '#fff' : '#29323A',
                  }}
                  onClick={onClose}
                >
                  X
                </button>
              </Flex>
              <Flex justifyContent="center" alignItems="center" mb={3}>
                <Text color={darkMode ? '#fff' : '#29323A'}>{monthLabel}</Text>
              </Flex>
              <Month
                darkMode={darkMode}
                key={`${month.year}-${month.month}`}
                year={month.year}
                month={month.month}
                firstDayOfWeek={firstDayOfWeek}
                onClose={onClose}
              />
              <Flex justifyContent="space-between" mt={3}>
                <HStack spacing={3}>
                  <button
                    type="button"
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '100%',
                      backgroundColor: '#DDDDDD',
                      color: '#29323A',
                      cursor: 'pointer',
                    }}
                    onClick={goToPreviousMonths}
                  >
                    <CustomChevronLeftIcon />
                  </button>
                  <button
                    type="button"
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '100%',
                      backgroundColor: '#DDDDDD',
                      color: '#29323A',
                      cursor: 'pointer',
                    }}
                    onClick={goToNextMonths}
                  >
                    <CustomChevronLeftIcon transform="rotate(180deg)" />
                  </button>
                </HStack>

                <button
                  type="button"
                  style={{
                    borderRadius: '20px',
                    backgroundColor: '#DDDDDD',
                    color: '#29323A',
                    padding: '8px 18px',
                    fontSize: 14,
                    cursor: 'pointer',
                  }}
                  onClick={onResetDates}
                >
                  Reset dates
                </button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </DatepickerContext.Provider>
  );
}
