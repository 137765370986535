import appsignal from '../../appsignal';
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Hide,
  HStack,
  Input,
  Link,
  Show,
  Spacer,
  Stack,
  Text,
  useToast,
  useColorModeValue,
  useClipboard,
  Switch,
  Tooltip,
  useOutsideClick,
  Grid,
  useMediaQuery,
} from '@chakra-ui/react';
import Breadcrumbs from '../../common/Breadcrumbs';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as Yup from 'yup';
import { CustomPlusIcon } from '../../theme/icons/CustomPlusIcon';
import { useState, useEffect, useContext, useRef } from 'react';
import PreviewPassTemplates from '../../components/client/pass-templates/PreviewPassTemplates';
import PassContext from '../../store/client/PassContext';
import PassTemplateContext from '../../store/client/PassTemplateContext';
import { formatOptionLabelFilterPassTemplate } from '../../components/common/CustomReactSelect';
import { getErrorResponsePayload } from '../../utils/ajax';
import CustomToast from '../../common/CustomToast';
import { CustomReactSelect } from '../../components/common/CustomReactSelect';
import { useParams } from 'react-router';
import RedirectionContext from '../../context/RedirectionContext';
import { CustomEditIcon } from '../../theme/icons/CustomEditIcon';
import { CustomExclamationMarkTriangleIcon } from '../../theme/icons/CustomExclamationMarkTriangleIcon';
import { HttpContext } from '../../context/HttpContext';
import PassLinkSender from '../../components/client/passes/PassLinkSender';
import { MeContext } from '../../context/MeContext';
import { MESSAGING_ENABLED_USERS } from '../../utils/messagingEnabledUsers';
import { CustomCreatableSelect } from '../../components/common/CustomCreatableSelect';
import CustomDatePicker from '../../components/common/CustomDatePicker/CustomDatePicker';
import moment from 'moment';
import CustomTimePicker from '../../components/common/CustomTimePicker/CustomTimePicker';
import { CustomExclamationMarkInverseIcon } from '../../theme/icons/CustomExclamationMarkInverseIcon';

const PassesCreatePageForm = ({ isEdit, createView }) => {
  const { authAxios } = useContext(HttpContext);
  const { uuid } = useParams();
  const [passTemplatesList, setPassTemplatesList] = useState([]);
  const [selectedPassTemplate, setSelectedPassTemplate] = useState(null);
  const passCtx = useContext(PassContext);
  const passTemplateCtx = useContext(PassTemplateContext);
  const redirectCtx = useContext(RedirectionContext);
  const [query, setQuery] = useState('');
  const [warning, setWarning] = useState(false);
  const [passInfo, setPassInfo] = useState();
  const [passTemplateError, setPassTemplateError] = useState('');
  const [noExpiryDateError, setNoExpiryDateError] = useState('');
  const [noExpiryTimeError, setNoExpiryTimeError] = useState('');
  const [invalidExpiryTimeError, setInvalidExpiryTimeError] = useState('');
  const [passUrl, setPassUrl] = useState();
  const toast = useToast();
  const navigate = useNavigate();
  // const [formIsValid, setFormIsValid] = useState(null);
  const modeFormBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeNoteText = useColorModeValue('secondaryDark06', '#DDDDDD');
  const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeHelperTextBold = useColorModeValue('primaryDark', 'white');
  // const modeStepperColor = useColorModeValue('primaryDark', 'white');
  const meContext = useContext(MeContext);
  const { organisationStatus, trialExpiry } = meContext.state;
  const accountEmail = meContext.state.account.email;
  const [createdPassUuid, setCreatedPassUuid] = useState(null);
  const [isContextLoaded, setIsContextLoaded] = useState(false);
  const [groupTagsList, setGroupTagsList] = useState([]);
  const { filterEntity } = meContext.state;

  const [expiryDate, setExpiryDate] = useState('');
  const [expiryTime, setExpiryTime] = useState('');
  const today = new Date();
  const minDate = new Date(today.setDate(today.getDate()));
  const maxDate = trialExpiry
    ? new Date(
        new Date(trialExpiry).setDate(new Date(trialExpiry).getDate() - 1)
      )
    : null;
  const [expiryTooltipOpen, setExpiryTooltipOpen] = useState(false);
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');

  const [isLargeForParallelDisplay] = useMediaQuery('(min-width: 1530px)');

  // // const loading = isEdit && passCtx?.passState?.creator === '';

  // // warning text render for section, gate,seat, and row fields
  const renderWarningText = () => {
    return 'field value longer than 20 characters might not be displayed in full on Apple Wallet passes';
  };

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Passes',
      linkUrl: '/passes',
    },
    {
      linkName: isEdit ? `Edit pass` : 'Create a pass',
      linkUrl: null,
    },
  ];

  // const validationSchema = Yup.object().shape({
  //   // header: Yup.string().required('Please enter a header value'),
  // });

  const formOptions = {
    // resolver: yupResolver(validationSchema),
    // mode: 'onChange',
  };

  const tooltipRef = useRef();

  useOutsideClick({
    ref: tooltipRef,
    handler: () => {
      setExpiryTooltipOpen(false);
    },
  });

  const showTooltipHandler = (e, tooltip) => {
    e.preventDefault();
    expiryTooltipOpen
      ? setExpiryTooltipOpen(false)
      : setExpiryTooltipOpen(true);
  };

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    watch,
    getValues,
  } = useForm(formOptions);

  // // overall pass form validation booleans
  // // const requiredIsValid =
  // //   watch('header') !== '' &&
  // //   watch('primary') !== '' &&
  // //   watch('secOne') !== '' &&
  // //   watch('secTwo') !== '' &&
  // //   watch('secThree') !== '' &&
  // //   watch('auxOne') !== '' &&
  // //   watch('auxTwo') !== '' &&
  // //   watch('auxThree') !== '';

  // const selectedIsValid = selectedPassTemplate !== null;

  // useEffect(() => {
  //   // if (requiredIsValid && selectedIsValid) {
  //   if (selectedIsValid) {
  //     setFormIsValid(true);
  //   } else {
  //     setFormIsValid(false);
  //   }
  //   // }, [requiredIsValid, selectedIsValid]);
  // }, [selectedIsValid]);

  // // fetch all PassTemplates for select PassTemplates dropdown
  useEffect(() => {
    const getData = async () => {
      try {
        let response;
        if (filterEntity) {
          response = await authAxios.get(
            `entities/${filterEntity.uuid}/passes/templates?fields=name`
          );
        } else {
          response = await authAxios.get(`passes/templates?fields=name`);
        }
        const data = response.data?.data;
        let templates = [];
        if (data.length === 0) {
          setPassTemplatesList([]);
          return;
        }
        data.forEach((item) => {
          templates.push({
            label: item.name,
            value: item.uuid,
          });
        });
        setPassTemplatesList(templates);
      } catch (error) {
        appsignal.sendError(error);
        console.log(error);
        const { message, code } = getErrorResponsePayload(error);
        code !== 401 &&
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={message ? message : `Something went wrong`}
                description={!message && 'Please try again later'}
                onClose={props.onClose}
              />
            ),
          });
      }
    };
    getData();
  }, [authAxios, setPassTemplatesList, toast, filterEntity]);

  // fetch all group tags for select group tags dropdown
  useEffect(() => {
    const getGroupTags = async () => {
      try {
        let response;
        if (filterEntity) {
          response = await authAxios.get(
            `api/v1/entities/${filterEntity.uuid}/passes?fields=groupTag`
          );
        } else {
          response = await authAxios.get(`api/v1/passes?fields=groupTag`);
        }
        const data = response.data?.data;
        const groupTags = data.map((item) => {
          if (item.attributes.groupTag) {
            return {
              label: item.attributes.groupTag,
            };
          }
        });
        const filteredGroupTags = groupTags.filter(
          (item) => item !== undefined
        );
        const uniqueGroupTags = Array.from(
          new Set(filteredGroupTags.map((a) => a.label))
        ).map((label) => {
          return filteredGroupTags.find((a) => a.label === label);
        });
        setGroupTagsList(uniqueGroupTags);
      } catch (error) {
        appsignal.sendError(error);
        console.log(error);
      }
    };
    getGroupTags();
  }, [authAxios, setGroupTagsList, filterEntity]);

  // Update Pass Template based on selected template in dropdown
  useEffect(() => {
    // watch selected PassTemplate for error removal
    const fetchSelectedPassTemplate = async () => {
      if (selectedPassTemplate !== null) {
        const templateUuid = selectedPassTemplate.value;
        try {
          const response = await authAxios.get(
            `passes/templates/${templateUuid}`
          );
          const templateData = response.data.data;
          passTemplateCtx.updateAllData(templateData);
        } catch (error) {
          appsignal.sendError(error);
          console.log(error);
        }
      }
    };
    fetchSelectedPassTemplate();
  }, [selectedPassTemplate]);

  useEffect(() => {
    setSelectedPassTemplate(null);
  }, [filterEntity]);

  // // populate pass template preview
  const externalId = watch('externalId');
  const qrValue = watch('qrValue');
  const qrDisplayText = watch('qrDisplayText');
  const headerOne = watch('headerOne');
  const headerTwo = watch('headerTwo');
  const headerThree = watch('headerThree');
  const primaryOne = watch('primaryOne');
  const primaryTwo = watch('primaryTwo');
  const secOne = watch('secOne');
  const secTwo = watch('secTwo');
  const secThree = watch('secThree');
  const auxOne = watch('auxOne');
  const auxTwo = watch('auxTwo');
  const auxThree = watch('auxThree');
  const description = watch('description');
  const backOne = watch('backOne');
  const backTwo = watch('backTwo');
  const backThree = watch('backThree');
  const backFour = watch('backFour');
  const backFive = watch('backFive');
  const nfc = watch('nfc');
  const groupTag = watch('groupTag');

  useEffect(() => {
    setPassInfo({
      externalId,
      qrValue,
      qrDisplayText,
      nfc,
      headerOne,
      primaryOne,
      secOne,
      secTwo,
      secThree,
      auxOne,
      auxTwo,
      auxThree,
      description,
      backOne,
      backTwo,
      backThree,
      backFour,
      backFive,
      groupTag,
      expiryDate,
      expiryTime,
    });
  }, [
    externalId,
    qrValue,
    qrDisplayText,
    nfc,
    headerOne,
    primaryOne,
    secOne,
    secTwo,
    secThree,
    auxOne,
    auxTwo,
    auxThree,
    description,
    backOne,
    backTwo,
    backThree,
    backFour,
    backFive,
    groupTag,
    expiryDate,
    expiryTime,
  ]);

  // watch if 20+ character are typed to handle on ios preview
  useEffect(() => {
    if (
      headerOne?.length > 20 ||
      primaryOne?.length > 20 ||
      secOne?.length > 20 ||
      secTwo?.length > 20 ||
      secThree?.length > 20 ||
      auxOne?.length > 20 ||
      auxTwo?.length > 20 ||
      auxThree?.length > 20 ||
      description?.length > 20 ||
      backOne?.length > 20 ||
      backTwo?.length > 20 ||
      backThree?.length > 20 ||
      backFour?.length > 20 ||
      backFive?.length > 20
    ) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  }, [
    headerOne?.length,
    primaryOne?.length,
    secOne?.length,
    secTwo?.length,
    secThree?.length,
    auxOne?.length,
    auxTwo?.length,
    auxThree?.length,
    description?.length,
    backOne?.length,
    backTwo?.length,
    backThree?.length,
    backFour?.length,
    backFive?.length,
    setError,
  ]);

  const editPassTemplateFromPassClickHandler = () => {
    // from edit
    if (isEdit) {
      let values = getValues();
      values['passTemplate'] = selectedPassTemplate;
      values['uuid'] = passCtx.passState.uuid;
      values['name'] = passCtx.passState.template.name;
      redirectCtx.updateTempPassState(values);
      redirectCtx.updatePassTemplateFromEditPassState(true);
      redirectCtx.updateAddReaderFromEditPassState(false);
      redirectCtx.updatePassTemplateFromPassState(false);
      redirectCtx.updatePassParamUrl(uuid);
    } else {
      // from create
      let values = getValues();
      values['passTemplate'] = selectedPassTemplate;
      redirectCtx.updateTempPassState(values);
      redirectCtx.updatePassTemplateFromPassState(true);
      redirectCtx.updatePassTemplateFromEditPassState(false);
      redirectCtx.updateAddReaderFromPassState(false);
      // empty out readers array if 'add new PassTemplate' is clicked two times in the row
      redirectCtx.readersArray.splice(0, redirectCtx.readersArray.length);
    }
  };

  // function camelize(str) {
  //   return str.replace(/(?:^\p{L}+|\s+\p{Ll}+|\s+)/gu, function (match, index) {
  //     if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
  //     return index === 0 ? match.toLowerCase() : match.toUpperCase();
  //   });
  // }

  const getExpiryDateTime = () => {
    const formattedExpiryDate = passCtx.expiryDate
      ? moment(passCtx.expiryDate).format('YYYY-MM-DD')
      : '';
    const formattedExpiryTime = passCtx.expiryTime
      ? moment(passCtx.expiryTime, ['h:mm A']).format('HH:mm:ss')
      : '';
    let expiryDateTime = moment(
      formattedExpiryDate + formattedExpiryTime,
      'YYYY-MM-DD HH:mm:ss'
    ).format('YYYY-MM-DD HH:mm:ss');
    return expiryDateTime;
  };

  const validateFields = () => {
    let passTemplateErrorMessage = '';
    let noExpiryDateErrorMessage = '';
    let noExpiryTimeErrorMessage = '';
    let expiryTimeInvalidMessage = '';
    if (!isEdit && !selectedPassTemplate) {
      passTemplateErrorMessage = 'Please select pass template';
    }
    if (!!expiryTime && !expiryDate) {
      noExpiryDateErrorMessage = 'Please select expiry date';
    }
    if (!!expiryDate && !expiryTime) {
      noExpiryTimeErrorMessage = 'Please select expiry time';
    }

    if (expiryDate && expiryTime) {
      let expiryDateTime = getExpiryDateTime();
      if (moment(expiryDateTime).isBefore(moment())) {
        expiryTimeInvalidMessage = 'Expiry date and time must be in the future';
      }
    }
    setPassTemplateError(passTemplateErrorMessage);
    setNoExpiryDateError(noExpiryDateErrorMessage);
    setNoExpiryTimeError(noExpiryTimeErrorMessage);
    setInvalidExpiryTimeError(expiryTimeInvalidMessage);

    if (passTemplateErrorMessage) {
      window.scrollTo(0, 0);
    }
    const validationError =
      !!passTemplateErrorMessage ||
      !!noExpiryDateErrorMessage ||
      !!noExpiryTimeErrorMessage ||
      !!expiryTimeInvalidMessage;

    return validationError;
  };

  const checkFieldPlaceholders = () => {
    const fieldIds = [
      'secOne',
      'secTwo',
      'secThree',
      'auxOne',
      'auxTwo',
      'auxThree',
      'backOne',
      'backTwo',
      'backThree',
      'backFour',
      'backFive',
    ];

    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    fieldIds.forEach((fieldId) => {
      const fieldInput = document.getElementById(fieldId);
      const currentFieldValue = passCtx[fieldId];
      const defaultValue = passTemplateCtx[`${fieldId}Value`];

      if (
        fieldInput &&
        (currentFieldValue === '' || currentFieldValue === defaultValue)
      ) {
        passCtx[`update${capitalize(fieldId)}`](defaultValue);
      }
    });
  };

  // prepare data
  const prepareDataForSubmit = (data, isEdit) => {
    // if select PassTemplate is null
    if (!isEdit && selectedPassTemplate === null) {
      // setPassTemplateError(true);
      return;
    } else {
      // setPassTemplateError(false);
    }

    let submittedData;

    submittedData = {
      pass: {},
    };
    if (passTemplateCtx.headerOneId) {
      submittedData.pass[passTemplateCtx.headerOneId] = passCtx.headerOne;
    }
    if (passTemplateCtx.primaryOneId) {
      submittedData.pass[passTemplateCtx.primaryOneId] = passCtx.primaryOne;
    }
    if (passTemplateCtx.secOneId) {
      submittedData.pass[passTemplateCtx.secOneId] = passCtx.secOne;
    }
    if (passTemplateCtx.secTwoId) {
      submittedData.pass[passTemplateCtx.secTwoId] = passCtx.secTwo;
    }
    if (passTemplateCtx.secThreeId) {
      submittedData.pass[passTemplateCtx.secThreeId] = passCtx.secThree;
    }
    if (passTemplateCtx.auxOneId) {
      submittedData.pass[passTemplateCtx.auxOneId] = passCtx.auxOne;
    }
    if (passTemplateCtx.auxTwoId) {
      submittedData.pass[passTemplateCtx.auxTwoId] = passCtx.auxTwo;
    }
    if (passTemplateCtx.auxThreeId) {
      submittedData.pass[passTemplateCtx.auxThreeId] = passCtx.auxThree;
    }
    if (passTemplateCtx.backOneId) {
      submittedData.pass[passTemplateCtx.backOneId] = passCtx.backOne;
    }
    if (passTemplateCtx.backTwoId) {
      submittedData.pass[passTemplateCtx.backTwoId] = passCtx.backTwo;
    }
    if (passTemplateCtx.backThreeId) {
      submittedData.pass[passTemplateCtx.backThreeId] = passCtx.backThree;
    }
    if (passTemplateCtx.backFourId) {
      submittedData.pass[passTemplateCtx.backFourId] = passCtx.backFour;
    }
    if (passTemplateCtx.backFiveId) {
      submittedData.pass[passTemplateCtx.backFiveId] = passCtx.backFive;
    }
    if (passCtx.qrValue) {
      submittedData.pass.qr = {
        value: passCtx.qrValue,
        displayText: passCtx.qrDisplayText,
      };
    } else {
      submittedData.pass.qr = {
        value: '',
        displayText: false,
      };
    }
    submittedData.pass['nfc'] = passCtx.nfc ? 'true' : 'false';

    let expiryDateTime = getExpiryDateTime();
    let isoExpiryDateTime = moment(expiryDateTime).toISOString();
    submittedData.pass.expiresAt = isoExpiryDateTime || null;

    submittedData = {
      ...submittedData,
      groupTag: passCtx.groupTag ? passCtx.groupTag.label : '',
    };

    return submittedData;
  };

  useEffect(() => {
    const queryPassTemplate =
      passTemplateCtx && !!passTemplateCtx.uuid
        ? '?pass_template=' + passTemplateCtx.uuid
        : '';
    const query = `${queryPassTemplate}`;
    setQuery(query);
  }, [passTemplateCtx]);

  useEffect(() => {
    if (isEdit) {
      if (!isContextLoaded && passCtx.qrValue !== '') {
        setIsContextLoaded(true);
      }
    }
  }, [isEdit, passCtx.qrValue, isContextLoaded]);

  useEffect(() => {
    if (!isEdit) {
      const updates = [
        [passCtx.updateHeaderOne, passTemplateCtx.headerOneValue],
        [passCtx.updatePrimaryOne, passTemplateCtx.primaryOneValue],
        [passCtx.updateSecOne, passTemplateCtx.secOneValue],
        [passCtx.updateSecTwo, passTemplateCtx.secTwoValue],
        [passCtx.updateSecThree, passTemplateCtx.secThreeValue],
        [passCtx.updateAuxOne, passTemplateCtx.auxOneValue],
        [passCtx.updateAuxTwo, passTemplateCtx.auxTwoValue],
        [passCtx.updateAuxThree, passTemplateCtx.auxThreeValue],
        [passCtx.updateBackOne, passTemplateCtx.backOneValue],
        [passCtx.updateBackTwo, passTemplateCtx.backTwoValue],
        [passCtx.updateBackThree, passTemplateCtx.backThreeValue],
        [passCtx.updateBackFour, passTemplateCtx.backFourValue],
        [passCtx.updateBackFive, passTemplateCtx.backFiveValue],
      ];

      updates.forEach(([updateFn, value]) => updateFn(value));
    }
  }, [
    passTemplateCtx.headerOneValue,
    passTemplateCtx.primaryOneValue,
    passTemplateCtx.secOneValue,
    passTemplateCtx.secTwoValue,
    passTemplateCtx.secThreeValue,
    passTemplateCtx.auxOneValue,
    passTemplateCtx.auxTwoValue,
    passTemplateCtx.auxThreeValue,
    passTemplateCtx.backOneValue,
    passTemplateCtx.backTwoValue,
    passTemplateCtx.backThreeValue,
    passTemplateCtx.backFourValue,
    passTemplateCtx.backFiveValue,
  ]);

  useEffect(() => {
    passCtx.updateExpiryDate(expiryDate);
  }, [expiryDate]);

  useEffect(() => {
    passCtx.updateExpiryTime(expiryTime);
  }, [expiryTime]);

  useEffect(() => {
    if (selectedPassTemplate) {
      setPassTemplateError('');
    }
    if (!!expiryDate && !!expiryTime) {
      setNoExpiryDateError('');
      setNoExpiryTimeError('');
      let expiryDateTime = getExpiryDateTime();
      if (!moment(expiryDateTime).isBefore(moment())) {
        setInvalidExpiryTimeError('');
      }
    }
    if (!expiryDate && !expiryTime) {
      setNoExpiryDateError('');
      setNoExpiryTimeError('');
    }
  }, [
    selectedPassTemplate,
    expiryDate,
    expiryTime,
    passCtx.expiryDate,
    passCtx.expiryTime,
  ]);

  const onSubmit = async (data) => {
    checkFieldPlaceholders();
    const validationError = validateFields();
    // SUBMIT FROM EDIT
    async function handleEdit() {
      if (!validationError) {
        let submittedData = prepareDataForSubmit(data, true);
        try {
          const response = await authAxios.patch(
            `passes/${uuid}`,
            submittedData
          );
          if (
            response.data.data.status === 'deactivated' &&
            passCtx.passState.status !== 'deactivated'
          ) {
            toast({
              render: ({ onClose }) => (
                <CustomToast
                  status="success"
                  title="New pass has been issued."
                  description="The pass with old contact details has been deactivated."
                  onClose={onClose}
                />
              ),
            });
          } else {
            toast({
              render: ({ onClose }) => (
                <CustomToast
                  status="success"
                  title="Pass has been edited"
                  description=""
                  onClose={onClose}
                />
              ),
            });
          }
          navigate(`/passes/${uuid}`, { replace: true });
        } catch (error) {
          appsignal.sendError(error);
          console.log(error);
          const { errors } = getErrorResponsePayload(error);
          if (errors && errors.ticket.children.firstName.errors) {
            const message = errors.ticket.children.firstName.errors[0];
            setError('firstName', { type: 'server', message });
          }
          if (errors && errors.ticket.children.lastName.errors) {
            const message = errors.ticket.children.lastName.errors[0];
            setError('lastName', { type: 'server', message });
          }
          if (errors && errors.ticket.children.email.errors) {
            const message = errors.ticket.children.email.errors[0];
            setError('email', { type: 'server', message });
          }
          if (errors && errors.ticket.children.section.errors) {
            const message = errors.ticket.children.section.errors[0];
            setError('section', { type: 'server', message });
          }
          if (errors && errors.ticket.children.seatRow.errors) {
            const message = errors.ticket.children.seatRow.errors[0];
            setError('row', { type: 'server', message });
          }
          if (errors && errors.ticket.children.seat.errors) {
            const message = errors.ticket.children.seat.errors[0];
            setError('seat', { type: 'server', message });
          }
          if (errors && errors.ticket.children.gateNumber.errors) {
            const message = errors.ticket.children.gateNumber.errors[0];
            setError('gateNumber', { type: 'server', message });
          }
          if (errors && errors.passTemplate && errors.passTemplate.errors) {
            // setPassTemplateError(true);
          }
        }
      }
    }

    // SUBMIT FROM CREATE
    try {
      if (isEdit) {
        await handleEdit();
        return;
      }
      if (!validationError) {
        let submittedData = prepareDataForSubmit(data);
        const response_data = await authAxios.post(
          `create-passes${query}${
            passCtx.externalId ? `&ext_id=${passCtx.externalId}` : ''
          }`,
          submittedData
        );
        const uuid = response_data.data.data.id;
        setCreatedPassUuid(uuid);
        const passUrl = response_data.data.data.attributes.downloadUrl;
        setPassUrl(passUrl);
        navigate(`/passes/${uuid}`, { replace: true });
        toast({
          render: ({ onClose }) => (
            <CustomToast
              status="success"
              title="Pass has been created"
              description=""
              onClose={onClose}
            />
          ),
        });
      }
    } catch (error) {
      appsignal.sendError(error);
      const { errors } = error.response.data;
      if (errors) {
        errors.forEach((errorMessage) => {
          toast({
            render: ({ onClose }) => (
              <CustomToast
                status="error"
                title={errorMessage}
                description=""
                onClose={onClose}
              />
            ),
          });
        });
      }
    }
  };

  useEffect(() => {
    if (passUrl) {
      window.scrollTo(0, 0);
    }
  }, [passUrl]);

  useEffect(() => {
    if (isEdit && passCtx.passState.url) {
      setPassUrl(passCtx.passState.url);
    }
  }, [isEdit, passCtx.passState.url]);

  useEffect(() => {
    if (MESSAGING_ENABLED_USERS.includes(accountEmail) && !passUrl) {
      passCtx.updateQrValue('https://passentry.com');
      passCtx.updateQrDisplayText(false);
    }
  }, [accountEmail]);

  const { onCopy, hasCopied } = useClipboard(passUrl);

  return (
    <Container display="flex" flexDir="column" flex="1" pb={6}>
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems="center"
          flexWrap="wrap"
          mt="15px"
          py="9px"
          minH="54px"
        >
          <Heading alignSelf="flex-start" mr={2} wordBreak="break-word">
            {passUrl ? `Current pass` : 'Create a pass'}
            {isEdit && !redirectCtx.passTemplateFromEditPassState ? (
              <Text
                as="span"
                display="inline-block"
                textStyle="bodyFamilyMedium"
                fontSize="16px"
                color={modeNoteText}
                lineHeight="130%"
              >
                / uuid: {passCtx.passState.uuid}
              </Text>
            ) : isEdit && redirectCtx.passTemplateFromEditPassState ? (
              <Text
                as="span"
                display="inline-block"
                textStyle="bodyFamilyMedium"
                fontSize="16px"
                color={modeNoteText}
                lineHeight="130%"
              >
                / uuid: {redirectCtx.tempPassState?.uuid}
              </Text>
            ) : null}
          </Heading>
        </Flex>

        <Divider borderColor="primaryBackground" mb={6} />

        <Flex
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems="center"
          flexWrap="wrap"
          minH="54px"
        >
          {!isEdit && (
            <Text
              color={modeNoteText}
              w={{ base: '100%', md: '50%' }}
              pb={{ base: '3', md: '0px' }}
              fontSize="14px"
            >
              You can also create passes via the{' '}
              <Link as={RouterLink} to="/developer-tools" color="brand">
                PassEntry API.
              </Link>
            </Text>
          )}
          <Spacer />

          <Grid
            mt={6}
            w="full"
            templateColumns={{ base: '100%', xl: '40% 60%' }}
            gap={6}
          >
            <Flex direction="column">
              {/* Pass holder info section */}
              <Box
                className="autofillForDarkBg"
                w="full"
                borderRadius="15px"
                mb={{ base: 5, xl: 0 }}
              >
                {/* Assign PassTemplate section */}
                {!passUrl ? (
                  <Box
                    className="autofillForDarkBg"
                    borderRadius="15px"
                    mb={{ base: 5, xl: 0 }}
                    w="full"
                  >
                    <Box
                      className="primaryBoxShadow"
                      bg={modeFormBoxBg}
                      borderRadius="15px"
                      w="full"
                    >
                      <Stack
                        p="14px 14px 14px 24px"
                        borderRadius="15px 15px 0 0"
                        bg={modeTextBg}
                        direction={{ base: 'column', '2xl': 'row' }}
                      >
                        <Text fontSize="18px" textStyle="headingFamilyMedium">
                          Assign a pass template{' '}
                        </Text>
                      </Stack>
                      <Divider borderColor={modeBorderColor} />

                      <FormControl
                        isRequired
                        bg={modeFormControlBg}
                        borderBottomLeftRadius="15px"
                        borderBottomRightRadius="15px"
                        w="full"
                      >
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" pb="34px" flexShrink={0} w="240px">
                              <FormLabel m={0}>Select pass template</FormLabel>
                            </Box>
                          </Hide>
                          <Box
                            p="14px"
                            bg={modeLabelBg}
                            borderBottomRightRadius="15px"
                            borderBottomLeftRadius={{
                              base: '15px',
                              '2xl': '0px',
                            }}
                            w="full"
                          >
                            <Show below="2xl">
                              <FormLabel>Select pass template</FormLabel>
                            </Show>
                            {/* Select PassTemplate */}
                            <CustomReactSelect
                              options={passTemplatesList}
                              placeholder={
                                isLargeForParallelDisplay
                                  ? 'Select pass template'
                                  : 'Select pass template from the list'
                              }
                              noOptionsMessage={() => 'No pass templates found'}
                              menuPlacement="auto"
                              formatOptionLabel={
                                formatOptionLabelFilterPassTemplate
                              }
                              onChange={(passTemplate) => {
                                setSelectedPassTemplate({
                                  label: passTemplate.label,
                                  value: passTemplate.value,
                                });
                              }}
                              value={selectedPassTemplate}
                              id="passTemplate"
                              passTemplate
                            />
                            <Flex
                              justifyContent={
                                passTemplateError ? 'space-between' : 'flex-end'
                              }
                            >
                              <p
                                style={{
                                  fontSize: '12px',
                                  paddingLeft: '22px',
                                  paddingTop: '10px',
                                  color: '#E53E3E',
                                }}
                              >
                                {passTemplateError}
                              </p>
                              <Link
                                as={RouterLink}
                                to={{
                                  pathname: '/pass-templates/create',
                                }}
                                alt="Add new pass template"
                                color="brand"
                                pt="8px"
                                onClick={() =>
                                  editPassTemplateFromPassClickHandler()
                                }
                              >
                                <HStack>
                                  <CustomPlusIcon
                                    color="#FF8477"
                                    boxSize="10px"
                                  />
                                  <Text as="span">New pass template</Text>
                                </HStack>
                              </Link>
                            </Flex>
                          </Box>
                        </Flex>
                      </FormControl>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    {!isEdit && (
                      <>
                        <Box
                          className="autofillForDarkBg"
                          w="full"
                          borderRadius="15px"
                          mb={{ base: 5 }}
                          // overflow="hidden"
                        >
                          <Box
                            className="primaryBoxShadow"
                            bg={modeFormBoxBg}
                            borderRadius="15px"
                          >
                            <Stack
                              p="14px 14px 14px 24px"
                              borderRadius="15px 15px 0 0"
                              bg={modeTextBg}
                              direction={{ base: 'column', '2xl': 'row' }}
                            >
                              <Text
                                fontSize="18px"
                                textStyle="headingFamilyMedium"
                              >
                                Pass issuance
                              </Text>
                            </Stack>
                            <Divider borderColor={modeBorderColor} />

                            <Box bg={modeFormControlBg}>
                              <Flex
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Hide below="2xl">
                                  <Box pl="24px" flexShrink={0} w="240px">
                                    <FormLabel m={0}>Pass link</FormLabel>
                                  </Box>
                                </Hide>
                                <Box
                                  className="inner-box"
                                  p="14px"
                                  w="full"
                                  bg={modeLabelBg}
                                >
                                  <Flex mb={2} alignItems="center">
                                    <Input
                                      variant="filledForDarkBg"
                                      id="passUrl"
                                      type="text"
                                      value={passUrl}
                                      disabled
                                    />
                                    <Button ml={3} onClick={onCopy}>
                                      {hasCopied ? 'Copied!' : 'Copy'}
                                    </Button>
                                  </Flex>
                                </Box>
                              </Flex>
                            </Box>
                            {MESSAGING_ENABLED_USERS.includes(accountEmail) && (
                              <>
                                <Divider borderColor={modeBorderColor} />
                                <Box
                                  bg={modeFormControlBg}
                                  borderBottomLeftRadius="15px"
                                  borderBottomRightRadius="15px"
                                >
                                  <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Hide below="2xl">
                                      <Box pl="24px" flexShrink={0} w="240px">
                                        <FormLabel m={0}>
                                          Send pass via
                                        </FormLabel>
                                      </Box>
                                    </Hide>
                                    <Box
                                      p="14px"
                                      w="full"
                                      bg={modeLabelBg}
                                      borderBottomRightRadius="15px"
                                      borderBottomLeftRadius={{
                                        base: '15px',
                                        '2xl': '0px',
                                      }}
                                    >
                                      <Show below="2xl">
                                        <FormLabel
                                          pl="10px"
                                          variant="adminLabel"
                                        >
                                          Send pass via
                                        </FormLabel>
                                      </Show>
                                      <PassLinkSender
                                        createdPassUuid={createdPassUuid}
                                      />
                                    </Box>
                                  </Flex>
                                </Box>
                              </>
                            )}
                          </Box>
                        </Box>
                      </>
                    )}
                    <Box
                      className="autofillForDarkBg"
                      w="full"
                      borderRadius="15px"
                      mb={{ base: 5, xl: 0 }}
                    >
                      <Box
                        className="primaryBoxShadow"
                        bg={modeFormBoxBg}
                        borderRadius="15px"
                      >
                        <Stack
                          p="14px 14px 14px 24px"
                          borderRadius="15px 15px 0 0"
                          bg={modeTextBg}
                          direction={{ base: 'column', '2xl': 'row' }}
                        >
                          <Text fontSize="18px" textStyle="headingFamilyMedium">
                            Assigned pass template
                          </Text>
                        </Stack>
                        <Divider borderColor={modeBorderColor} />

                        <FormControl
                          isRequired
                          bg={modeFormControlBg}
                          borderBottomLeftRadius="15px"
                          borderBottomRightRadius="15px"
                        >
                          <Flex alignItems="center">
                            <Hide below="2xl">
                              <Box pl="24px" flexShrink={0} w="240px">
                                <FormLabel m={0}>Pass template name</FormLabel>
                              </Box>
                            </Hide>
                            <Box
                              p="14px"
                              w="full"
                              bg={modeLabelBg}
                              borderBottomRightRadius="15px"
                              borderBottomLeftRadius={{
                                base: '15px',
                                '2xl': '0px',
                              }}
                            >
                              <Show below="2xl">
                                <FormLabel>Pass template name</FormLabel>
                              </Show>
                              {/* Select PassTemplate */}
                              <Flex
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Text p={3} wordBreak="break-word" w="80%">
                                  {passTemplateCtx.templateName}
                                </Text>
                                <Button
                                  as={RouterLink}
                                  to={`/pass-templates/edit/${passCtx.passState?.template?.uuid}`}
                                  size="xs"
                                  px="15px"
                                  minW={
                                    !isLargeForParallelDisplay
                                      ? '200px'
                                      : 'auto'
                                  }
                                  onClick={() =>
                                    editPassTemplateFromPassClickHandler()
                                  }
                                >
                                  <HStack zIndex={1} padding="20px">
                                    <CustomEditIcon
                                      boxSize="20px"
                                      color1="#fff"
                                    />
                                    <Text
                                      textStyle="headingFamilyMedium"
                                      fontWeight="500"
                                      lineHeight="28px"
                                      textTransform="none"
                                    >
                                      {isLargeForParallelDisplay
                                        ? 'Edit'
                                        : 'Edit pass template'}
                                    </Text>
                                  </HStack>
                                </Button>
                              </Flex>
                            </Box>
                          </Flex>
                        </FormControl>
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box
                  className="primaryBoxShadow"
                  bg={modeFormBoxBg}
                  borderRadius="15px"
                  mt={5}
                  overflow="hidden"
                >
                  <Text
                    fontSize="18px"
                    textStyle="headingFamilyMedium"
                    p="14px 14px 14px 24px"
                    bg={modeTextBg}
                    borderRadius="15px 15px 0 0"
                  >
                    Pass info
                  </Text>
                  {(!passUrl || (!!passUrl && !!passCtx.externalId)) && (
                    <Box>
                      <Divider borderColor={modeBorderColor} />
                      <FormControl bg={modeFormControlBg}>
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0}>External identifier</FormLabel>
                            </Box>
                          </Hide>
                          <Box
                            className="inner-box"
                            p="14px"
                            w="full"
                            bg={modeLabelBg}
                          >
                            <Show below="2xl">
                              <FormLabel>External identifier</FormLabel>
                            </Show>
                            <Input
                              variant="filledForDarkBg"
                              id="externalIdentifier"
                              type="text"
                              placeholder="type here"
                              value={passCtx.externalId}
                              onChange={(e) =>
                                passCtx.updateExternalId(e.target.value)
                              }
                              autoComplete="off"
                              disabled={isEdit || !!passUrl}
                              onBlur={() => {
                                const value = passCtx.externalId;
                                if (value.trim() === '') {
                                  passCtx.updateExternalId('');
                                }
                              }}
                            />
                            <FormErrorMessage>
                              {errors.firstName?.message || errors.firstName}
                            </FormErrorMessage>
                          </Box>
                        </Flex>
                      </FormControl>
                    </Box>
                  )}
                  {/* Group tag field */}
                  <Box>
                    <Divider borderColor={modeBorderColor} />
                    <FormControl bg={modeFormControlBg}>
                      <Flex alignItems="center">
                        <Hide below="2xl">
                          <Box pl="24px" flexShrink={0} w="240px">
                            <FormLabel m={0}>Group tag</FormLabel>
                          </Box>
                        </Hide>
                        <Box
                          className="inner-box"
                          p="14px"
                          w="full"
                          bg={modeLabelBg}
                        >
                          <Show below="2xl">
                            <FormLabel>Group tag</FormLabel>
                          </Show>
                          <CustomCreatableSelect
                            options={groupTagsList}
                            placeholder={
                              isLargeForParallelDisplay
                                ? 'Select or create tag'
                                : 'Select or create group tag'
                            }
                            noOptionsMessage={() => 'No group tags found'}
                            menuPlacement="auto"
                            id="groupTag"
                            onChange={(groupTag) => {
                              if (groupTag) {
                                passCtx.updateGroupTag({
                                  label: groupTag.label.trim(),
                                });
                              } else {
                                passCtx.updateGroupTag('');
                              }
                            }}
                            value={passCtx.groupTag}
                          />
                        </Box>
                      </Flex>
                    </FormControl>
                  </Box>
                  {/* NFC field */}
                  <Divider borderColor={modeBorderColor} />
                  <FormControl bg={modeFormControlBg}>
                    <Flex alignItems="center">
                      <Hide below="2xl">
                        <Box pl="24px" flexShrink={0} w="240px">
                          <FormLabel m={0}>Enable NFC</FormLabel>
                        </Box>
                      </Hide>
                      <Box
                        className="inner-box"
                        p="14px"
                        w="full"
                        bg={modeLabelBg}
                      >
                        <Show below="2xl">
                          <FormLabel>Enable NFC</FormLabel>
                        </Show>
                        <Flex
                          direction="row"
                          gap={3}
                          alignItems="center"
                          ml={3}
                        >
                          <Switch
                            id="nfc"
                            isChecked={passCtx.nfc}
                            onChange={() => passCtx.updateNfc(!passCtx.nfc)}
                          />
                        </Flex>
                      </Box>
                    </Flex>
                  </FormControl>
                  {/* QR field */}
                  {(createView || isEdit) && (
                    <Box>
                      <Divider borderColor={modeBorderColor} />
                      <FormControl bg={modeFormControlBg}>
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0}>QR code value</FormLabel>
                            </Box>
                          </Hide>
                          <Box
                            className="inner-box"
                            p="14px"
                            w="full"
                            bg={modeLabelBg}
                          >
                            <Show below="2xl">
                              <FormLabel>QR code value</FormLabel>
                            </Show>
                            <Flex direction="column" gap={3}>
                              <Input
                                variant="filledForDarkBg"
                                id="qrValue"
                                type="text"
                                placeholder="type here"
                                value={passCtx.qrValue}
                                onChange={(e) =>
                                  passCtx.updateQrValue(e.target.value)
                                }
                                onBlur={() => {
                                  const value = passCtx.qrValue;
                                  if (value.trim() === '') {
                                    passCtx.updateQrValue('');
                                  }
                                }}
                                autoComplete="off"
                              />
                              <Flex
                                direction="row"
                                gap={3}
                                alignItems="center"
                                ml={3}
                              >
                                <Text fontSize="14px">Display QR value</Text>
                                <Switch
                                  isRequired={false}
                                  id="qrDisplayText"
                                  isChecked={passCtx.qrDisplayText}
                                  onChange={() =>
                                    passCtx.updateQrDisplayText(
                                      !passCtx.qrDisplayText
                                    )
                                  }
                                  disabled={!passCtx.qrValue}
                                />
                              </Flex>
                            </Flex>
                          </Box>
                        </Flex>
                      </FormControl>
                    </Box>
                  )}
                  {/* Expiry date */}
                  <Box>
                    <Divider borderColor={modeBorderColor} />
                    <FormControl bg={modeFormControlBg}>
                      <Flex alignItems="center">
                        <Hide below="2xl">
                          <Box pl="24px" flexShrink={0} w="240px">
                            <Flex
                              direction="row"
                              alignItems="center"
                              gap={1}
                              align="center"
                            >
                              <FormLabel m={0}>Expiry date and time</FormLabel>
                              <Tooltip
                                label={
                                  'Google passes may expire up to 24 hours after the selected time'
                                }
                                hasArrow
                                placement="top"
                                fontSize="12px"
                                bg={bgTooltip}
                                color={textTooltip}
                                w="205px"
                                borderRadius="6px"
                                textAlign="center"
                                p="10px"
                                isOpen={expiryTooltipOpen}
                              >
                                <Box
                                  onClick={(e) =>
                                    showTooltipHandler(e, 'expiry')
                                  }
                                  ref={tooltipRef}
                                  mb={1}
                                  cursor="pointer"
                                >
                                  <CustomExclamationMarkInverseIcon
                                    boxSize="12px"
                                    mode={'light'}
                                  />
                                </Box>
                              </Tooltip>
                            </Flex>
                          </Box>
                        </Hide>
                        <Box
                          className="inner-box"
                          p="14px"
                          w="full"
                          bg={modeLabelBg}
                        >
                          <Show below="2xl">
                            <Flex
                              direction="row"
                              alignItems="center"
                              gap={1}
                              mb={1}
                              align="center"
                            >
                              <FormLabel m={0}>Expiry date and time</FormLabel>
                              <Tooltip
                                label={
                                  'Google passes may expire up to 24 hours after the selected time'
                                }
                                hasArrow
                                placement="top"
                                fontSize="12px"
                                bg={bgTooltip}
                                color={textTooltip}
                                w="205px"
                                borderRadius="6px"
                                textAlign="center"
                                p="10px"
                                isOpen={expiryTooltipOpen}
                              >
                                <Box
                                  onClick={(e) =>
                                    showTooltipHandler(e, 'expiry')
                                  }
                                  ref={tooltipRef}
                                  mb={1}
                                  cursor="pointer"
                                >
                                  <CustomExclamationMarkInverseIcon
                                    boxSize="12px"
                                    mode={'light'}
                                  />
                                </Box>
                              </Tooltip>
                            </Flex>
                          </Show>
                          <Box w="full">
                            <Input
                              id="startDate"
                              name="startDate"
                              type="hidden"
                            />
                            <Box
                              as={CustomDatePicker}
                              placementTop
                              setDate={setExpiryDate}
                              isEdit={isEdit}
                              startDate
                              expiryDate
                              minBookingDate={minDate}
                              {...(trialExpiry &&
                                organisationStatus === 'trial' && {
                                  maxBookingDate: maxDate,
                                })}
                            />
                          </Box>
                          {!!noExpiryDateError && (
                            <p
                              style={{
                                fontSize: '12px',
                                paddingLeft: '22px',
                                paddingTop: '10px',
                                color: '#E53E3E',
                              }}
                            >
                              {noExpiryDateError}
                            </p>
                          )}
                        </Box>
                      </Flex>
                    </FormControl>
                  </Box>
                  {/* Expiry time */}
                  <Box>
                    <FormControl bg={modeFormControlBg}>
                      <Flex alignItems="center">
                        <Hide below="2xl">
                          <Box pl="24px" flexShrink={0} w="240px"></Box>
                        </Hide>
                        <Box
                          className="inner-box"
                          pt="5px"
                          px="14px"
                          pb="14px"
                          w="full"
                          bg={modeLabelBg}
                        >
                          <Show below="2xl"></Show>
                          <Box w="full">
                            <Input
                              id="startTime"
                              name="startTime"
                              type="hidden"
                            />
                            <CustomTimePicker
                              timeValue={expiryTime ? expiryTime : ''}
                              setTimeValue={setExpiryTime}
                              placementTop
                              isEdit={isEdit}
                              expiryTime
                            />
                          </Box>
                          {!!noExpiryTimeError && (
                            <p
                              style={{
                                fontSize: '12px',
                                paddingLeft: '22px',
                                paddingTop: '10px',
                                color: '#E53E3E',
                              }}
                            >
                              {noExpiryTimeError}
                            </p>
                          )}
                          {!!invalidExpiryTimeError && (
                            <p
                              style={{
                                fontSize: '12px',
                                paddingLeft: '22px',
                                paddingTop: '10px',
                                color: '#E53E3E',
                              }}
                            >
                              {invalidExpiryTimeError}
                            </p>
                          )}
                        </Box>
                      </Flex>
                    </FormControl>
                  </Box>
                  {/* Header field */}
                  {passTemplateCtx &&
                  !!passTemplateCtx.headerOneId &&
                  passTemplateCtx.passType !== 'stampCard' ? (
                    <Box>
                      <Divider borderColor={modeBorderColor} />
                      <FormControl
                        isRequired={
                          passTemplateCtx.headerOneId &&
                          !passTemplateCtx.headerOneValue
                            ? true
                            : false
                        }
                        bg={modeFormControlBg}
                      >
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0}>
                                {passTemplateCtx.headerOneLabel ||
                                  `<${passTemplateCtx.headerOneId}>`}
                              </FormLabel>
                            </Box>
                          </Hide>
                          <Box
                            className="inner-box"
                            p="14px"
                            w="full"
                            bg={modeLabelBg}
                          >
                            <Show below="2xl">
                              <FormLabel>
                                {passTemplateCtx.headerOneLabel ||
                                  `<${passTemplateCtx.headerOneId}>`}
                              </FormLabel>
                            </Show>
                            <Input
                              variant="filledForDarkBg"
                              id="headerOne"
                              type="text"
                              placeholder={
                                !!selectedPassTemplate &&
                                passTemplateCtx.passType === 'storeCard'
                                  ? passTemplateCtx.headerOneValue || '0'
                                  : passTemplateCtx.headerOneValue ||
                                    'type here'
                              }
                              value={passCtx.headerOne}
                              onChange={(e) =>
                                passCtx.updateHeaderOne(e.target.value)
                              }
                              onBlur={() => {
                                const value = passCtx.headerOne;
                                if (value.trim() === '') {
                                  passCtx.updateHeaderOne('');
                                }
                              }}
                              autoComplete="off"
                            />
                            <FormErrorMessage>
                              {errors.firstName?.message || errors.firstName}
                            </FormErrorMessage>
                          </Box>
                        </Flex>
                      </FormControl>
                    </Box>
                  ) : (
                    ''
                  )}
                  {/* Primary field */}
                  {passTemplateCtx && !!passTemplateCtx.primaryOneId ? (
                    <Box>
                      <Divider borderColor={modeBorderColor} />
                      <FormControl isRequired bg={modeFormControlBg}>
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0}>
                                {passTemplateCtx.primaryOneLabel ||
                                  `<${passTemplateCtx.primaryOneId}>`}
                              </FormLabel>
                            </Box>
                          </Hide>
                          <Box
                            className="inner-box"
                            p="14px"
                            w="full"
                            bg={modeLabelBg}
                          >
                            <Show below="2xl">
                              <FormLabel>
                                {passTemplateCtx.primaryOneLabel ||
                                  `<${passTemplateCtx.primaryOneId}>`}
                              </FormLabel>
                            </Show>
                            <Input
                              variant="filledForDarkBg"
                              id="primaryOne"
                              type="text"
                              placeholder="type here"
                              value={passCtx.primaryOne}
                              onChange={(e) =>
                                passCtx.updatePrimaryOne(e.target.value)
                              }
                              onBlur={() => {
                                const value = passCtx.primaryOne;
                                if (value.trim() === '') {
                                  passCtx.updatePrimaryOne('');
                                }
                              }}
                              autoComplete="off"
                            />
                            <FormErrorMessage>
                              {errors.lastName?.message || errors.lastName}
                            </FormErrorMessage>
                          </Box>
                        </Flex>
                      </FormControl>
                    </Box>
                  ) : (
                    ''
                  )}
                  {/* Sec One field */}
                  {passTemplateCtx && !!passTemplateCtx.secOneId ? (
                    <Box>
                      <Divider borderColor={modeBorderColor} />
                      <FormControl
                        isRequired={!passTemplateCtx.secOneValue}
                        bg={modeFormControlBg}
                      >
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0}>
                                {passTemplateCtx.secOneLabel ||
                                  `<${passTemplateCtx.secOneId}>`}
                              </FormLabel>
                            </Box>
                          </Hide>
                          <Box
                            className="inner-box"
                            p="14px"
                            w="full"
                            bg={modeLabelBg}
                          >
                            <Show below="2xl">
                              <FormLabel>
                                {passTemplateCtx.secOneLabel ||
                                  `<${passTemplateCtx.secOneId}>`}
                              </FormLabel>
                            </Show>
                            <Input
                              variant="filledForDarkBg"
                              id="secOne"
                              type="text"
                              value={passCtx.secOne}
                              placeholder={
                                passTemplateCtx.secOneValue || 'type here'
                              }
                              onChange={(e) =>
                                passCtx.updateSecOne(e.target.value)
                              }
                              onBlur={() => {
                                const value = passCtx.secOne;
                                if (value.trim() === '') {
                                  passCtx.updateSecOne('');
                                }
                              }}
                            />
                            <FormErrorMessage>
                              {errors.mobileNumber?.message ||
                                errors.mobileNumber}
                            </FormErrorMessage>
                          </Box>
                        </Flex>
                      </FormControl>
                    </Box>
                  ) : (
                    ''
                  )}
                  {/* Sec Two field */}
                  {passTemplateCtx && !!passTemplateCtx.secTwoId ? (
                    <Box>
                      <Divider borderColor={modeBorderColor} />
                      <FormControl
                        isRequired={!passTemplateCtx.secTwoValue}
                        bg={modeFormControlBg}
                      >
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0}>
                                {passTemplateCtx.secTwoLabel ||
                                  `<${passTemplateCtx.secTwoId}>`}
                              </FormLabel>
                            </Box>
                          </Hide>
                          <Box
                            className="inner-box"
                            p="14px"
                            w="full"
                            bg={modeLabelBg}
                          >
                            <Show below="2xl">
                              <FormLabel>
                                {passTemplateCtx.secTwoLabel ||
                                  `<${passTemplateCtx.secTwoId}>`}
                              </FormLabel>
                            </Show>
                            <Input
                              variant="filledForDarkBg"
                              id="secTwo"
                              type="text"
                              value={passCtx.secTwo}
                              placeholder={
                                passTemplateCtx.secTwoValue || 'type here'
                              }
                              onChange={(e) =>
                                passCtx.updateSecTwo(e.target.value)
                              }
                              onBlur={() => {
                                const value = passCtx.secTwo;
                                if (value.trim() === '') {
                                  passCtx.updateSecTwo('');
                                }
                              }}
                            />
                            <FormErrorMessage>
                              {errors.email?.message || errors.email}
                            </FormErrorMessage>
                          </Box>
                        </Flex>
                      </FormControl>
                    </Box>
                  ) : (
                    ''
                  )}
                  {/* Sec Three field */}
                  {passTemplateCtx && !!passTemplateCtx.secThreeId ? (
                    <Box>
                      <Divider borderColor={modeBorderColor} />
                      <FormControl
                        isRequired={!passTemplateCtx.secThreeValue}
                        bg={modeFormControlBg}
                      >
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0}>
                                {passTemplateCtx.secThreeLabel ||
                                  `<${passTemplateCtx.secThreeId}>`}
                              </FormLabel>
                            </Box>
                          </Hide>
                          <Box
                            className="inner-box"
                            p="14px"
                            w="full"
                            bg={modeLabelBg}
                          >
                            <Show below="2xl">
                              <FormLabel>
                                {passTemplateCtx.secThreeLabel ||
                                  `<${passTemplateCtx.secThreeId}>`}
                              </FormLabel>
                            </Show>
                            <Input
                              variant="filledForDarkBg"
                              id="secThree"
                              type="text"
                              value={passCtx.secThree}
                              placeholder={
                                passTemplateCtx.secThreeValue || 'type here'
                              }
                              onChange={(e) =>
                                passCtx.updateSecThree(e.target.value)
                              }
                              autoComplete="off"
                              onBlur={() => {
                                const value = passCtx.secThree;
                                if (value.trim() === '') {
                                  passCtx.updateSecThree('');
                                }
                              }}
                            />
                            <FormErrorMessage>
                              {errors.section?.message || errors.section}
                            </FormErrorMessage>
                            {warning && (
                              <Flex
                                justifyContent="center"
                                alignItems="flex-start"
                                mt={2}
                              >
                                <CustomExclamationMarkTriangleIcon
                                  boxSize="18px"
                                  color="brand"
                                  mr="6px"
                                  mt="3px"
                                />
                                <Text color={modeHelperText} fontSize="14px">
                                  <Text as="span" color={modeHelperTextBold}>
                                    Warning:{' '}
                                  </Text>
                                  {renderWarningText('section')}
                                </Text>
                              </Flex>
                            )}
                          </Box>
                        </Flex>
                      </FormControl>
                    </Box>
                  ) : (
                    ''
                  )}
                  {/* Aux One field */}
                  {passTemplateCtx && !!passTemplateCtx.auxOneId ? (
                    <Box>
                      <Divider borderColor={modeBorderColor} />
                      <FormControl
                        isRequired={!passTemplateCtx.auxOneValue}
                        isInvalid={errors.section}
                        bg={modeFormControlBg}
                      >
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0}>
                                {passTemplateCtx.auxOneLabel ||
                                  `<${passTemplateCtx.auxOneId}>`}
                              </FormLabel>
                            </Box>
                          </Hide>
                          <Box
                            className="inner-box"
                            p="14px"
                            w="full"
                            bg={modeLabelBg}
                          >
                            <Show below="2xl">
                              <FormLabel>
                                {passTemplateCtx.auxOneLabel ||
                                  `<${passTemplateCtx.auxOneId}>`}
                              </FormLabel>
                            </Show>
                            <Input
                              variant="filledForDarkBg"
                              id="auxOne"
                              type="text"
                              value={passCtx.auxOne}
                              placeholder={
                                passTemplateCtx.auxOneValue || 'type here'
                              }
                              onChange={(e) =>
                                passCtx.updateAuxOne(e.target.value)
                              }
                              autoComplete="off"
                              onBlur={() => {
                                const value = passCtx.auxOne;
                                if (value.trim() === '') {
                                  passCtx.updateAuxOne('');
                                }
                              }}
                            />
                            <FormErrorMessage>
                              {errors.section?.message || errors.section}
                            </FormErrorMessage>
                            {warning && (
                              <Flex
                                justifyContent="center"
                                alignItems="flex-start"
                                mt={2}
                              >
                                <CustomExclamationMarkTriangleIcon
                                  boxSize="18px"
                                  color="brand"
                                  mr="6px"
                                  mt="3px"
                                />
                                <Text color={modeHelperText} fontSize="14px">
                                  <Text as="span" color={modeHelperTextBold}>
                                    Warning:{' '}
                                  </Text>
                                  {renderWarningText('section')}
                                </Text>
                              </Flex>
                            )}
                          </Box>
                        </Flex>
                      </FormControl>
                    </Box>
                  ) : (
                    ''
                  )}
                  {/* Aux Two field */}
                  {passTemplateCtx && !!passTemplateCtx.auxTwoId ? (
                    <Box>
                      <Divider borderColor={modeBorderColor} />
                      <FormControl
                        isRequired={!passTemplateCtx.auxTwoValue}
                        isInvalid={errors.section}
                        bg={modeFormControlBg}
                      >
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0}>
                                {passTemplateCtx.auxTwoLabel ||
                                  `<${passTemplateCtx.auxTwoId}>`}
                              </FormLabel>
                            </Box>
                          </Hide>
                          <Box
                            className="inner-box"
                            p="14px"
                            w="full"
                            bg={modeLabelBg}
                          >
                            <Show below="2xl">
                              <FormLabel>
                                {passTemplateCtx.auxTwoLabel ||
                                  `<${passTemplateCtx.auxTwoId}>`}
                              </FormLabel>
                            </Show>
                            <Input
                              variant="filledForDarkBg"
                              id="auxTwo"
                              type="text"
                              placeholder={
                                passTemplateCtx.auxTwoValue || 'type here'
                              }
                              value={passCtx.auxTwo}
                              onChange={(e) =>
                                passCtx.updateAuxTwo(e.target.value)
                              }
                              autoComplete="off"
                              onBlur={() => {
                                const value = passCtx.auxTwo;
                                if (value.trim() === '') {
                                  passCtx.updateAuxTwo('');
                                }
                              }}
                            />
                            <FormErrorMessage>
                              {errors.section?.message || errors.section}
                            </FormErrorMessage>
                            {warning && (
                              <Flex
                                justifyContent="center"
                                alignItems="flex-start"
                                mt={2}
                              >
                                <CustomExclamationMarkTriangleIcon
                                  boxSize="18px"
                                  color="brand"
                                  mr="6px"
                                  mt="3px"
                                />
                                <Text color={modeHelperText} fontSize="14px">
                                  <Text as="span" color={modeHelperTextBold}>
                                    Warning:{' '}
                                  </Text>
                                  {renderWarningText('section')}
                                </Text>
                              </Flex>
                            )}
                          </Box>
                        </Flex>
                      </FormControl>
                    </Box>
                  ) : (
                    ''
                  )}
                  {/* Aux Three field */}
                  {passTemplateCtx && !!passTemplateCtx.auxThreeId ? (
                    <Box>
                      <Divider borderColor={modeBorderColor} />
                      <FormControl
                        isRequired={!passTemplateCtx.auxThreeValue}
                        isInvalid={errors.section}
                        bg={modeFormControlBg}
                      >
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0}>
                                {passTemplateCtx.auxThreeLabel ||
                                  `<${passTemplateCtx.auxThreeId}>`}
                              </FormLabel>
                            </Box>
                          </Hide>
                          <Box
                            className="inner-box"
                            p="14px"
                            w="full"
                            bg={modeLabelBg}
                          >
                            <Show below="2xl">
                              <FormLabel>
                                {passTemplateCtx.auxThreeLabel ||
                                  `<${passTemplateCtx.auxThreeId}>`}
                              </FormLabel>
                            </Show>
                            <Input
                              variant="filledForDarkBg"
                              id="auxThree"
                              type="text"
                              value={passCtx.auxThree}
                              placeholder={
                                passTemplateCtx.auxThreeValue || 'type here'
                              }
                              onChange={(e) =>
                                passCtx.updateAuxThree(e.target.value)
                              }
                              autoComplete="off"
                              onBlur={() => {
                                const value = passCtx.auxThree;
                                if (value.trim() === '') {
                                  passCtx.updateAuxThree('');
                                }
                              }}
                            />
                            <FormErrorMessage>
                              {errors.section?.message || errors.section}
                            </FormErrorMessage>
                            {warning && (
                              <Flex
                                justifyContent="center"
                                alignItems="flex-start"
                                mt={2}
                              >
                                <CustomExclamationMarkTriangleIcon
                                  boxSize="18px"
                                  color="brand"
                                  mr="6px"
                                  mt="3px"
                                />
                                <Text color={modeHelperText} fontSize="14px">
                                  <Text as="span" color={modeHelperTextBold}>
                                    Warning:{' '}
                                  </Text>
                                  {renderWarningText('section')}
                                </Text>
                              </Flex>
                            )}
                          </Box>
                        </Flex>
                      </FormControl>
                    </Box>
                  ) : (
                    ''
                  )}
                  {/* Back One field */}
                  {passTemplateCtx && !!passTemplateCtx.backOneId ? (
                    <Box>
                      <Divider borderColor={modeBorderColor} />
                      <FormControl
                        isRequired={!passTemplateCtx.backOneValue}
                        isInvalid={errors.section}
                        bg={modeFormControlBg}
                      >
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0}>
                                {passTemplateCtx.backOneLabel ||
                                  `<${passTemplateCtx.backOneId}>`}
                              </FormLabel>
                            </Box>
                          </Hide>
                          <Box
                            className="inner-box"
                            p="14px"
                            w="full"
                            bg={modeLabelBg}
                          >
                            <Show below="2xl">
                              <FormLabel>
                                {passTemplateCtx.backOneLabel ||
                                  `<${passTemplateCtx.backOneId}>`}
                              </FormLabel>
                            </Show>
                            <Input
                              variant="filledForDarkBg"
                              id="backOne"
                              type="text"
                              value={passCtx.backOne}
                              placeholder={
                                passTemplateCtx.backOneValue || 'type here'
                              }
                              onChange={(e) =>
                                passCtx.updateBackOne(e.target.value)
                              }
                              autoComplete="off"
                              onBlur={() => {
                                const value = passCtx.backOne;
                                if (value.trim() === '') {
                                  passCtx.updateBackOne('');
                                }
                              }}
                            />
                            <FormErrorMessage>
                              {errors.section?.message || errors.section}
                            </FormErrorMessage>
                            {warning && (
                              <Flex
                                justifyContent="center"
                                alignItems="flex-start"
                                mt={2}
                              >
                                <CustomExclamationMarkTriangleIcon
                                  boxSize="18px"
                                  color="brand"
                                  mr="6px"
                                  mt="3px"
                                />
                                <Text color={modeHelperText} fontSize="14px">
                                  <Text as="span" color={modeHelperTextBold}>
                                    Warning:{' '}
                                  </Text>
                                  {renderWarningText('section')}
                                </Text>
                              </Flex>
                            )}
                          </Box>
                        </Flex>
                      </FormControl>
                    </Box>
                  ) : (
                    ''
                  )}
                  {/* Back Two field */}
                  {passTemplateCtx && !!passTemplateCtx.backTwoId ? (
                    <Box>
                      <Divider borderColor={modeBorderColor} />
                      <FormControl
                        isRequired={!passTemplateCtx.backTwoValue}
                        isInvalid={errors.section}
                        bg={modeFormControlBg}
                      >
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0}>
                                {passTemplateCtx.backTwoLabel ||
                                  `<${passTemplateCtx.backTwoId}>`}
                              </FormLabel>
                            </Box>
                          </Hide>
                          <Box
                            className="inner-box"
                            p="14px"
                            w="full"
                            bg={modeLabelBg}
                          >
                            <Show below="2xl">
                              <FormLabel>
                                {passTemplateCtx.backTwoLabel ||
                                  `<${passTemplateCtx.backTwoId}>`}
                              </FormLabel>
                            </Show>
                            <Input
                              variant="filledForDarkBg"
                              id="backTwo"
                              type="text"
                              value={passCtx.backTwo}
                              placeholder={
                                passTemplateCtx.backTwoValue || 'type here'
                              }
                              onChange={(e) =>
                                passCtx.updateBackTwo(e.target.value)
                              }
                              autoComplete="off"
                              onBlur={() => {
                                const value = passCtx.backTwo;
                                if (value.trim() === '') {
                                  passCtx.updateBackTwo('');
                                }
                              }}
                            />
                            <FormErrorMessage>
                              {errors.section?.message || errors.section}
                            </FormErrorMessage>
                            {warning && (
                              <Flex
                                justifyContent="center"
                                alignItems="flex-start"
                                mt={2}
                              >
                                <CustomExclamationMarkTriangleIcon
                                  boxSize="18px"
                                  color="brand"
                                  mr="6px"
                                  mt="3px"
                                />
                                <Text color={modeHelperText} fontSize="14px">
                                  <Text as="span" color={modeHelperTextBold}>
                                    Warning:{' '}
                                  </Text>
                                  {renderWarningText('section')}
                                </Text>
                              </Flex>
                            )}
                          </Box>
                        </Flex>
                      </FormControl>
                    </Box>
                  ) : (
                    ''
                  )}
                  {/* Back Three field */}
                  {passTemplateCtx && !!passTemplateCtx.backThreeId ? (
                    <Box>
                      <Divider borderColor={modeBorderColor} />
                      <FormControl
                        isRequired={!passTemplateCtx.backThreeValue}
                        isInvalid={errors.section}
                        bg={modeFormControlBg}
                      >
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0}>
                                {passTemplateCtx.backThreeLabel ||
                                  `<${passTemplateCtx.backThreeId}>`}
                              </FormLabel>
                            </Box>
                          </Hide>
                          <Box
                            className="inner-box"
                            p="14px"
                            w="full"
                            bg={modeLabelBg}
                          >
                            <Show below="2xl">
                              <FormLabel>
                                {passTemplateCtx.backThreeLabel ||
                                  `<${passTemplateCtx.backThreeId}>`}
                              </FormLabel>
                            </Show>
                            <Input
                              variant="filledForDarkBg"
                              id="backThree"
                              type="text"
                              value={passCtx.backThree}
                              placeholder={
                                passTemplateCtx.backThreeValue || 'type here'
                              }
                              onChange={(e) =>
                                passCtx.updateBackThree(e.target.value)
                              }
                              autoComplete="off"
                              onBlur={() => {
                                const value = passCtx.backThree;
                                if (value.trim() === '') {
                                  passCtx.updateBackThree('');
                                }
                              }}
                            />
                            <FormErrorMessage>
                              {errors.section?.message || errors.section}
                            </FormErrorMessage>
                            {warning && (
                              <Flex
                                justifyContent="center"
                                alignItems="flex-start"
                                mt={2}
                              >
                                <CustomExclamationMarkTriangleIcon
                                  boxSize="18px"
                                  color="brand"
                                  mr="6px"
                                  mt="3px"
                                />
                                <Text color={modeHelperText} fontSize="14px">
                                  <Text as="span" color={modeHelperTextBold}>
                                    Warning:{' '}
                                  </Text>
                                  {renderWarningText('section')}
                                </Text>
                              </Flex>
                            )}
                          </Box>
                        </Flex>
                      </FormControl>
                    </Box>
                  ) : (
                    ''
                  )}
                  {/* Back Four field */}
                  {passTemplateCtx && !!passTemplateCtx.backFourId ? (
                    <Box>
                      <Divider borderColor={modeBorderColor} />
                      <FormControl
                        isRequired={!passTemplateCtx.backFourValue}
                        isInvalid={errors.section}
                        bg={modeFormControlBg}
                      >
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0}>
                                {passTemplateCtx.backFourLabel ||
                                  `<${passTemplateCtx.backFourId}>`}
                              </FormLabel>
                            </Box>
                          </Hide>
                          <Box
                            className="inner-box"
                            p="14px"
                            w="full"
                            bg={modeLabelBg}
                          >
                            <Show below="2xl">
                              <FormLabel>
                                {passTemplateCtx.backFourLabel ||
                                  `<${passTemplateCtx.backFourId}>`}
                              </FormLabel>
                            </Show>
                            <Input
                              variant="filledForDarkBg"
                              id="backFour"
                              type="text"
                              value={passCtx.backFour}
                              placeholder={
                                passTemplateCtx.backFourValue || 'type here'
                              }
                              onChange={(e) =>
                                passCtx.updateBackFour(e.target.value)
                              }
                              autoComplete="off"
                              onBlur={() => {
                                const value = passCtx.backFour;
                                if (value.trim() === '') {
                                  passCtx.updateBackFour('');
                                }
                              }}
                            />
                            <FormErrorMessage>
                              {errors.section?.message || errors.section}
                            </FormErrorMessage>
                            {warning && (
                              <Flex
                                justifyContent="center"
                                alignItems="flex-start"
                                mt={2}
                              >
                                <CustomExclamationMarkTriangleIcon
                                  boxSize="18px"
                                  color="brand"
                                  mr="6px"
                                  mt="3px"
                                />
                                <Text color={modeHelperText} fontSize="14px">
                                  <Text as="span" color={modeHelperTextBold}>
                                    Warning:{' '}
                                  </Text>
                                  {renderWarningText('section')}
                                </Text>
                              </Flex>
                            )}
                          </Box>
                        </Flex>
                      </FormControl>
                    </Box>
                  ) : (
                    ''
                  )}
                  {/* Back Five field */}
                  {passTemplateCtx && !!passTemplateCtx.backFiveId ? (
                    <Box>
                      <Divider borderColor={modeBorderColor} />
                      <FormControl
                        isRequired={!passTemplateCtx.backFiveValue}
                        isInvalid={errors.section}
                        bg={modeFormControlBg}
                      >
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0}>
                                {passTemplateCtx.backFiveLabel ||
                                  `<${passTemplateCtx.backFiveId}>`}
                              </FormLabel>
                            </Box>
                          </Hide>
                          <Box
                            className="inner-box"
                            p="14px"
                            w="full"
                            bg={modeLabelBg}
                          >
                            <Show below="2xl">
                              <FormLabel>
                                {passTemplateCtx.backFiveLabel ||
                                  `<${passTemplateCtx.backFiveId}>`}
                              </FormLabel>
                            </Show>
                            <Input
                              variant="filledForDarkBg"
                              id="backFive"
                              type="text"
                              value={passCtx.backFive}
                              placeholder={
                                passTemplateCtx.backFiveValue || 'type here'
                              }
                              onChange={(e) =>
                                passCtx.updateBackFive(e.target.value)
                              }
                              autoComplete="off"
                              onBlur={() => {
                                const value = passCtx.backFive;
                                if (value.trim() === '') {
                                  passCtx.updateBackFive('');
                                }
                              }}
                            />
                            <FormErrorMessage>
                              {errors.section?.message || errors.section}
                            </FormErrorMessage>
                            {warning && (
                              <Flex
                                justifyContent="center"
                                alignItems="flex-start"
                                mt={2}
                              >
                                <CustomExclamationMarkTriangleIcon
                                  boxSize="18px"
                                  color="brand"
                                  mr="6px"
                                  mt="3px"
                                />
                                <Text color={modeHelperText} fontSize="14px">
                                  <Text as="span" color={modeHelperTextBold}>
                                    Warning:{' '}
                                  </Text>
                                  {renderWarningText('section')}
                                </Text>
                              </Flex>
                            )}
                          </Box>
                        </Flex>
                      </FormControl>
                    </Box>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>

              <Show above="xl">
                <Box mt={{ base: '30px' }} w="full">
                  <Divider borderColor="primaryBackground" mb="15px" />

                  <Box display="flex">
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      disabled={
                        (isSubmitting && !isEdit) || (isEdit && isSubmitting)
                      }
                      alt="Save"
                      size="sm"
                      mr="24px"
                      mb={{ base: '10px', sm: '0px' }}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="secondary"
                      as={RouterLink}
                      to="/passes"
                      type="button"
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Show>
            </Flex>

            {/* TBD IF NUMBER OF TICKETS PARAMETER IS ADDED LATER */}
            {/* Number of tickets */}
            {/* <FormControl
                    isRequired
                    isInvalid={errors.numberOfTickets}
                    bg={modeFormControlBg}
                  >
                    <Flex alignItems="center">
                      <Hide below="2xl">
                        <Box pl="24px" flexShrink={0} w="240px">
                          <FormLabel m={0}>Number of tickets</FormLabel>
                        </Box>
                      </Hide>
                      <Box className="inner-box" p="14px" w="full" bg={modeLabelBg}>
                        <Show below="2xl">
                          <FormLabel>Number of tickets</FormLabel>
                        </Show>
                        <Show below="md">
                          <HStack>
                            <Button {...dec} variant="mobileStepper" alt="-">
                              -
                            </Button>
                            <Input
                              {...input}
                              variant="filledForDarkBg"
                              id="numberOfTickets"
                              min={1}
                              {...register('numberOfTickets')}
                              placeholder="Select number of tickets"
                              onChange={(e) => console.log(e)}
                            />
                            <Button {...inc} variant="mobileStepper" alt="+">
                              +
                            </Button>
                          </HStack>
                        </Show>
                        <Show above="md">
                          <NumberInput
                            variant="filledForDarkBg"
                            id="numberOfTickets"
                            min={1}
                            {...register('numberOfTickets')}
                            onChange={(e) => console.log(e)}
                          >
                            <NumberInputField placeholder="Select number of tickets" />
                            <NumberInputStepper mr="15px">
                              <NumberIncrementStepper
                                children={
                                  <CustomChevronLeftIcon
                                    transform="rotate(90deg)"
                                    w="18px"
                                    h="18px"
                                    color={modeStepperColor}
                                  />
                                }
                              />
                              <NumberDecrementStepper
                                children={
                                  <CustomChevronLeftIcon
                                    transform="rotate(270deg)"
                                    w="18px"
                                    h="18px"
                                    color={modeStepperColor}
                                  />
                                }
                              />
                            </NumberInputStepper>
                          </NumberInput>
                        </Show>
                        <FormErrorMessage>
                          {errors.numberOfTickets?.message || errors.numberOfTickets}
                        </FormErrorMessage>
                      </Box>
                    </Flex>
                  </FormControl>
                  <Divider borderColor={modeBorderColor} /> */}

            {/* Pass template preview */}
            <PreviewPassTemplates
              selectedPassTemplate={selectedPassTemplate}
              passInfo={passInfo}
              isEdit={isEdit}
              createView={createView}
            />
          </Grid>

          <Show below="xl">
            <Box mt={{ base: '30px' }} w="full">
              <Divider borderColor="primaryBackground" mb="15px" />

              <Box display="flex">
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={
                    (isSubmitting && !isEdit) || (isEdit && isSubmitting)
                  }
                  alt="Save"
                  size="sm"
                  mr="24px"
                  mb={{ base: '10px', sm: '0px' }}
                >
                  Save
                </Button>
                <Button
                  size="sm"
                  variant="secondary"
                  as={RouterLink}
                  to="/passes"
                  type="button"
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Show>
        </Flex>
      </form>
    </Container>
  );
};
export default PassesCreatePageForm;
