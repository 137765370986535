import { PopupButton } from '@typeform/embed-react';
import './FeedbackFormOverlay.css';
const TYPEFORM_ID = 'BwU415Nj';

const FeedbackFormOverlay = () => {
  return (
    <PopupButton id={TYPEFORM_ID} className="cta-button">
      Give feedback
    </PopupButton>
  );
};

export default FeedbackFormOverlay;
