import { createContext, useState } from 'react';

const initialFilterValues = {
  reader: '',
  pass: '',
  groupTag: '',
  scanType: '',
  status: '',
  validationType: '',
};

const LiveEventDataListContext = createContext({
  initialLiveEventData: {
    items: [],
    included: [],
    status: 'idle',
    total: 0,
    pageCount: 0,
    hasMore: false,
    loading: false,
    error: null,
  },
  filterValues: initialFilterValues,
  loadMoreClick: null,
  updateInitialLiveEventData: () => {},
  updateFilterValues: () => {},
  updateLoadMoreClick: () => {},
});

export const LiveEventDataListContextProvider = ({ children }) => {
  const [initialLiveEventData, setInitialLiveEventData] = useState({
    items: [],
    included: [],
    status: 'idle',
    total: 0,
    pageCount: 0,
    hasMore: false,
    loading: false,
    error: null,
  });
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const [loadMoreClick, setLoadMoreClick] = useState(false);

  const updateInitialLiveEventDataHandler = (param) => {
    setInitialLiveEventData(param);
  };

  function updateFilterValuesHandler(filterValues) {
    setFilterValues(filterValues);
  }

  const updateLoadMoreClickHandler = (param) => {
    setLoadMoreClick(param);
  };

  const context = {
    initialLiveEventData,
    filterValues,
    loadMoreClick,
    updateInitialLiveEventData: updateInitialLiveEventDataHandler,
    updateFilterValues: updateFilterValuesHandler,
    updateLoadMoreClick: updateLoadMoreClickHandler,
  };

  return (
    <LiveEventDataListContext.Provider value={context}>
      {children}
    </LiveEventDataListContext.Provider>
  );
};

export default LiveEventDataListContext;
