import React, { useContext, useState } from 'react';
import PassDownloadTemplateContext from '../../../store/client/PassDownloadTemplateContext';
import CropModal from '../../modals/CropModal';
import CustomDropzone from '../../common/CustomDropzone';
import { useDisclosure } from '@chakra-ui/react';

function LogoImage() {
  const [droppedImage, setDroppedImage] = useState(null);
  const passDownloadCtx = useContext(PassDownloadTemplateContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { logo: image } = passDownloadCtx;

  function handleUpdateDroppedImage(image) {
    setDroppedImage(image);
    passDownloadCtx.updateLogo({
      ...image,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 360 / 110,
    });
  }

  function handleSaveDroppedImage(base64DroppedFile) {
    passDownloadCtx.updateLogoImage(base64DroppedFile);
    passDownloadCtx.updateLogo({
      ...image,
      src: droppedImage.preview,
      mimeType: droppedImage.type,
    });
  }

  function isImageAdded() {
    onOpen();
  }

  async function updateImage(image) {
    passDownloadCtx.updateLogo(image);
  }

  return (
    <>
      <CropModal
        key="download_page_logo_image_crop_modal"
        onClose={onClose}
        onOpen={onOpen}
        isOpen={isOpen}
        image={image}
        updateImage={updateImage}
        parent="download_page_logo_image"
        title="Crop logo image"
        droppedImage={droppedImage}
        saveDroppedImage={handleSaveDroppedImage}
      />
      <CustomDropzone
        parent="download_page_logo_image"
        key="download_page_logo_image_drop_zone"
        image={image}
        isImageAdded={isImageAdded}
        explanation="Recommended file type is png with transparent background."
        accept={['image/jpg', 'image/jpeg', 'image/png', 'image/x-png']}
        droppedImage={droppedImage}
        updateDroppedImage={handleUpdateDroppedImage}
      />
    </>
  );
}

export default LogoImage;
