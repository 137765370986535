import { Container, Divider, useColorModeValue } from '@chakra-ui/react';
import Breadcrumbs from '../../common/Breadcrumbs';
import EmptyArtistAnimated from '../../theme/illustrations-animated//EmptyArtistAnimated';
import EmptyArtistDarkAnimated from '../../theme/illustrations-animated//EmptyArtistDarkAnimated';
import LiveEventDataHeader from '../../components/client/live-event-data/LiveEventDataHeader';
import LiveEventDataList from '../../components/client/live-event-data/LiveEventDataList';
import { LiveEventDataListContextProvider } from '../../store/client/LiveEventDataListContext';
import useOrganizationStatus from '../../hooks/useOrganisationStatus';

const LiveEventDataPage = () => {
  const modeSvgIcon = useColorModeValue(
    <EmptyArtistAnimated />,
    <EmptyArtistDarkAnimated />
  );
  const { shouldRender, components } = useOrganizationStatus(modeSvgIcon);

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Scans',
      linkUrl: null,
    },
  ];

  return (
    <LiveEventDataListContextProvider>
      <Container display="flex" flexDir="column" flex="1" pb={6}>
        <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />

        <LiveEventDataHeader />

        <Divider borderColor="primaryBackground" mb={6} />
        {components}

        {shouldRender && <LiveEventDataList />}
      </Container>
    </LiveEventDataListContextProvider>
  );
};
export default LiveEventDataPage;
