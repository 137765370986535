import appsignal from '../../../appsignal';
import {
  Button,
  Box,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  VStack,
  Link,
  Circle,
  Text,
  Checkbox,
  Divider,
  Collapse,
  useColorModeValue,
  useToast,
  Flex,
  Hide,
  Show,
  Input,
  Select,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { useDisclosure } from '@chakra-ui/react';
import { CustomFilterIcon } from '../../../theme/icons/CustomFilterIcon';
import { useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import LiveEventDataListContext from '../../../store/client/LiveEventDataListContext';
import {
  CustomReactSelect,
  formatOptionLabelSelectPassType,
} from '../../common/CustomReactSelect';
import { HttpContext } from '../../../context/HttpContext';
import { MeContext } from '../../../context/MeContext';
import { getErrorResponsePayload } from '../../../utils/ajax';
import CustomToast from '../../../common/CustomToast';
import { CloseIcon } from '@chakra-ui/icons';

function LiveEventDataListFilter() {
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);
  const [totalActiveFilters, setTotalActiveFilters] = useState(0);
  const [readers, setReaders] = useState([]);
  const [groupTagsList, setGroupTagsList] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit, reset, watch, control, setValue } = useForm();
  const liveEventDataListCtx = useContext(LiveEventDataListContext);
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;

  const byPass = watch('byPass');
  const byGroupTag = watch('byGroupTag');
  const byReader = watch('byReader');
  const byScanType = watch('byScanType');
  const byValidationType = watch('byValidationType');
  const byStatus = watch('byStatus');

  // FETCHING READERS
  useEffect(() => {
    const getReaders = async () => {
      try {
        const endpoint = filterEntity
          ? `api/v1/entities/${filterEntity.uuid}/readers`
          : 'api/v1/readers';
        const response = await authAxios.get(`${endpoint}?per_page=100`);
        const fetchedReaders = response?.data?.data?.map((reader) => {
          return { label: reader.attributes.name, value: reader.id };
        });
        setReaders(fetchedReaders);
      } catch (error) {
        appsignal.sendError(error);
        console.log(error);
        const { message, code } = getErrorResponsePayload(error);
        code !== 401 &&
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={message ? message : `Something went wrong`}
                description={!message && 'Please try again later'}
                onClose={props.onClose}
              />
            ),
          });
      }
    };

    getReaders();

    return () => {
      setReaders([]);
    };
  }, [authAxios, filterEntity]);

  // FETCHING GROUP TAGS
  useEffect(() => {
    const getGroupTags = async () => {
      try {
        let response;
        if (filterEntity) {
          response = await authAxios.get(
            `api/v1/entities/${filterEntity.uuid}/passes?fields=groupTag`
          );
        } else {
          response = await authAxios.get(`api/v1/passes?fields=groupTag`);
        }
        const data = response.data?.data;
        const groupTags = data.map((item) => {
          if (item.attributes.groupTag) {
            return {
              label: item.attributes.groupTag,
            };
          } else {
            return undefined;
          }
        });
        const filteredGroupTags = groupTags.filter(
          (item) => item !== undefined
        );
        const uniqueGroupTags = Array.from(
          new Set(filteredGroupTags.map((a) => a.label))
        ).map((label) => {
          return filteredGroupTags.find((a) => a.label === label);
        });
        setGroupTagsList(uniqueGroupTags);
      } catch (error) {
        appsignal.sendError(error);
        console.log(error);
      }
    };
    getGroupTags();
  }, [authAxios, filterEntity]);

  useEffect(() => {
    onResetAll();
  }, [filterEntity]);

  const onSubmit = async (values) => {
    // on submit alter values object and add status from useState
    const filteredValues = {
      reader: values.byReader && values.reader ? values.reader : '',
      groupTag: values.byGroupTag && values.groupTag ? values.groupTag : '',
      pass: values.byPass && values.pass ? values.pass : '',
      scanType: values.byScanType && values.scanType ? values.scanType : '',
      status: values.byStatus && values.status ? values.status : '',
      validationType:
        values.byValidationType && values.validationType
          ? values.validationType
          : '',
    };

    liveEventDataListCtx.updateFilterValues(filteredValues);

    let countableFilterValues = [];
    Object.entries(values).forEach(([key, value]) => {
      if (!key.startsWith('by') && value !== false) {
        countableFilterValues = countableFilterValues.concat(value);
      }
    });
    setTotalActiveFilters(
      countableFilterValues.filter((val) => val.length !== 0).length
    );
    onClose();
  };

  const onResetAll = async () => {
    reset({
      byReader: false,
      byGroupTag: false,
      byPass: false,
      byScanType: false,
      byStatus: false,
      byValidationType: false,
      reader: '',
      groupTag: '',
      pass: '',
      scanType: '',
      status: '',
      validationType: '',
    });

    liveEventDataListCtx.updateFilterValues({
      reader: '',
      groupTag: '',
      pass: '',
      scanType: '',
      status: '',
      validationType: '',
    });
    setTotalActiveFilters(0);
    onClose();
  };

  return (
    <Flex
      alignSelf="flex-end"
      justify="flex-start"
      flexDirection={{ base: 'row-reverse', sm: 'row' }}
      flexGrow={{ base: '1', sm: 'unset' }}
    >
      {totalActiveFilters > 0 && (
        <Button
          variant="white"
          size="sm"
          w={{ base: '36px', sm: 'unset' }}
          px={{ base: '0', sm: '20px' }}
          mr={{ base: '0', sm: '10px' }}
          ml={{ base: '5px', sm: '0' }}
          onClick={onResetAll}
        >
          <Hide below="sm">
            <Text
              textStyle="headingFamilyMedium"
              textTransform="none"
              fontWeight="500"
              lineHeight="28px"
            >
              Clear
            </Text>
          </Hide>
          <Show below="sm">
            <CloseIcon />
          </Show>
        </Button>
      )}
      <Button size="sm" px={{ base: '15px', sm: '30px' }} onClick={onOpen}>
        <Box as="span" zIndex={1}>
          {totalActiveFilters ? (
            <Box as="span" display="inline-block">
              <Circle
                bg="white"
                size="18px"
                mr="10px"
                color="brand"
                fontSize="12px"
              >
                {totalActiveFilters}
              </Circle>
            </Box>
          ) : (
            <CustomFilterIcon boxSize="19px" mr="10px" />
          )}
          Filter
        </Box>
      </Button>
      <Drawer onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <DrawerContent bg="white">
            <DrawerCloseButton />
            <DrawerHeader
              borderBottomWidth="1px"
              borderBottomColor="primaryBackground"
              pt={{ base: '16px', lg: '56px' }}
              bg="white"
            >
              Filter Scans
            </DrawerHeader>
            <DrawerBody p="0" bg="white">
              <VStack spacing={0}>
                <FormControl px="24px" mt="12px">
                  <Checkbox
                    id="byReader"
                    {...register('byReader', {
                      onChange: (e) => {
                        if (!e.target.checked) {
                          setValue('reader', '');
                        }
                      },
                    })}
                  >
                    <Text color="secondaryDark">By reader</Text>
                  </Checkbox>
                  <Collapse in={byReader} animateOpacity>
                    <Controller
                      name="reader"
                      control={control}
                      render={({ field }) => (
                        <CustomReactSelect
                          options={readers}
                          formatOptionLabel={formatOptionLabelSelectPassType}
                          placeholder="Select reader"
                          {...field}
                          id="passTypeFilter"
                        />
                      )}
                    />
                  </Collapse>
                </FormControl>
                <Box py="12px" w="full">
                  <Divider borderColor="primaryBackground" />
                </Box>

                <FormControl px="24px" mt="14px">
                  <Checkbox
                    id="byPass"
                    {...register('byPass', {
                      onChange: (e) => {
                        if (!e.target.checked) {
                          setValue('pass', '');
                        }
                      },
                    })}
                  >
                    <Text color="secondaryDark">By pass</Text>
                  </Checkbox>
                  <Collapse in={byPass} animateOpacity>
                    <Input
                      mt={2}
                      id="extId"
                      type="text"
                      placeholder="Enter pass UUID or External ID"
                      {...register('pass')}
                    />
                  </Collapse>
                </FormControl>
                <Box py="12px" w="full">
                  <Divider borderColor="primaryBackground" />
                </Box>

                <FormControl px="24px" mt="12px">
                  <Checkbox
                    id="byStatus"
                    {...register('byStatus', {
                      onChange: (e) => {
                        if (!e.target.checked) {
                          setValue('status', '');
                        }
                      },
                    })}
                  >
                    <Text color="secondaryDark">By status</Text>
                  </Checkbox>
                  <Collapse in={byStatus} animateOpacity mt={2}>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <CustomReactSelect
                          options={[
                            {
                              label: 'Successful',
                              value: 'successful',
                            },
                            {
                              label: 'Failed',
                              value: 'failed',
                            },
                          ]}
                          formatOptionLabel={formatOptionLabelSelectPassType}
                          placeholder="Select status"
                          {...field}
                          id="groupTagFilter"
                          groupTag
                        />
                      )}
                    />
                  </Collapse>
                </FormControl>
                <Box py="12px" w="full">
                  <Divider borderColor="primaryBackground" />
                </Box>

                <FormControl px="24px" mt="12px">
                  <Checkbox
                    id="byGroupTag"
                    {...register('byGroupTag', {
                      onChange: (e) => {
                        if (!e.target.checked) {
                          setValue('groupTag', '');
                        }
                      },
                    })}
                  >
                    <Text color="secondaryDark">By group tag</Text>
                  </Checkbox>
                  <Collapse in={byGroupTag} animateOpacity>
                    <Controller
                      name="groupTag"
                      control={control}
                      render={({ field }) => (
                        <CustomReactSelect
                          options={groupTagsList}
                          formatOptionLabel={formatOptionLabelSelectPassType}
                          placeholder="Select group tag"
                          {...field}
                          id="groupTagFilter"
                          groupTag
                        />
                      )}
                    />
                  </Collapse>
                </FormControl>
                <Box py="12px" w="full">
                  <Divider borderColor="primaryBackground" />
                </Box>

                <FormControl px="24px" mt="12px">
                  <Checkbox
                    id="byScanType"
                    {...register('byScanType', {
                      onChange: (e) => {
                        if (!e.target.checked) {
                          setValue('scanType', '');
                        }
                      },
                    })}
                  >
                    <Text color="secondaryDark">By scan type</Text>
                  </Checkbox>
                  <Collapse in={byScanType} animateOpacity mt={2}>
                    <Controller
                      name="scanType"
                      control={control}
                      render={({ field }) => (
                        <CustomReactSelect
                          options={[
                            { label: 'Barcode', value: 'barcode' },
                            { label: 'NFC', value: 'nfc' },
                          ]}
                          formatOptionLabel={formatOptionLabelSelectPassType}
                          placeholder="Select scan type"
                          {...field}
                          id="groupTagFilter"
                          groupTag
                        />
                      )}
                    />
                  </Collapse>
                </FormControl>
                <Box py="12px" w="full">
                  <Divider borderColor="primaryBackground" />
                </Box>

                <FormControl px="24px" mt="12px">
                  <Checkbox
                    id="byValidationType"
                    {...register('byValidationType', {
                      onChange: (e) => {
                        if (!e.target.checked) {
                          setValue('validationType', '');
                        }
                      },
                    })}
                  >
                    <Text color="secondaryDark">By validation type</Text>
                  </Checkbox>
                  <Collapse in={byValidationType} animateOpacity mt={2}>
                    <Controller
                      name="validationType"
                      control={control}
                      render={({ field }) => (
                        <CustomReactSelect
                          options={[
                            {
                              label: 'PassEntry',
                              value: 'internal_validation',
                            },
                            {
                              label: 'Third Party',
                              value: 'external_validation',
                            },
                          ]}
                          formatOptionLabel={formatOptionLabelSelectPassType}
                          placeholder="Select scan type"
                          {...field}
                          id="groupTagFilter"
                          groupTag
                        />
                      )}
                    />
                  </Collapse>
                </FormControl>
                <Box py="12px" w="full">
                  <Divider borderColor="primaryBackground" />
                </Box>
              </VStack>
            </DrawerBody>
            <DrawerFooter
              borderTopWidth="1px"
              borderTopColor="primaryBackground"
              bg="white"
            >
              <Link
                color={useColorModeValue('secondaryDark08', '#DDDDDD')}
                fontSize="14px"
                onClick={onResetAll}
                mr="30px"
              >
                <Text textStyle="headingFamilyMedium">Reset all</Text>
              </Link>
              <Button
                type="submit"
                alt="Apply filters"
                size="sm"
                px="30px"
                alignSelf="flex-start"
              >
                Apply filters
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </Drawer>
    </Flex>
  );
}

export default LiveEventDataListFilter;
