import { Tooltip, useColorModeValue } from '@chakra-ui/react';

const TooltipClientDashboard = ({ children, open, text, sentStats }) => {
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');

  const renderTooltipText = (text) => {
    switch (text) {
      case 'Passes issued':
        return 'Passes that have been created and sent, regardless of pass status.';
      case 'Active passes':
        return 'Passes that have been added to a wallet.';
      case 'Pending passes':
        return 'Passes that have been sent, but not yet added to a wallet.';
      case 'Total scans':
        return 'Total number of scans, including multiple scans of a single pass, regardless of pass status.';
      case 'Unique passes scanned':
        return 'Total number of valid scans, excluding multiple scans of a single pass.';
      case 'Invalid passes scanned':
        return 'Total number of invalid scans, including multiple scans of a single pass, regardless of pass status.';
      case 'SMS':
        return 'Total number of SMS notifications sent to pass holders.';
      case 'Emails':
        return 'Total number of email notifications sent to pass holders and transactional emails.';
      case 'Push':
        return 'Total number of push notifications sent to pass holders.';
      default:
        return null;
    }
  };

  return (
    <Tooltip
      label={renderTooltipText(text)}
      hasArrow
      placement="top"
      fontSize="12px"
      bg={sentStats ? '#ddd' : bgTooltip}
      color={sentStats ? 'primaryDark' : textTooltip}
      w="205px"
      borderRadius="6px"
      textAlign="center"
      p="10px"
      mb="5px"
      isOpen={open}
    >
      {children}
    </Tooltip>
  );
};
export default TooltipClientDashboard;
