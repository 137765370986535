import { styled } from '@stitches/react';
import { useMonth } from '@datepicker-react/hooks';
import { Day } from './Day';

const WeekDaysContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',
  placeItems: 'center',
  marginBottom: '10px',
  gap: '8px',
  //   backgroundColor: 'Yellow',
});

const DaysContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',
  gap: '8px',
  placeItems: 'center',
  //   backgroundColor: 'orange',
});

const DayLabel = styled('span', {
  fontSize: 12,
  lineHeight: 1.4,
  textAlign: 'center',
  color: '#29323A',
  //   backgroundColor: 'DarkOrange',
  width: 30,
  variants: {
    color: {
      darkMode: {
        color: '#fff',
      },
    },
  },
});

export function Month({ firstDayOfWeek, year, month, darkMode, onClose }) {
  const { days, weekdayLabels } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });
  const { days: prevDays } = useMonth({
    year,
    month: month - 1,
    firstDayOfWeek,
  });
  const { days: nextDays } = useMonth({
    year,
    month: month + 1,
    firstDayOfWeek,
  });

  function composeCalendar() {
    const firstWeek = days.slice(0, 7);
    const prevEmptyDays = firstWeek.filter((a) => typeof a !== 'object').length;
    const prevWeek = firstWeek.map((day, idx) => {
      if (typeof day !== 'object') {
        return {
          ...prevDays[prevDays.length - prevEmptyDays + idx],
          type: 'prev',
        };
      }

      return day;
    });

    const prevAndCurrentDays = prevWeek.concat(days.slice(7));
    let i = 0;
    while (prevAndCurrentDays.length % 7 !== 0) {
      prevAndCurrentDays.push({
        ...nextDays.filter((d) => typeof d === 'object')[i],
        type: 'next',
      });
      i++;
    }

    return prevAndCurrentDays;
  }

  // console.log(composeCalendar());

  return (
    <>
      <WeekDaysContainer>
        {weekdayLabels.map((dayLabel) => (
          <DayLabel key={dayLabel} color={darkMode && 'darkMode'}>
            {dayLabel.charAt(0)}
          </DayLabel>
        ))}
      </WeekDaysContainer>
      <DaysContainer>
        {composeCalendar().map((day, index) => {
          if (typeof day === 'object') {
            return (
              <Day
                key={day.date.toString()}
                {...day}
                darkMode={darkMode}
                onClose={onClose}
              />
            );
          }

          return <div key={index} />;
        })}
      </DaysContainer>
    </>
  );
}
