import {
  Text,
  Image,
  Flex,
  Box,
  Spinner,
  Button,
  Link,
} from '@chakra-ui/react';
import {
  LOGO_BASE64,
  GOOGLE_WALLET_BUTTON_BASE64,
  APPLE_WALLET_BUTTON_BASE64,
} from '../../../utils/base64';
import PassDownloadTemplateContext from '../../../store/client/PassDownloadTemplateContext';
import { useContext } from 'react';
import footerLight from '../../../assets/vectors/footer-light.svg';
import footerDark from '../../../assets/vectors/footer-dark.svg';

const PreviewMobileScreen = (props) => {
  const { type, device } = props;
  const passDownloadCtx = useContext(PassDownloadTemplateContext);

  return (
    <Flex
      data-testid="preview-mobile-screen"
      direction="column"
      align="center"
      w="100%"
      mt={3}
    >
      <Flex
        data-testid="mobile-background"
        minH={{ base: '600px', md: '700px' }}
        w={{ base: '320px', md: '375px' }}
        bg={passDownloadCtx.backgroundColor}
        border="1px solid #ccc"
        boxShadow="0px 6px 13px rgb(0 0 0 / 15%)"
        borderRadius="15px"
        direction="column"
        position="relative"
        alignItems="center"
        justify="center"
        px={{ base: 4, md: 6 }}
        pt={{ base: 6, md: 8 }}
        pb="70px"
      >
        {/* Header Text */}
        {type !== 'failure' &&
          type !== 'success' &&
          passDownloadCtx.headerText && (
            <Text
              data-testid="header-text"
              fontFamily="avenir"
              textStyle="headingFamilyMedium"
              fontSize={{ base: '18px', md: '20px' }}
              fontWeight="600"
              color={passDownloadCtx.headerColor}
              textAlign="center"
              mb={8}
            >
              {passDownloadCtx.headerText}
            </Text>
          )}
        {/* Info Card */}
        <Box
          data-testid="download-info-card"
          bg="#FFFFFF"
          borderRadius="15px"
          w={{ base: '250px', md: '300px' }}
          py="30px"
          px={{ base: 4, md: 8 }}
        >
          <Flex direction="column" align="center" justify="center" gap={4}>
            <Image
              data-testid="default-logo-image"
              maxW="100%"
              w={{ base: '150px', md: '200px' }}
              src={passDownloadCtx.logoImage || LOGO_BASE64}
              alt="pass-download-web-page"
            />
            {/* Pass download Text */}
            {type !== 'failure' &&
              type !== 'success' &&
              passDownloadCtx.passDownloadText && (
                <Text
                  data-testid="body-text"
                  fontFamily="avenir"
                  fontSize={{ base: '14px', md: '16px' }}
                  color={passDownloadCtx.passDownloadTextColor}
                  textAlign="center"
                >
                  {passDownloadCtx.passDownloadText}
                </Text>
              )}
            {/* Add to Wallet */}
            {type === 'addToWallet' && (
              <>
                {device === 'Google' ? (
                  <Image
                    data-testid="add-to-google-wallet-button"
                    w="150px"
                    src={GOOGLE_WALLET_BUTTON_BASE64}
                    alt="pass-download-web-page"
                  />
                ) : (
                  <Image
                    data-testid="add-to-apple-wallet-button"
                    w="150px"
                    src={APPLE_WALLET_BUTTON_BASE64}
                    alt="pass-download-web-page"
                  />
                )}
              </>
            )}
            {/* Building your pass */}
            {type === 'building' && (
              <Flex direction="column" align="center">
                <Spinner
                  data-testid="spinner"
                  speed="3s"
                  borderWidth="6px"
                  color={passDownloadCtx.componentFillColor}
                  emptyColor="gray.200"
                />
                <Text
                  fontFamily="avenir"
                  fontSize={{ base: '14px', md: '16px' }}
                  color={passDownloadCtx.passDownloadTextColor || 'gray'}
                  mt={3}
                >
                  Building your pass...
                </Text>
              </Flex>
            )}
            {/* Success */}
            {type === 'success' && (
              <>
                <Text
                  fontFamily="avenir"
                  color={passDownloadCtx.passDownloadTextColor || '#45525B'}
                  fontSize={{ base: '14px', md: '16px' }}
                  textAlign="center"
                  w="100%"
                >
                  You have successfully downloaded the pass
                </Text>
                {device === 'Google' ? (
                  <Button
                    data-testid="go-to-google-wallet-button"
                    size="md"
                    w={{ base: '220px', md: 'auto' }}
                    bg={passDownloadCtx.componentFillColor}
                    color={passDownloadCtx.componentFontColor}
                    _hover={{
                      bg: passDownloadCtx.componentFillColor,
                      color: passDownloadCtx.componentFontColor,
                      opacity: 0.9,
                    }}
                    mt={2}
                  >
                    Go to Google Wallet
                  </Button>
                ) : (
                  <Button
                    data-testid="go-to-apple-wallet-button"
                    size="md"
                    w={{ base: '200px', md: 'auto' }}
                    bg={passDownloadCtx.componentFillColor}
                    color={passDownloadCtx.componentFontColor}
                    _hover={{
                      bg: passDownloadCtx.componentFillColor,
                      color: passDownloadCtx.componentFontColor,
                      opacity: 0.9,
                    }}
                    mt={2}
                  >
                    Go to Apple Wallet
                  </Button>
                )}

                <Flex direction="row" align="center" gap={1} mt={3}>
                  <Text
                    fontFamily="avenir"
                    fontSize="15px"
                    color={passDownloadCtx.passDownloadTextColor || '#45525B'}
                  >
                    Facing issues?
                  </Text>
                  <Link
                    color={passDownloadCtx.passDownloadTextColor || '#45525B'}
                    fontSize="14px"
                    fontWeight="bold"
                    href="#"
                    position="relative"
                    _hover={{ textDecoration: 'none' }}
                  >
                    Try again
                    <Box
                      position="absolute"
                      bottom="-3px"
                      left="0"
                      w="100%"
                      h="3px"
                      bg={passDownloadCtx.componentFillColor}
                    />
                  </Link>
                </Flex>
              </>
            )}
            {/* Failure */}
            {type === 'failure' && (
              <Text
                fontFamily="avenir"
                color={passDownloadCtx.passDownloadTextColor || '#45525B'}
                fontSize={{ base: '14px', md: '16px' }}
                textAlign="center"
                w="100%"
              >
                Pass has already been added to {device} Wallet
              </Text>
            )}
          </Flex>
        </Box>
        {/* Footer */}
        {!passDownloadCtx.skipFooter && (
          <Box data-testid="passentry-footer" position="absolute" bottom="30px">
            <Image
              width="150px"
              src={
                passDownloadCtx.footerColor === 'dark'
                  ? footerDark
                  : footerLight
              }
              alt="pass-download-web-page"
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default PreviewMobileScreen;
