import {
  IconButton,
  Link,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Grid,
  Text,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';

import MoreInfoLine from '../../common/MoreInfoLine';
import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';
import LiveEventDataRenderStatus from './LiveEventDataRenderStatus';

function LiveEventDataListMoreInfo({ cell }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modeIconColor = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');

  function handleMoreInfoClick(e) {
    e.stopPropagation();
    onOpen();
  }

  return (
    <>
      <IconButton
        onClick={handleMoreInfoClick}
        icon={
          <CustomChevronLeftIcon
            w="20px"
            h="20px"
            color={modeIconColor}
            transform="rotate(180deg)"
          />
        }
        variant="white"
        size="sm"
        w="36px"
        mr={1}
      />
      <Drawer onClose={onClose} isOpen={isOpen} size="md">
        <DrawerOverlay />
        <DrawerContent bg={modeBoxBg}>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottomWidth="1px"
            borderBottomColor={modeBorderColor}
            pt={{ base: '16px', lg: '56px' }}
            pb={3}
            bg={modeTextBg}
          >
            Live reader scans overview
          </DrawerHeader>
          <DrawerBody p="0" bg={modeLabelBg}>
            <Grid
              templateColumns={{ base: '150px 1fr', md: '192px 1fr' }}
              gap={0}
            >
              <MoreInfoLine heading="Scan ID" value={cell.row.original.id} />
              <MoreInfoLine heading="Type" value={cell.row.original.type} />

              <MoreInfoLine
                heading="Validation"
                value={cell.row.original.validation}
              />

              <MoreInfoLine
                heading="Reader name"
                value={cell.row.original.readerName}
              />

              <MoreInfoLine
                heading="Reader ID"
                value={cell.row.original.readerId}
              />

              <MoreInfoLine
                heading="Scan Value"
                value={cell.row.original.scanValue}
              />

              {cell.row.original.passUuid &&
                cell.row.original.passUuid !== 'N/A' && (
                  <MoreInfoLine
                    heading="Pass UUID"
                    value={cell.row.original.passUuid}
                  />
                )}

              {cell.row.original.externalId &&
                cell.row.original.externalId !== 'N/A' && (
                  <MoreInfoLine
                    heading="Pass Ext ID"
                    value={cell.row.original.externalId}
                  />
                )}

              <MoreInfoLine
                heading="Status"
                value={
                  <LiveEventDataRenderStatus
                    value={cell.row.original.status === 'successful'}
                    errorMsg={cell.row.original.statusDetail}
                  />
                }
              />

              <MoreInfoLine
                heading="Time"
                value={cell.value !== null ? cell.row.original.time : ''}
              />
            </Grid>
          </DrawerBody>
          <DrawerFooter
            borderTopWidth="1px"
            borderTopColor={modeBorderColor}
            bg={modeTextBg}
          >
            <Link
              color={modeIconColor}
              fontSize="14px"
              onClick={onClose}
              mr="30px"
            >
              <Text textStyle="headingFamilyMedium">Back to list</Text>
            </Link>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default LiveEventDataListMoreInfo;
