import React, { useContext, useState } from 'react';
import PassDownloadTemplateContext from '../../../store/client/PassDownloadTemplateContext';
import CropModal from '../../modals/CropModal';
import CustomDropzone from '../../common/CustomDropzone';
import { useDisclosure } from '@chakra-ui/react';

function FaviconImage() {
  const [droppedImage, setDroppedImage] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const passDownloadCtx = useContext(PassDownloadTemplateContext);
  const { faviconLogo: image } = passDownloadCtx;

  function handleUpdateDroppedImage(image) {
    setDroppedImage(image);
    passDownloadCtx.updateFaviconLogo({
      ...image,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
    });
  }

  function handleSaveDroppedImage(base64DroppedFile) {
    passDownloadCtx.updateFaviconImage(base64DroppedFile);
    passDownloadCtx.updateFaviconLogo({
      ...image,
      src: droppedImage.preview,
      mimeType: droppedImage.type,
    });
  }

  function isImageAdded() {
    onOpen();
  }

  async function updateImage(image) {
    passDownloadCtx.updateFaviconLogo(image);
  }

  return (
    <>
      <CropModal
        key="favicon_image_crop_modal"
        onClose={onClose}
        onOpen={onOpen}
        isOpen={isOpen}
        image={image}
        updateImage={updateImage}
        parent="favicon_image"
        title="Crop favicon image"
        droppedImage={droppedImage}
        saveDroppedImage={handleSaveDroppedImage}
      />
      <CustomDropzone
        key="favicon_image_drop_zone"
        isSquare
        image={image}
        isImageAdded={isImageAdded}
        parent="favicon_image"
        explanation="Recommended size: 16x16 pixels. Recommended file type is png with transparent background."
        accept={['image/jpg', 'image/jpeg', 'image/png', 'image/x-png']}
        droppedImage={droppedImage}
        updateDroppedImage={handleUpdateDroppedImage}
      />
    </>
  );
}

export default FaviconImage;
